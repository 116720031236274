export const NUMBERS = "0-9";
export default "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("").concat(NUMBERS);

// const vowelA = /^[aàáâãäåāăąǎǟǡǻȁȃȧḁẚạảấầẩẫậắằẳẵặ]/i;
// const vowelE = /^[eèéêëēĕėęěȅȇẹẻẽếềểễệ]/i;
// const vowelI = /^[iìíîïĩīĭįǐȉȋịĩ]/i;
// const vowelO = /^[oòóôõöøōŏȍőǒǫǭǿỡṍṏṑṓọỏốồổỗộớờởỡợ]/i;
// const vowelU = /^[uùúûüũūŭůűųǔǖǘǚǜȕȗụủūůűųửữự]/i;

/**
 * TODO, improve regex for vowels accents
 * @param {string} letter
 * @returns {RegExp}
 */
export function getRegEx(letter) {
  // if (letter === "A") return vowelA;
  // if (letter === "E") return vowelE;
  // if (letter === "I") return vowelI;
  // if (letter === "O") return vowelO;
  // if (letter === "U") return vowelU;
  if (RegExp("[A-Za-z]").test(letter)) return new RegExp(`^${letter}`, "i");
  else if (letter === NUMBERS) return /^[^a-zA-Z]/i;
  else return new RegExp(`^[${letter}]`);
}

export function normalizeString(str) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g);
}
