<template>
  <div class="profile-edit-item" :class="typeClass" @click="$emit('click')">
    <div
      class="image"
      :style="{
        backgroundImage: `url(${
          bestImage || data.imageUrl || data.lightImageUrl
        })`,
      }"
    ></div>
    <div class="info">
      <div class="name">
        {{ type === "concept" ? $t(data.name) : data.name }}
      </div>
      <div class="brand" v-if="data.brand">{{ data.brand.name }}</div>
      <div class="type">{{ data.type }}</div>
      <families-with-icon
        v-if="type === 'perfume'"
        :family="data.family"
        :subfamily="data.secondaryFamily"
      ></families-with-icon>
    </div>
    <div v-if="!hideRemove" class="trash-button" @click="_onTrash"></div>
  </div>
</template>

<script>
import FamiliesWithIcon from "./Legacy/FamiliesWithIcon.vue";
import { capitalize } from "@/utils/textUtils";
export default {
  components: { FamiliesWithIcon },
  props: ["data", "type", "hideRemove","allList"],
  computed: {
    typeClass() {
      return this.type;
    },
    bestEan() {
      if (!this.data || !this.data.eanData) return null;
      let bestEan = Object.values(this.data.eanData).find((d) => d.isMaster);
      if (!bestEan) bestEan = Object.values(this.data.eanData)[0];
      return bestEan;
    },
    bestImage() {
      return this.bestEan && this.bestEan.imageUrl
        ? this.bestEan.imageUrl
        : this.data.imageUrl;
    },
  },
  methods: {
    async _onTrash() {
        let action = "remove" + capitalize(this.type);
        this.$store.dispatch(action, this.data);  
        this.$emit("onTrash");
      
    },
  },
};
</script>

<style lang="stylus" scoped>
.profile-edit-item
  align-items: center
  border: 1px solid #000
  display: flex
  padding: vh(15px) vw(10px)

  &:not(:last-child)
    margin-bottom: vh(10px)

  .mobile &
    padding: vw(10px)

    .image
      height: vw(60px)
      width: vw(60px)

  &.ingredient
    .image
      border-radius: 100%

  .image
    background-position: center
    background-repeat: no-repeat
    background-size: contain
    height: vw(60px)
    margin-right: vw(10px)
    width: vw(60px)

  .info
    flex: 1 1 0%
    width: 70%

    .name
      font-weight: $fw-bold
      m-font-size(14, 20)

    .brand
      m-font-size(14, 20)
      margin-bottom: vh(5px)

  .trash-button
    cursor: pointer
    m-icon("trash", 18)

  &.concept
    height: vw(80px)

    .image
      display: none

    .name
      m-font-size(20, 20)
      text-align: center
      width: 100%
</style>