export function getEanData(perfume) {
  if (!perfume.eanData) return {};
  if (perfume.eanData.length === 0) return {};
  let bestEan = Object.values(perfume.eanData).find((d) => d.isMaster);
  if (!bestEan) bestEan = Object.values(perfume.eanData)[0];
  return {
    sku: bestEan ? bestEan.sku : null,
    notinoPerfumeId: bestEan ? bestEan.notinoPerfumeId : null,
  };
}
export function lowerPrice(perfume) {
  if (!perfume || !perfume.eanData) return null;
  return Object.values(perfume.eanData).reduce((acc, curr) => {
    return acc === null
      ? curr.price
      : curr.price === null
      ? acc
      : acc > curr.price
      ? curr.price
      : acc;
  }, null);
}
