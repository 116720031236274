<template>
<div class="back-button" @click="$router.go(-1)">
        <div class="back-icon"></div>
        <div class="back-text">{{ $t("back") }}</div>
    </div>
</template>
<script>
export default {}
</script>
<style lang="stylus">
.back-button
    align-items: center
    cursor: pointer
    display: flex
    
    .back-icon
      m-icon("arrow-left", 14)
      margin-right: vw(5px)

    .back-text
      m-font-size(14, 20)
      font-weight: $fw-bold
      margin-top: 0.1em
      text-transform: uppercase
</style>