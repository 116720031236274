import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import i18n from './modules/i18n'
import profile from './modules/profile'
import recos from './modules/recos'
import session from './modules/session'
import VuexPersistence from 'vuex-persist'

const vuexPersist = new VuexPersistence({
  storage: window.localStorage,
  reducer: state => ({
    user: state.user,
    profile: state.profile,
    i18n: state.i18n
  })
})

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    profile,
    i18n,
    recos,
    session
  },
  plugins: [vuexPersist.plugin],
  getters: {
    // profileFamiliesWithSubs: (state,getters) => async () => {
    //   let perfumes = await getters.perfumes()
    //   let pairs = perfumes.filter(e => e).map(p => { return { secondaryFamily: p.secondaryFamily, family: p.family, value: 1 } });
    //   let fams = Object.values(pairs.reduce((dict, { family, secondaryFamily }) => {
    //     dict[family.id] = dict[family.id] || { family: family, subFamilies: [] }
    //     dict[family.id].subFamilies.push(secondaryFamily)
    //     return dict
    //   }, {}))

    //   //explicit ings
    //   let ings = await getters.ingredients()
    //   let ingsFams = ings.map((i)=>({family:{...i.family},subFamilies:[{...i.family}]}))
    //   ingsFams.forEach((ingfam)=>{
    //     let candidate = fams.find((f)=>f.family.id === ingfam.family.id)
    //     if(!candidate) fams.push(ingfam)
    //     else candidate.subFamilies = candidate.subFamilies.concat(ingfam.subFamilies)
    //   })

    //   fams.forEach((f) => {
    //     f.subFamilies = Object.values(f.subFamilies.reduce((dict, subFamily) => {
    //       dict[subFamily.id] = dict[subFamily.id] || { subFamily: subFamily, count: 0 }
    //       dict[subFamily.id].count += 1
    //       return dict
    //     }, {}))
    //     f.id = f.family.id
    //   })
    //   return fams

    // },
    familiesWithSubsByIngredients: (state, getters) => async () => {
      // let perfumes = await getters.perfumes()
      // let pairs = perfumes.filter(e => e).map(p => { return { secondaryFamily: p.secondaryFamily, family: p.family, value: 1 } });
      // let fams = Object.values(pairs.reduce((dict, { family, secondaryFamily }) => {
      //   dict[family.id] = dict[family.id] || { family: family, subFamilies: [] }
      //   dict[family.id].subFamilies.push(secondaryFamily)
      //   return dict
      // }, {}))
      let fams = []
      //explicit ings
      let ings = await getters.ingredients()
      let ingsFams = ings.map(i => ({
        family: { ...i.family },
        subFamilies: [{ ...i.family }]
      }))
      ingsFams.forEach(ingfam => {
        let candidate = fams.find(f => f.family.id === ingfam.family.id)
        if (!candidate) fams.push(ingfam)
        else
          candidate.subFamilies = candidate.subFamilies.concat(
            ingfam.subFamilies
          )
      })

      fams.forEach(f => {
        f.subFamilies = Object.values(
          f.subFamilies.reduce((dict, subFamily) => {
            dict[subFamily.id] = dict[subFamily.id] || {
              subFamily: subFamily,
              count: 0
            }
            dict[subFamily.id].count += 1
            return dict
          }, {})
        )
        f.id = f.family.id
      })
      return fams
    },
    familiesWithSubsByPerfumes: (state, getters) => async () => {
      let perfumes = await getters.perfumes()
      let pairs = perfumes
        .filter(e => e)
        .map(p => {
          return {
            secondaryFamily: p.secondaryFamily,
            family: p.family,
            value: 1
          }
        })
      let fams = Object.values(
        pairs.reduce((dict, { family, secondaryFamily }) => {
          dict[family.id] = dict[family.id] || {
            family: family,
            subFamilies: []
          }
          dict[family.id].subFamilies.push(secondaryFamily)
          return dict
        }, {})
      )

      // //explicit ings
      // let ings = await getters.ingredients()
      // let ingsFams = ings.map((i)=>({family:{...i.family},subFamilies:[{...i.family}]}))
      // ingsFams.forEach((ingfam)=>{
      //   let candidate = fams.find((f)=>f.family.id === ingfam.family.id)
      //   if(!candidate) fams.push(ingfam)
      //   else candidate.subFamilies = candidate.subFamilies.concat(ingfam.subFamilies)
      // })

      fams.forEach(f => {
        f.subFamilies = Object.values(
          f.subFamilies.reduce((dict, subFamily) => {
            dict[subFamily.id] = dict[subFamily.id] || {
              subFamily: subFamily,
              count: 0
            }
            dict[subFamily.id].count += 1
            return dict
          }, {})
        )
        f.id = f.family.id
      })
      return fams
    },
    profileImplicitIngredients: (state, getters) => async () => {
      let perfumes = await getters.perfumes()
      let dict = perfumes.reduce((accum, p) => {
        p.ingredientProportions.forEach(ing => {
          let id = ing.ingredient.id
          if (!accum[id]) accum[id] = { value: 0, ing: ing.ingredient }
          accum[id].value += ing.proportion
        })
        return accum
      }, {})
      let list = Object.values(dict).sort((a, b) => b.value - a.value)
      let perfumeIngs = list.map(pp => pp.ing)
      return perfumeIngs
    },
    profileIngredients: (state, getters) => async () => {
      // let lang = getters.lang
      let explicitIngs = await getters.ingredients()
      let perfumeIngs = await getters.profileImplicitIngredients()
      let merged = [...explicitIngs, ...perfumeIngs]
      let unique = merged.filter(
        (ing, i) => merged.findIndex(mi => mi.id === ing.id) === i
      )
      return unique
    },
    getProfileHeroes: (state, getters) => async () => {
      let likedPerfumes = await getters.perfumes()
      let heroes = likedPerfumes
        .filter(p => p.heroIngredient)
        .map(p => p.heroIngredient)
      return heroes
    },
    canShowProfile: state => () => {
      // return state.profile.perfumes.length>=3 || (state.profile.perfumes.length>0 && state.profile.concepts.length>=3)
      return state.profile.perfumes.length > 0
    }
  }
})
