<template>
  <div class="view profile-edit scrollable">
    <div class="back-button" @click="()=>$router.back()">{{$t('done')}}</div>
    <div class="main-title">{{$t('your_profile_to_edit')}}</div>
    <div class="gender-toggle">
      <div class="label">{{$t('what_gender')}}</div> 
      <div class="gender-btn-container">
        <div class="gender-btn" :class="{selected: $store.state.user.gender==='MU'}" @click="setGender('MU')">{{$t('gender_m')}}</div>
        <div class="gender-btn" :class="{selected: $store.state.user.gender==='FU'}" @click="setGender('FU')">{{$t('gender_f')}}</div>
      </div>
    </div>
    <div class="rows">
      <div class="row perfumes">
        <div class="row-header">
          <div class="row-title">{{$t('fragrances')}}</div>
          <router-link class="add-more-item" :to="{name:'PerfumeSearch'}" v-if="!isMobile">{{$t('add_more_fragrances')}}</router-link>
        </div>
        <div class="row-items" v-if="profilePerfumes">
          <profile-edit-item v-for="item in profilePerfumes" :key="item.id" :data="item" type="perfume" @click="$router.push({ name: `Perfume`,params:{id:item.id}, query: { fromReco: true }})"></profile-edit-item>
          <router-link class="add-more-item" v-if="isMobile" :to="{name:'PerfumeSearch'}">{{$t('add_more_fragrances')}}</router-link>
          <div class="no-items" v-if="profilePerfumes.length===0">{{$t('no_perfumes_yet')}}</div>
        </div>
      </div>
      <!-- <div class="row ingredients">
        <div class="row-header">
          <div class="row-title">{{$t('ingredients')}}</div>
          <router-link class="add-more-item" to="/ingredient-search" v-if="!isMobile">{{$t('add_more_ingredients')}}</router-link>
        </div>
        <div class="row-items" v-if="profileIngredients">
          <profile-edit-item v-for="item in profileIngredients" :key="item.id" :data="item" type="ingredient"></profile-edit-item>
          <router-link class="add-more-item" v-if="isMobile" to="/ingredient-search">{{$t('add_more_ingredients')}}</router-link>
          <div class="no-items" v-if="profileIngredients.length===0">{{$t('no_ingredients_yet')}}</div>
        </div>
      </div> -->
      <div class="row concepts">
        <div class="row-header">
          <div class="row-title">{{$t('concepts')}}</div>
          <router-link class="add-more-item" :to="{name:'ConceptsSelect'}" v-if="!isMobile">{{$t('change_concepts')}}</router-link>
        </div>
        <div class="row-items" v-if="profileConcepts">
          <profile-edit-item v-for="item in profileConcepts" :key="item.id" :data="item" type="concept"></profile-edit-item>
          <router-link class="add-more-item" v-if="isMobile" :to="{name:'ConceptsSelect'}" >{{$t('change_concepts')}}</router-link>
          <div class="no-items" v-if="profileConcepts.length===0">{{$t('no_concepts_yet')}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileEditItem from '../components/ProfileEditItem.vue'
export default {
  components: { ProfileEditItem },
  asyncComputed:{
    profilePerfumes(){
      return this.$store.getters.perfumes()
    },
    profileIngredients(){
      // Hay que usar los EXPLICITOS SOLO!
      return this.$store.getters.ingredients()
    },
    profileConcepts(){
      return this.$store.getters.concepts()
    }
  },
  methods: {
    setGender(gender) {
      this.$store.dispatch('setGender',gender)
    }
  }
}
</script>

<style lang="stylus" scoped>
.profile-edit
  display: flex
  flex-direction: column
  padding: vh(20px) vw(30px)
  padding-bottom: vh(20px) !important
  position: relative

  .mobile &
    padding: vw(20px)
    padding-bottom: vw(20px) !important

  .back-button
    cursor: pointer
    font-weight: $fw-medium
    m-font-size(14, 20)
    position: absolute
    right: vw(30px)
    text-transform: uppercase
    top: vh(40px)

    .mobile &
      left: vw(20px)
      right: auto
      top: vw(20px)

  .main-title
    m-font-size(18, 22)
    font-weight: $fw-medium
    margin-bottom: vh(40px)
    margin-top: vh(20px)

    .mobile &
      margin-top: vw(40px)

  .gender-toggle
    align-items: center
    display: flex
    margin-bottom: vh(40px)

    .mobile &
      display: block

    .label
      margin-right: vw(20px)

      .mobile &
        margin-bottom: vw(10px)
        margin-right: 0
        text-align: center

    .gender-btn-container
      align-items: center
      display: flex
      justify-content: center

    .gender-btn
      border: 1px solid #000
      padding: vw(5px) vw(10px)
      text-align: center
      width: vw(100px)

      &:not(:last-child)
        border-right: none

      &.selected
        background-color: #000
        color: #fff

  .rows
    display: flex
    flex-direction: column

    .mobile &
      display: block

  .row
    display: flex
    flex-direction: column

    .mobile &
      display: block

    &:not(:last-child)
      margin-bottom: vh(50px)

    .no-items
      opacity: 0.5
      padding: vw(40px)
      text-align: center
      width: 100%

      .mobile &
        padding-left: vw(20px)
        padding-right: vw(20px)

  .row-header
    display: flex
    justify-content: space-between
    margin-bottom: vh(20px)

  .row-title
    m-font-size(16, 20)
    font-weight: $fw-bold

  .add-more-item
    m-font-size(14, 20)
    text-decoration: underline

    .mobile &
      align-items: center
      border-radius: 5px
      display: flex
      justify-content: center
      m-font-size(16, 20)
      padding: vw(30px)
      text-align: center

  .row-items
    $gap = vh(-15px)
    display: flex
    flex-direction: row
    flex-wrap: wrap
    margin-left: $gap
    margin-right: $gap

    .mobile &
      // flex-direction: column
      display: block

  .profile-edit-item
    $gap = vh(15px)
    $width = percentage((1 / 3))
    flex: 0 0 "calc(%s - (%s * 2))" % ($width $gap)
    margin-bottom: $gap
    margin-left: $gap
    margin-right: $gap

    &.perfume
      cursor: pointer
</style>