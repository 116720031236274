export default {
welcome_to_profiler: "<b>Notino Fragrance Finder</b>",
what_are_you_looking_for: "Lassen Sie sich von uns einen neuen Duft empfehlen, der zu Ihnen oder Ihren Liebsten perfekt passt.<br>Für wen suchen Sie den perfekten Duft?",
build_your_profile_and: "Lassen Sie sich von uns einen neuen Duft empfehlen, der zu Ihnen oder Ihren Liebsten perfekt passt.<br>Für wen suchen Sie den perfekten Duft?",
search_by: "Wir empfehlen Ihnen Düfte, entsprechend Ihrer Präferenzen",
gender_m: "Für Herren",
gender_f: "Für Damen",
mode_perfumes: "Lieblingsdüfte",
mode_ingredients: "Duftstoffe",
back: "Zurück",
novelty: "Neuheit",
go_back_to_finder: "Zurück zum Fragrance Finder",
done: "Fertig",
best_sellers: "Bestseller",
from: "ab",
search_your_perfume: "<b>Wählen Sie<b> max. 5 Düfte aus, die Sie oder der/die Beschenkte mögen.",
search_your_perfume_flat: "Geben Sie Ihren Lieblingsduft oder Ihre Lieblingsmarke ein",
select_your_perfume: "<b>Wählen Sie</b> Ihre Lieblingsdüfte.",
no_perfumes_found: "Es wurden keine Düfte gefunden :(",
no_ingredients_found: "Es wurden keine Duftstoffe gefunden :(",
something_went_wrong: "Leider ergab Ihre Suche keine Ergebnisse. Versuchen Sie einen neuen Namen einzugeben",
select_fav_ings: "<b>Wählen Sie</b> Ihre bevorzugten Duftstoffe aus und wir helfen Ihnen, den richtigen Duft zu finden.",
search_fav_ings: "Geben Sie hier den Namen Ihres bevorzugten Duftstoffs ein.",
building_your_profile: "<b>Wir erstellen</b> Ihr<br>Duftprofil",
edit_your_profile: "Profil bearbeiten",
fragrances: "Düfte",
ingredients: "Duftstoffe",
concepts: "Konzepte",
next_step: "Fertig",
what_gender: "Für wen ist dieses Profil gedacht?",
selected_ingredients: "Ihre bevorzugten Duftstoffe",
selected_perfumes: "Ihre Lieblingsdüfte",
no_perfumes_yet: "In Ihrem Profil sind keine Düfte vorhanden",
no_ingredients_yet: "In Ihrem Profil sind keine Duftstoffe vorhanden",
no_conceptss_yet: "In Ihrem Profil sind keine Konzepte vorhanden",
get_recomendations: "Empfehlungen erhalten",
this_are_your_ings: "Das sind Ihre bevorzugten Duftstoffe",
your_profile_to_edit: "Ihr Profil",
add_more_fragrances: "Weitere Düfte hinzufügen",
add_more_ingredients: "Weitere Duftstoffe hinzufügen",
add_more_concepts: "Weitere Konzepte hinzufügen",
top_6_recos: "AUF SIE ZUGESCHNITTENE DÜFTE",
recos_based_on: "Diese Empfehlungen beruhen auf Ihrem Duftprofil.",
rest_of_recos: "Weitere Empfehlungen",
view_details: "Details anzeigen",
your_profile_is_empty: "Ihr Profil ist leer!",
add_to_profile_to_get_recos: "Fügen Sie Ihrem Profil Düfte oder Duftstoffe hinzu und erhalten Sie Empfehlungen",
price: "Preis",
buy_it_now: "Mehr erfahren",
prominent_ings: "Dominierende Duftstoffe",
all_ings: "Vollständige Liste der Duftstoffe",
why_do_we_recommend: "Warum empfehlen wir Ihnen <b>diesen Duft</b>?",
other_ings_present: "Sowie andere, weniger ausgeprägte",
description: "Beschreibung",
evaluate_this_reco: "Bewerten Sie diese Empfehlung",
how_relevant_do_you: "Wie relevant ist Ihrer Meinung nach <b>diese Empfehlung</b>?",
submit: "Senden",
check_your_new_recommendations_1: "<b>Profil aktualisiert.</b> Danke!",
check_your_new_recommendations_2: "Sie haben neue Empfehlungen.",
view_new_recommendations: "Neue Empfehlungen anzeigen",
close: "Schließen",
loading: "Lädt",

//Profile_desc,
you_love_perfumes_of_the: "<b>Sie lieben Düfte</b><br>",
you_love_perfumes_of_the_plural: "<b>Sie lieben Düfte</b><br>",
and: "und",
desc_family: "Duftfamilie",
desc_families: "Duftfamilien",
you_also_love_plural: "Sie lieben auch Untergruppen",
you_also_love: "Sie lieben auch die Untergruppe",
desc_sub_family: "Untergruppe",
desc_sub_families: "Untergruppen",

//RECOS:,
it: "das",
also: "auch",
$pronoun_self: "Sie",
$possessive_singular_self: "Ihr",
$possessive_refl_singular_self: "meine",
$possessive_plural_self: "Ihre",
$possessive_refl_plural_self: "meine",
$possessive_singular_prep_self: "Ihre",
$possessive_singular_liste_self: "Ihre",
$you_self: "Sie",
$you_prep_self: "Sie",
$do_you_have_self: "Sie haben",
$that_you_love_self: "die Sie mögen",
$you_love_self: "Sie mögen",
$you_adore_self: "Sie mögen",
$apreciate_self: "Gefällt Ihnen",
$you_have_self: "Sie haben",
reco_desc_title: "Wir empfehlen diesen Duft, weil",
possible_reco_title: "Vielleicht $you_love dieser Duft, weil",
recommended_by_hero: "$you_adore seinen dominierenden Duftstoff: <b>%HERO%</b>",
recommended_by_fam: "gehört zu einer $possessive_plural bevorzugten Duftfamilien, <b>%FAMILY%</b>",
not_recommended_by_fam: "Auch wenn er ein anderes Profil als $possessive_singular_prep, $you_prep hat, sollten Sie ihn ausprobieren",
recommended_by_ings: "er enthält einige $possessive_plural bevorzugten Duftstoffe: ",
furthermore: "Weiter",
home: "Neue Empfehlungen",
my_profile: "Mein Profil",
no_concepts_yet: "In Ihrem Profil sind keine Konzepte vorhanden",
view_more: "Mehr anzeigen",
select_fav_ingredients_to_find_perfumes: "Wählen Sie Ihre beliebten <b>Duftstoffe</b><br> aus und finden Sie Ihre<br> beliebten <b>Düfte</b>",
your_prefered_concepts: "Ihre Lieblingseigenschaften",
concept_fresh: "Frisch (z. B. Zitrusdüfte)",
concept_intense: "Intensiv (z. B. holzige Düfte)",
concept_casual: "Für das tägliche Tragen (z. B. für die Arbeit)",
concept_special_occasion: "Für einen besonderen Anlass (z. B. ein Date)",
concept_sure_bet: "Auf Nummer sicher gehen",
concept_novelty: "Trendy (z. B. Neuheit)",
change_concepts: "Ausgewählte Eigenschaften ändern",
select_concepts: "Wir sind fast fertig",
add_concepts: "Weitere beliebte Eigenschaften hinzufügen",
or: "oder",
explore_by: "Erkunden nach",
brands: "Marke",
your_selection: "Ihre Auswahl",
list_view: "Liste beliebter Düfte",
graphic_view: "Ihr Duftprofil",
recos_based_on_selection: "Empfehlungen auf der Grundlage Ihrer Auswahl",
add_to_profile: "Zu meinem Profil hinzufügen",
do_you_want_to_reset: "Möchten Sie Ihr Profil neu einstellen?",
start_new: "Von Anfang beginnen",
keep_perfumes: "Duft bewahren",
click_to_view: "Per Klick anzeigen",
already_in_profile: "Bereits hinzugefügt",
only_showing_that_combine_with: "Es werden nur Duftstoffe angezeigt in Kombination mit",
see_all_ings_in_this_fam: "Alle Duftstoffe dieser Duftfamilie anzeigen",
select_concepts_subtitle: "Um Empfehlungen zu erhalten, müssen Sie mindestens 2 Düfte auswählen (klicken Sie auf zurück) oder fügen Sie mindestens eines der folgenden Duftmerkmale hinzu.",
action: "Angebote",
free_del: "Gratisversand",
new_in: "Neu",
cancel: "Abbrechen",
see_more: "Mehr anzeigen",
see_less: "Weniger anzeigen",
recommendation_by: "Empfehlungen mit diesen Inhaltsstoffen",
apply: "Anwenden",
modal_limit_ingredients_title: "Maximale Anzahl an Inhaltsstoffen",
modal_limit_ingredients_text: "Sie haben die Höchstgrenze für die Auswahl von Inhaltsstoffen erreicht (4). Bitte entfernen Sie einen, bevor Sie einen weiteren hinzufügen.",
empty_selection: "Sie haben nichts ausgewählt",
empty_selection_text: "Wählen Sie einen oder mehrere Düfte für Ihre Empfehlungen aus",
search_perfume_subtitle: "Großartig! Sie haben nun Zugriff auf Ihre persönlichen Empfehlungen.",
perfume_search_subtitle: "Großartig! Sie haben nun Zugriff auf Ihre persönlichen Empfehlungen.",
filter_by_brand: "Nach Marke filtern",
perfume_list: "Wählen Sie einen Duft",
others: "",
some_error: "Ohe! Es gibt wohl einen Fehler auf der Webseite. Wir arbeiten daran, diesen so schnell wie möglich zu beheben. Vielen Dank für Ihre Geduld!",
no_brands_found: "Keine Marken gefunden :("
}