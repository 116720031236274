export default {
welcome_to_profiler: "<b>Notino Fragrance Finder</b>",
what_are_you_looking_for: "Nechajte si od nás odporučiť novú vôňu, ktorá vám alebo vašim blízkym padne ako uliata.<br>Pre koho hľadáte ideálnu vôňu?",
build_your_profile_and: "Nechajte si od nás odporučiť novú vôňu, ktorá vám alebo vašim blízkym padne ako uliata.<br>Pre koho hľadáte ideálnu vôňu?",
search_by: "Odporučíme parfémy podľa vašich",
gender_m: "Pre muža",
gender_f: "Pre ženu",
mode_perfumes: "Obľúbených parfémov",
mode_ingredients: "Ingrediencií",
back: "Späť",
novelty: "Novinka",
go_back_to_finder: "Návrat do Fragrance Finderu",
done: "Hotovo",
best_sellers: "Bestseller",
from: "od",
search_your_perfume: "<b>Vyberte<b> max. 5 vôní, ktoré máte radi vy alebo ten, pre koho parfém vyberáte.",
search_your_perfume_flat: "Začnite písať obľúbený parfém alebo značku.",
select_your_perfume: "<b>Vyberte</b> svoje obľúbené vône.",
no_perfumes_found: "Neboli nájdené žiadne vône :(",
no_ingredients_found: "Neboli nájdené žiadne ingrediencie :(",
something_went_wrong: "Bohužiaľ sme nemohli nájsť žiadny výsledok. Skúste zadať nový názov.",
select_fav_ings: "<b>Vyberte</b> vaše obľúbené ingrediencie a my vám pomôžeme nájsť ten pravý parfém.",
search_fav_ings: "Sem zadajte názov vašej obľúbenej ingrediencie.",
building_your_profile: "<b>Vytvárame</b> váš<br>parfumový profil",
edit_your_profile: "Upraviť profil",
fragrances: "Vône",
ingredients: "Ingrediencie",
concepts: "Koncepty",
next_step: "Hotovo",
what_gender: "Komu je tento profil určený?",
selected_ingredients: "Vaše obľúbené ingrediencie",
selected_perfumes: "Vaše obľúbené vône",
no_perfumes_yet: "Vo vašom profile nie sú žiadne vône",
no_ingredients_yet: "Vo vašom profile nie sú žiadne ingrediencie",
no_conceptss_yet: "Vo vašom profile nie sú žiadne koncepty",
get_recomendations: "Získať odporúčania",
this_are_your_ings: "Toto sú vaše obľúbené ingrediencie",
your_profile_to_edit: "Váš profil",
add_more_fragrances: "Pridať ďalšie vône",
add_more_ingredients: "Pridať ďalšie ingrediencie",
add_more_concepts: "Pridať ďalšie koncepty",
top_6_recos: "VÔNE NA MIERU",
recos_based_on: "Tieto odporúčania sú založené na vašom parfumovom profile.",
rest_of_recos: "Ďalšie odporúčania",
view_details: "Zobraziť detaily",
your_profile_is_empty: "Váš profil je prázdny!",
add_to_profile_to_get_recos: "Pridajte si do profilu vône alebo ingrediencie a získajte odporúčania",
price: "cena",
buy_it_now: "Dozvedieť sa viac",
prominent_ings: "Najvýraznejšie ingrediencie",
all_ings: "Kompletný zoznam ingrediencií",
why_do_we_recommend: "Prečo odporúčame <b>túto vôňu</b>?",
other_ings_present: "Rovnako ako ďalšie, menej výrazné",
description: "Popis",
evaluate_this_reco: "Ohodnoťte toto odporúčanie",
how_relevant_do_you: "Aké relevantné je podľa vás <b>toto odporúčanie</b>?",
submit: "Odoslať",
check_your_new_recommendations_1: "<b>Profil aktualizovaný.</b> Ďakujeme!",
check_your_new_recommendations_2: "Máte nové odporúčania.",
view_new_recommendations: "Zobraziť nové odporúčania",
close: "zavrieť",
loading: "Načítanie",

//Profile_desc,
you_love_perfumes_of_the: "<b>milujete parfémy</b><br>",
you_love_perfumes_of_the_plural: "<b>milujete parfémy</b><br>",
and: "a",
desc_family: "rodina",
desc_families: "rodiny",
you_also_love_plural: "milujete aj podskupiny",
you_also_love: "milujete aj podskupinu",
desc_sub_family: "podskupina",
desc_sub_families: "podskupiny",

//RECOS:,
it: "to",
also: "tiež",
$pronoun_self: "vy",
$possessive_singular_self: "vaše",
$possessive_refl_singular_self: "moje",
$possessive_plural_self: "vaše",
$possessive_refl_plural_self: "moje",
$possessive_singular_prep_self: "vaše",
$possessive_singular_liste_self: "vaše",
$you_self: "vy",
$you_prep_self: "vy",
$do_you_have_self: "máte",
$that_you_love_self: "ktoré máte radi",
$you_love_self: "máte radi",
$you_adore_self: "máte radi",
$apreciate_self: "páči sa vám",
$you_have_self: "máte",
reco_desc_title: "Tento parfém odporúčame, pretože",
possible_reco_title: "Možno $you_love tento parfém, pretože",
recommended_by_hero: "$you_adore jeho hlavnú ingredienciu: <b>%HERO%</b>",
recommended_by_fam: "patrí do jednej z $possessive_plural obľúbených parfumových rodín, <b>%FAMILY%</b>",
not_recommended_by_fam: "Aj keď má iný profil než $possessive_singular_prep, $you_prep mali by ste ho skúsiť",
recommended_by_ings: "má niektoré z $possessive_plural obľúbených ingrediencií: ",
furthermore: "Ďalej",
home: "Nové odporúčanie",
my_profile: "Môj profil",
no_concepts_yet: "Vo vašom profile nie sú žiadne koncepty",
view_more: "Ukázať viac",
select_fav_ingredients_to_find_perfumes: "Vyberte si obľúbené <b>ingrediencie</b><br> a nájdite si<br> obľúbené <b>vône</b>",
your_prefered_concepts: "Vaše obľúbené vlastnosti",
concept_fresh: "Svieže (napr. citrusové)",
concept_intense: "Intenzívne (napr. drevité)",
concept_casual: "Na každodenné nosenie (napr. do práce)",
concept_special_occasion: "Na zvláštnu príležitosť (napr. na rande)",
concept_sure_bet: "Stávka na istotu",
concept_novelty: "Módne (napr. novinka)",
change_concepts: "Zmeniť vybrané vlastnosti",
select_concepts: "Už to skoro máme",
add_concepts: "Pridať ďalšie obľúbené vlastnosti",
or: "alebo",
explore_by: "Preskúmať podľa",
brands: "Značky",
your_selection: "Váš výber",
list_view: "Zoznam obľúbených vôní",
graphic_view: "Váš parfumový profil",
recos_based_on_selection: "Odporúčania založené na vašom výbere",
add_to_profile: "Pridať do môjho profilu",
do_you_want_to_reset: "Chcete si znovu nastaviť profil?",
start_new: "Začať znovu",
keep_perfumes: "Zachovať parfémy",
click_to_view: "Kliknutím zobraziť",
already_in_profile: "Už pridané",
only_showing_that_combine_with: "Zobrazujú sa len zložky v kombinácii s",
see_all_ings_in_this_fam: "Zobraziť všetky ingrediencie tejto rodiny",
select_concepts_subtitle: "Ak chcete získať odporúčania, musíte si vybrať aspoň 2 vône (kliknite späť) alebo pridať aspoň jednu z nasledujúcich vlastností parfému.",
action: "akcia",
free_del: "doprava zadarmo",
new_in: "nové",
cancel: "zrušiť",
see_more: "Ďalšie",
see_less: "Zobraziť menej",
recommendation_by: "Parfémy s týmito ingredienciami",
apply: "Použiť",
modal_limit_ingredients_title: "Maximálny počet ingrediencií",
modal_limit_ingredients_text: "Pridať môžete maximálne 4 ingrediencie. Ak chcete pridať ďalšiu, jednu odstráňte.",
empty_selection: "Nemáte žiaden výber",
empty_selection_text: "Vyberte jednu alebo viacero vôní pre odporúčania",
search_perfume_subtitle: "Skvelé! Teraz máte prístup k svojim personalizovaným odporúčaniam.",
perfume_search_subtitle: "Skvelé! Teraz máte prístup k svojim personalizovaným odporúčaniam.",
filter_by_brand: "Filtrovať podľa značky",
perfume_list: "Vybrať vôňu",
others: "",
some_error: "Hups! Zdá sa, že sa na webovej stránke vyskytla chyba. Na jej odstránení usilovne pracujeme. Ďakujeme za trpezlivosť!",
no_brands_found: "Neboli nájdené žiadne značky :("
}