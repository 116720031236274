/**
 * Return all possible combinations with elements of array
 * @param {T[]} array
 * @returns {T[][]}
 * @template T
 */
export default function combinations(array) {
  let result = [];

  function recursiveCombination(arr, start, current) {
    result.push(current);

    for (let i = start; i < arr.length; i++) {
      recursiveCombination(arr, i + 1, current.concat(arr[i]));
    }
  }
  recursiveCombination(array, 0, []);
  return result.sort((arr1, arr2) => arr2.length - arr1.length);
}
