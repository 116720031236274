export default {
welcome_to_profiler: " <b>Notino Fragrance Finder</b>",
what_are_you_looking_for: "Nechejte si od nás doporučit novou vůni, která vám nebo vašim blízkým padne jako ulitá.<br>Pro koho hledáte ideální vůni?",
build_your_profile_and: "Nechejte si od nás doporučit novou vůni, která vám nebo vašim blízkým padne jako ulitá.<br>Pro koho hledáte ideální vůni?",
search_by: "Doporučíme parfémy podle vaších",
gender_m: "Pro muže",
gender_f: "Pro ženu",
mode_perfumes: "Oblíbených parfémů",
mode_ingredients: "Ingrediencí",
back: "Zpět",
novelty: "NOVÉ",
go_back_to_finder: "Návrat do Fragrance Finderu",
done: "Hotovo",
best_sellers: "Best seller",
from: "od",
search_your_perfume: "<b>Vyberte<b> max 5 vůní, které máte rádi vy nebo ten, pro koho parfém vybíráte.",
search_your_perfume_flat: "Začněte psát oblíbený parfém nebo značku",
select_your_perfume: "<b>Vyberte</b> své oblíbené vůně.",
no_perfumes_found: "Nebyly nalezeny žádné vůně :(",
no_ingredients_found: "Nebyly nalezeny žádné ingredience :(",
something_went_wrong: "Bohužel jsme nemohli najít žádný výsledek. Zkuste zadat nový název",
select_fav_ings: "<b>Vyberte</b> vaše oblíbené ingredience a my vám pomůžeme najít ten pravý parfém.",
search_fav_ings: "Sem zadejte název vaší oblíbené ingredience.",
building_your_profile: "<b>Vytváříme</b> váš<br>parfémový profil",
edit_your_profile: "Upravit profil",
fragrances: "Vůně",
ingredients: "Ingredience",
concepts: "Koncepty",
next_step: "Hotovo",
what_gender: "Komu je tento profil určen?",
selected_ingredients: "Vaše oblíbené ingredience",
selected_perfumes: "Vaše oblíbené vůně",
no_perfumes_yet: "Ve vašem profilu nejsou žádné vůně",
no_ingredients_yet: "Ve vašem profilu nejsou žádné ingredience",
no_conceptss_yet: "Ve vašem profilu nejsou žádné koncepty",
get_recomendations: "Získat doporučení",
this_are_your_ings: "Toto jsou vaše oblíbené ingredience",
your_profile_to_edit: "Váš profil",
add_more_fragrances: "Přidat další vůně",
add_more_ingredients: "Přidat další ingredience",
add_more_concepts: "Přidat další koncepty",
top_6_recos: "VŮNĚ VÁM NA MÍRU",
recos_based_on: "Tato doporučení jsou založena na vašem parfémovém profilu.",
rest_of_recos: "Další doporučení",
view_details: "Zobrazit detaily",
your_profile_is_empty: "Váš profil je prázdný!",
add_to_profile_to_get_recos: "Přidejte do svého profilu vůně nebo ingredience a získejte doporučení",
price: "cena",
buy_it_now: "Dozvědět se více",
prominent_ings: "Nejvýraznější ingredience",
all_ings: "Kompletní seznam ingrediencí",
why_do_we_recommend: "Proč doporučujeme <b>tuto vůni</b>?",
other_ings_present: "Stejně jako další, méně výrazné",
description: "Popis",
evaluate_this_reco: "Ohodnoťte toto doporučení",
how_relevant_do_you: "Jak relevantní je podle vás <b>toto doporučení</b>?",
submit: "Odeslat",
check_your_new_recommendations_1: "<b>Profil aktualizován.</b> Díky!",
check_your_new_recommendations_2: "Máte nová doporučení.",
view_new_recommendations: "Zobrazit nová doporučení",
close: "zavřít",
loading: "Načítání",

//Profile_desc,
you_love_perfumes_of_the: "<b>milujete parfémy</b><br>",
you_love_perfumes_of_the_plural: "<b>milujete parfémy</b><br>",
and: "a",
desc_family: "rodina",
desc_families: "rodiny",
you_also_love_plural: "také milujete podskupiny",
you_also_love: "také milujete podskupinu",
desc_sub_family: "podskupina",
desc_sub_families: "podskupiny",

//RECOS:,
it: "to",
also: "také",
$pronoun_self: "vy",
$possessive_singular_self: "vaše",
$possessive_refl_singular_self: "moje",
$possessive_plural_self: "vaše",
$possessive_refl_plural_self: "moje",
$possessive_singular_prep_self: "vaše",
$possessive_singular_liste_self: "vaše",
$you_self: "vy",
$you_prep_self: "vy",
$do_you_have_self: "máte",
$that_you_love_self: "které máte rádi",
$you_love_self: "máte rádi",
$you_adore_self: "máte rádi",
$apreciate_self: "líbí se vám",
$you_have_self: "máte",
reco_desc_title: "Tento parfém doporučujeme, protože",
possible_reco_title: "Možná $you_love tento parfém, protože",
recommended_by_hero: "$you_adore jeho hlavní ingredienci: <b>%HERO%</b>",
recommended_by_fam: "patří do jedné z $possessive_plural oblíbených parfémových rodin, <b>%FAMILY%</b>",
not_recommended_by_fam: "I když má jiný profil než $possessive_singular_prep, $you_prep měli byste jej zkusit",
recommended_by_ings: "má některé z $possessive_plural oblíbených ingrediencí: ",
furthermore: "Dále",
home: "Nové doporučení",
my_profile: "Můj profil",
no_concepts_yet: "Ve vašem profilu nejsou žádné koncepty",
view_more: "Ukázat více",
select_fav_ingredients_to_find_perfumes: "Vyberte své oblíbené <b>ingredience</b><br> a najděte své<br> oblíbené <b>vůně</b>",
your_prefered_concepts: "Vaše oblíbené vlastnosti",
concept_fresh: "Svěží (např. citrusové)",
concept_intense: "Intenzivní (např. dřevité)",
concept_casual: "Na každodenní nošení (např. do práce)",
concept_special_occasion: "Pro zvláštní příležitost (např. na rande)",
concept_sure_bet: "Sázka na jistotu",
concept_novelty: "Módní (např. novinka)",
change_concepts: "Změnit vybrané vlastnosti",
select_concepts: "Už to skoro máme",
add_concepts: "Přidat další oblíbené vlastnosti",
or: "nebo",
explore_by: "Prozkoumat podle",
brands: "Značky",
your_selection: "Váš výběr",
list_view: "Seznam oblíbených vůní",
graphic_view: "Váš parfémový profil",
recos_based_on_selection: "Doporučení založená na vašem výběru",
add_to_profile: "Přidat do mého profilu",
do_you_want_to_reset: "Chcete znovu nastavit svůj profil?",
start_new: "Začít znovu",
keep_perfumes: "Zachovat parfémy",
click_to_view: "Kliknutím zobrazit",
already_in_profile: "Již přidáno",
only_showing_that_combine_with: "Zobrazují se pouze složky kombinované s",
see_all_ings_in_this_fam: "Zobrazit všechny ingredience této rodiny",
select_concepts_subtitle: "Chcete-li získat doporučení, musíte si vybrat alespoň 2 vůně (klikněte zpět) nebo přidat alespoň jednu z následujících vlastností parfému.",
action: "akce",
free_del: "doprava zdarma",
new_in: "nové",
cancel: "Zrušit",
see_more: "Další",
see_less: "Zobrazit méně",
recommendation_by: "Parfémy s těmito ingrediencemi",
apply: "Použít",
modal_limit_ingredients_title: "Maximální počet ingrediencí",
modal_limit_ingredients_text: "Přidat můžete maximálně 4 ingredience. Pokud chcete přidat další, jednu odeberte.",
empty_selection: "Nic jste nevybrali",
empty_selection_text: "Pro doporučení si vyberte jednu nebo více vůní",
search_perfume_subtitle: "Výborně! Teď máte přístup k vlastním doporučením na míru.",
perfume_search_subtitle: "Výborně! Teď máte přístup k vlastním doporučením na míru.",
filter_by_brand: "Filtrovat podle značky",
perfume_list: "Vyberte vůni",
others: "",
some_error: "Jejda! Vypadá to, že se na stránce vyskytla chyba. Snažíme se ji co nejrychleji opravit. Děkujeme za trpělivost!",
no_brands_found: "Žádná značka nebyla nalezena :("
}