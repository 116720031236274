export default {
welcome_to_profiler: "<b>Notino Fragrance Finder</b>",
what_are_you_looking_for: "Szeretnénk ajánlani egy új illatot, amely tökéletesen illik Önhöz vagy szeretteihez.<br> Kinek a számára keres ideális illatot?",
build_your_profile_and: "Hadd ajánljunk egy új illatot, amely tökéletesen illik Önhöz vagy szeretteihez.<br> Kinek a számára keres ideális illatot?",
search_by: "Megfelelő parfümöket ajánlunk az Ön ...",
gender_m: "Férfinak",
gender_f: "Nőnek",
mode_perfumes: "...Kedvenc parfümjei szerint",
mode_ingredients: "Összetevők",
back: "Vissza",
novelty: "Újdonság",
go_back_to_finder: "Visszatérés a Fragrance Finder oldalra",
done: "Kész",
best_sellers: "Bestseller",
from: "tól",
search_your_perfume: "<b>Válasszon<b> maximum 5 illatot, amely tetszik Önnek vagy annak, akinek a parfümöt választja.",
search_your_perfume_flat: "Kezdje el beírni kedvenc parfümjét vagy márkáját",
select_your_perfume: "<b>Válassza ki</b> kedvenc illatait.",
no_perfumes_found: "Nem találtunk semmilyen illatot :(",
no_ingredients_found: "Nem találtunk semmilyen összetevőt :(",
something_went_wrong: "Sajnos nem találtunk semmilyen eredményt. Próbáljon megadni egy új megnevezést",
select_fav_ings: "<b>Válassza ki </b> kedvenc összetevőit, és mi segítünk megtalálni a megfelelő parfümöt.",
search_fav_ings: "Ide írja be kedvenc összetevőjének nevét.",
building_your_profile: "<b>Elkészítjük</b> az Ön<br>parfümprofilját",
edit_your_profile: "A profil módosítása",
fragrances: "Illatok",
ingredients: "Összetevők",
concepts: "Fogalmak",
next_step: "Kész",
what_gender: "Kire vonatkozik ez a profil?",
selected_ingredients: "Kedvenc összetevői",
selected_perfumes: "Kedvenc illatai",
no_perfumes_yet: "Nincsenek illatok a profiljában",
no_ingredients_yet: "Nincsenek összetevők a profiljában",
no_conceptss_yet: "Nincsenek fogalmak a profiljában",
get_recomendations: "Önnek ajánljuk",
this_are_your_ings: "Ezek az Ön kedvenc összetevői",
your_profile_to_edit: "Az Ön profilja",
add_more_fragrances: "További illatanyagok hozzáadása",
add_more_ingredients: "További összetevők hozzáadása",
add_more_concepts: "További fogalmak hozzáadása",
top_6_recos: "TESTRE SZABOTT ILLATOK",
recos_based_on: "Ez az ajánlás az Ön parfümprofilján alapul.",
rest_of_recos: "További ajánlás",
view_details: "Részletek megtekintése",
your_profile_is_empty: "Az Ön profilja üres!",
add_to_profile_to_get_recos: "Adjon hozzá illatokat vagy összetevőket a profiljához, és szerezzen ajánlásokat",
price: "ár",
buy_it_now: "Tudjon meg többet",
prominent_ings: "Legkifejezőbb összetevők",
all_ings: "Az összetevők teljes listája",
why_do_we_recommend: "Miért ajánljuk <b>ezt az illatot</b>?",
other_ings_present: "Hasonlóan mint a többi, kevésbé jelentős",
description: "Leírás",
evaluate_this_reco: "Értékelje ezt az ajánlást",
how_relevant_do_you: "Ön szerint mennyire releváns <b>ez az ajánlás?</b>",
submit: "Elküldés",
check_your_new_recommendations_1: "<b>A profilt frissítettük.</b>Köszönjük!",
check_your_new_recommendations_2: "Új ajánlások.",
view_new_recommendations: "Új ajánlások megjelenítése",
close: "bezárni",
loading: "Beolvasás",

//Profile_desc,
you_love_perfumes_of_the: "<b>ezeket a parfümöket szereti</b><br>",
you_love_perfumes_of_the_plural: "<b>ezeket a parfümöket szereti</b><br>",
and: "és",
desc_family: "család",
desc_families: "családok",
you_also_love_plural: "szeret egy csipetnyit ebből is",
you_also_love: "szeret egy csipetnyit ebből is",
desc_sub_family: "alcsoport",
desc_sub_families: "alcsoportok",

//RECOS:,
it: "azaz",
also: "szintén",
$pronoun_self: "Ön",
$possessive_singular_self: "az Ön",
$possessive_refl_singular_self: "az én",
$possessive_plural_self: "az Ön",
$possessive_refl_plural_self: "az én",
$possessive_singular_prep_self: "az Ön",
$possessive_singular_liste_self: "az Ön",
$you_self: "Ön",
$you_prep_self: "Ön",
$do_you_have_self: "(Önnek) van",
$that_you_love_self: "amiket Ön szeret",
$you_love_self: "szereti",
$you_adore_self: "szereti",
$apreciate_self: "tetszik Önnek",
$you_have_self: "(Önnek) van",
reco_desc_title: "Ezt a parfümöt ajánljuk, mert",
possible_reco_title: "Lehet, hogy $you_love ezt a parfümöt, mert",
recommended_by_hero: "$you_adore a fő összetevőit:<b> %HERO%</b>",
recommended_by_fam: "beletartozik az egyikbe az $possessive_plural kedvenc parfümcsaládjai közül<b> %FAMILY%</b> (Ön)",
not_recommended_by_fam: "Bár más profilja van, mint az $possessive _singular_prep-nek, a $you_prep ki kellene próbálnia (Öné)",
recommended_by_ings: "az $possessive_plural kedvenc összetevői közül néhánnyal rendelkezik (Ön)",
furthermore: "Tovább",
home: "Új ajánlás",
my_profile: "Az én profilom",
no_concepts_yet: "Nincsenek fogalmak a profiljában",
view_more: "Mutasson többet",
select_fav_ingredients_to_find_perfumes: "Válassza ki kedvenc <b>összetevőit</b>, <br>és találja meg<br> kedvenc<b> illatait</b>",
your_prefered_concepts: "Az Ön által kedvelt tulajdonságok",
concept_fresh: "Friss (pl. citrusos)",
concept_intense: "Intenzív (pl. fás)",
concept_casual: "Mindennapi viseletre (pl. munkába)",
concept_special_occasion: "Különleges alkalmakra (pl. randevúra)",
concept_sure_bet: "Biztos tipp",
concept_novelty: "Divatos (pl. újdonság)",
change_concepts: "A kiválasztott tulajdonságok megváltoztatása",
select_concepts: "Már majdnem kész",
add_concepts: "További kedvelt tulajdonságok hozzáadása",
or: "vagy",
explore_by: "Megtekintés az alábbiak szerint",
brands: "Márkák",
your_selection: "Az Ön választása",
list_view: "Kedvelt illatok listája",
graphic_view: "Az Ön parfümprofilja",
recos_based_on_selection: "Ajánlás az Ön alábbi választása alapján",
add_to_profile: "A profilomhoz csatolandó",
do_you_want_to_reset: "Szeretné ismételten beállítani a profilját?",
start_new: "Kezdje újra",
keep_perfumes: "Tartsa meg a parfümöket",
click_to_view: "Kattintson a megtekintéshez",
already_in_profile: "Már hozzá lett adva",
only_showing_that_combine_with: "Csak az alábbiakkal kombinált összetevők megjelenítése",
see_all_ings_in_this_fam: "Ezen család valamennyi összetevőjének megjelenítése",
select_concepts_subtitle: "Az ajánlás megszerzéséhez ki kell választania legalább 2 illatot (kattintson vissza), vagy hozzá kell adnia legalább egyet a parfüm alábbi tulajdonságaiból.",
action: "akciók",
free_del: "ingyenes szállítás",
new_in: "új",
cancel: "törlés",
see_more: "Mutass többet",
see_less: "Mutass kevesebbet",
recommendation_by: "Ajánlások ezzel az összetevővel",
apply: "Alkalmaz",
modal_limit_ingredients_title: "Összetevők maximális száma",
modal_limit_ingredients_text: "Elérte a kiválasztható összetevők maximális korlátját (4). Kérjük, távolítson el egy összetevőt, mielőtt hozzáadna egy másikat.",
empty_selection: "Még nem választott",
empty_selection_text: "Javaslatokért válasszon ki egy vagy több illatot",
search_perfume_subtitle: "Nagyszerű! Most már Önnek is működik a személyes illatajánlója.",
perfume_search_subtitle: "Nagyszerű! Most már Önnek is működik a személyes illatajánlója.",
filter_by_brand: "Szűrés márka szerint",
perfume_list: "Válasszon illatot",
others: "",
some_error: "Hoppá! Úgy tűnik, hiba lépett fel a weboldalon. Dolgozunk rajta, hogy minél előbb elhárítsuk. Köszönjük türelmét!",
no_brands_found: "Sajnos nincs márkatalálat :("
}