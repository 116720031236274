export default {
welcome_to_profiler: "Welcome to the <b>Notino Fragrance Finder</b>.",
what_are_you_looking_for: "What are you looking for?",
build_your_profile_and: "Build your <b>olfactive profile</b> and get frangrance<br>recommendations tailored to your preferences.",
search_by: "Search by",
gender_m: "Male",
gender_f: "Female",
mode_perfumes: "Brands",
mode_ingredients: "Ingredients",
back: "Back",
novelty: "New",
go_back_to_finder: "Go back to finder",
done: "Done",
best_sellers: "Best sellers",
from: "from",
search_your_perfume: "<b>Search</b> your favourite fragrances.",
search_your_perfume_flat: "Search your favourite fragrances.",
select_your_perfume: "<b>Select</b> your favourite fragrances.",
no_perfumes_found: "No fragrances found :(",
no_ingredients_found: "No ingredients found :(",
something_went_wrong: "Something went wrong :(",
select_fav_ings: "<b>Select</b> your favourite ingredients.",
search_fav_ings: "Search your favourite ingredients.",
building_your_profile: "<b>Building</b> your<br>olfactive profile",
edit_your_profile: "Edit your profile",
fragrances: "Fragrances",
ingredients: "Ingredients",
concepts: "Concepts",
next_step: "Next step",
what_gender: "Who is this profile for?",
selected_ingredients: "Selected ingredients",
selected_perfumes: "Selected fragrances",
no_perfumes_yet: "There are no fragrances in your profile",
no_ingredients_yet: "There are no ingredients in your profile",
no_conceptss_yet: "There are no concepts in your profile",
get_recomendations: "Get recommendations",
this_are_your_ings: "Theese are your favourite ingredients",
your_profile_to_edit: "Your Profile",
add_more_fragrances: "Add more fragrances",
add_more_ingredients: "Add more ingredients",
add_more_concepts: "Add more concepts",
top_6_recos: "Top 6 recommendations",
recos_based_on: "Theese recommendations are based on yor olfactory profile.",
rest_of_recos: "Other recommendations",
view_details: "View details",
your_profile_is_empty: "Your profile is empty!",
add_to_profile_to_get_recos: "Add fragrances or ingredients to your profile to get some recommendations",
price: "price",
buy_it_now: "Buy it now",
prominent_ings: "Most prominent ingredients",
all_ings: "Complete list of ingredients",
why_do_we_recommend: "Why do we recommend <b>this fragrance</b>?",
other_ings_present: "As well as others, more subtly present",
description: "Description",
evaluate_this_reco: "Evaluate this recommendation",
how_relevant_do_you: "How relevant do you find <b>this recommendation</b>?",
submit: "Submit",
check_your_new_recommendations_1: "<b>Profile updated.</b> Thanks!",
check_your_new_recommendations_2: "You have new recommendations.",
view_new_recommendations: "View New Recommendations",
close: "close",
loading: "Loading",

//Profile_desc,
you_love_perfumes_of_the: "<b>you love perfumes</b> of the<br>",
you_love_perfumes_of_the_plural: "<b>you love perfumes</b> of the<br>",
and: "and",
desc_family: "family",
desc_families: "families",
you_also_love_plural: "you also love nuances of the",
you_also_love: "you also love nuances of the",
desc_sub_family: "subfamily",
desc_sub_families: "subfamilies",

//RECOS:,
it: "it",
also: "also",
$pronoun_self: "you",
$possessive_singular_self: "your",
$possessive_refl_singular_self: "my",
$possessive_plural_self: "your",
$possessive_refl_plural_self: "my",
$possessive_singular_prep_self: "your",
$possessive_singular_liste_self: "your",
$you_self: "you",
$you_prep_self: "you",
$do_you_have_self: "you have",
$that_you_love_self: "that you love",
$you_love_self: "you love",
$you_adore_self: "you love",
$apreciate_self: "like",
$you_have_self: "you have",
reco_desc_title: "We recommend this perfume because",
possible_reco_title: "Maybe $you_love this perfume because",
recommended_by_hero: "$you_adore its main ingredient: <b>%HERO%</b>",
recommended_by_fam: "belongs to one of $possessive_plural favorite olfactive families, <b>%FAMILY%</b>",
not_recommended_by_fam: "Even though it has a different profile than $possessive_singular_prep, $you_prep should try it",
recommended_by_ings: "it has some of $possessive_plural favorite ingredients: ",
furthermore: "Furthermore",
home: "Home",
my_profile: "My Profile",
no_concepts_yet: "There are no concepts in your profile",
view_more: "View more",
select_fav_ingredients_to_find_perfumes: "Select your favourite <b>ingredients</b><br>to find your<br> favourite <b>fragrances</b>",
your_prefered_concepts: "Your prefered concepts",
concept_fresh: "Fresh",
concept_intense: "Intense",
concept_casual: "Casual",
concept_special_occasion: "Special occasion",
concept_sure_bet: "Sure bet",
concept_novelty: "Novelty",
change_concepts: "Change concepts",
select_concepts: "Select concepts",
add_concepts: "Add concepts",
or: "or",
explore_by: "Explore by",
brands: "Brands",
your_selection: "Your selection",
list_view: "List view",
graphic_view: "Graphic view",
recos_based_on_selection: "Recommendations based on your selection",
add_to_profile: "Add to my profile",
do_you_want_to_reset: "Do you want to reset your profile",
start_new: "Start anew",
keep_perfumes: "Keep perfumes",
click_to_view: "Click to view",
already_in_profile: "Already added",
only_showing_that_combine_with: "Only showing ingredients that combine with",
see_all_ings_in_this_fam: "See all ingredients in this family",
select_concepts_subtitle: "To receive recommendations you must have selected at least 2 fragrances or add a concept to your search",
action: "action",
free_del: "free delivery",
new_in: "new",
cancel: "cancel",
see_more: "See More",
see_less: "See Less",
recommendation_by: "Recommendations with this ingredients",
apply: "Apply",
modal_limit_ingredients_title: "Maximum of ingredients",
modal_limit_ingredients_text: "You have reached the maximum limit for selecting ingredients (4). Please remove one ingredient before adding another.",
empty_selection: "You have no selections",
empty_selection_text: "Select one or more fragrances for recommendations",
search_perfume_subtitle: "Great! You now have access to your personalized recommendations.",
perfume_search_subtitle: "Great! You now have access to your personalized recommendations.",
filter_by_brand: "Filter by brand",
perfume_list: "Select a fragrance",
others: "Others",
some_error: "Oops! Looks like there's an error on the website. We're working on fixing it ASAP. Thanks for your patience!",
no_brands_found: "No brands found :("
}