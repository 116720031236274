<template>
  <div class="evaluate-drawer" :class="{ visible }">
    <div class="overlay"></div>
    <div class="drawer">
      <div class="close" @click="onClose"></div>
      <div class="description" v-html="$t('how_relevant_do_you')"></div>
      <div class="buttons">
        <div
          class="rate-button"
          :class="{ active: currentRating != null && i <= currentRating }"
          v-for="i in [0, 1, 2, 3, 4, 5]"
          :key="i"
          @click="currentRating = i"
        >
          {{ i }}
        </div>
      </div>
      <div class="submit button" @click="onSubmit">{{ $t("submit") }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentRating: null,
      visible: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.visible = true;
    });
  },
  methods: {
    hide() {
      this.visible = false;
      return new Promise((res) => {
        setTimeout(() => {
          res();
        }, 800);
      });
    },
    onClose() {
      this.hide().then(() => this.$emit("close"));
    },
    onSubmit() {
      this.visible = false;
      this.hide().then(() => this.$emit("submit", this.currentRating));
    },
  },
};
</script>

<style lang="stylus" scoped>
.evaluate-drawer
  height: 100%
  position: fixed
  top: 0
  width: 100%
  z-index: 3

  .overlay
    background: rgba(0, 0, 0, 0.3)
    height: 100%
    opacity: 0
    overflow: hidden
    position: absolute
    transition: opacity 0.8s ease-in-out
    width: 100%

    .visible&
      opacity: 1

  .drawer
    align-items: center
    background-color: #fff
    bottom: -50%
    display: flex
    flex-direction: column
    padding: vh(30px) vw(20px) vh(20px)
    position: absolute
    transition: bottom 0.8s ease-in-out
    width: 100%

    .mobile &
      padding: vw(30px) vw(20px) vw(20px)

    .visible&
      bottom: 0

    .close
      cursor: pointer
      m-icon("close", 18)
      position: absolute
      right: vw(20px)
      top: vh(30px)

      .mobile &
        top: vw(30px)

    .description
      m-font-size(16, 20)
      margin-bottom: vh(30px)
      text-align: center
      width: vw(300px)

      .mobile &
        margin-bottom: vw(20px)
        margin-right: vw(50px)
        text-align: left
        width: "calc(100% - %s)" % vw(50px)

    .buttons
      display: flex
      justify-content: space-between
      margin-bottom: vh(30px)
      width: vw(400px)

      .mobile &
        margin-bottom: vw(20px)
        width: 100%

      .rate-button
        border: 1px solid #000
        border-radius: 4px
        padding: vw(10px) vw(15px)

        &.active
          background-color: #000
          color: #fff

    .submit
      width: vw(400px)

      .mobile &
        margin-left: 0
        margin-right: 0
        width: 100%
</style>