<template>
  <div
    class="profile-summary"
    v-if="
      (mode === 'perfumes' && profilePerfumes) ||
      (mode === 'ingredients' && profileIngredients)
    "
  >
    <!-- {{profileIngredients && profileIngredients.map((i)=>i.name)}} {{profilePerfumes && profilePerfumes.map((p)=>p.name)}}
    {{donutData}} -->
    <Back class="donut" v-if="isRecoView && !isMobile" />
    <div
      class="title"
      :class="{ disabled: !fragranceCount && !ingredientsCount }"
    >
      <div class="text" v-html="$t('your_selection')"></div>
    </div>
    <profile-desc v-if="!isMobile"></profile-desc>
    <div class="donut-wrapper" v-if="donutData && donutData.length">
      <!-- <Donut :data="donutData"></Donut> -->
      <Donut :data="donutData" :show-labels="true" :snap-angle="-90"></Donut>
    </div>
    <div class="actions">
      <!-- <router-link to="/profile-edit" v-if="mode==='perfumes'" class="goto-edit" @click="trackEditProfile"> <div >{{$t('edit_your_profile')}}</div></router-link> -->
      <div class="buttons">
        <div
          v-if="mode === 'perfumes' || isMobile"
          class="button btn--secondary"
          :class="{ disabled: selectionCount === 0 }"
          @click="showSummary = !showSummary"
        >
          <span
            >{{ showSummary ? $t("graphic_view") : $t("list_view") }}
            <span v-if="selectionCount > 0" class="num"
              >({{ selectionCount }})</span
            ></span
          >
        </div>
        <div
          v-if="!isRecoView"
          class="button"
          :class="{ disabled: selectionCount === 0 }"
          @click="tryGoToRecos"
        >
          {{ $t("get_recomendations") }}
        </div>
        <!-- <div v-if="mode==='ingredients'" class="button btn--secondary" :class="{disabled: ingredientsCount===0}" @click="showSummary=true">{{$t('ingredients')}} ({{ingredientsCount}})</div>  -->
        <!-- <div v-if="mode==='perfumes'" class="button" :class="{disabled: fragranceCount===0}" @click="tryGoToProfile">{{$t('get_recomendations')}}</div>
        <div v-else class="button" :class="{disabled: ingredientsCount===0}" @click="$emit('nextStep')">{{$t('get_recomendations')}}</div> -->
      </div>
    </div>
    <transition name="slide">
      <div
        class="overlay perfumes-summary"
        :class="{ full: isFull }"
        v-if="mode === 'perfumes' && showSummary"
      >
        <div class="overlay-title">{{ $t("selected_perfumes") }}</div>
        <div class="overlay-list">
          <profile-edit-item
            v-for="perfume in profilePerfumes"
            :key="perfume.id"
            :data="perfume"
            type="perfume"
          ></profile-edit-item>
        </div>
        <div
          class="close-btn"
          v-if="isMobile"
          @click="showSummary = false"
        ></div>
      </div>
      <div
        class="overlay ingredients-summary"
        :class="{ full: isFull }"
        v-else-if="mode === 'ingredients' && showSummary"
      >
        <Back v-if="isRecoView && !isMobile" />
        <div class="overlay-title">{{ $t("selected_ingredients") }}</div>
        <div class="overlay-list">
          <profile-edit-item
            v-for="ing in profileIngredients"
            :key="ing.id"
            :data="ing"
            type="ingredient"
            @onTrash="handleTrash"
          ></profile-edit-item>
        </div>
        <div
          class="close-btn"
          v-if="isMobile"
          @click="showSummary = false"
        ></div>
      </div>
    </transition>
  </div>
</template>

<script>
// import Donut from "@/components/Legacy/Donut";
import Donut from "@bestiario/puig-donut";
import ProfileEditItem from "./ProfileEditItem.vue";
import ProfileDesc from "./ProfileDesc.vue";
import Back from "./Back.vue";
import { RouteNames } from "../router";
// import { brTracker } from '../services/tracker';
export default {
  components: {
    Donut,
    ProfileEditItem,
    ProfileDesc,
    Back,
  },
  props: ["mode", "tempIngredients"],
  data() {
    return {
      showSummary: false,
    };
  },
  mounted() {
    this.showSummary = !this.isMobile;
  },
  computed: {
    /** for hide donut in background */
    isFull() {
      return !(this.mode === "perfumes" || this.isMobile || !this.isRecoView);
    },
    isRecoView() {
      return (
        this.$route.name === "IngredientSearch" &&
        !!this.$route.params.recommendation
      );
    },
    hideRemoveIngredients() {
      return this.isRecoView && this.profileIngredients?.length <= 1;
    },
  },
  asyncComputed: {
    profilePerfumes() {
      return this.$store.getters.perfumes();
    },
    profileIngredients() {
      // Hay que usar los EXPLICITOS SOLO!
      return this.$store.getters.ingredients();
    },
    profileConcepts() {
      return this.$store.getters.concepts();
    },
    donutData() {
      if (this.$route.name === "IngredientSearch")
        return this.$store.getters.familiesWithSubsByIngredients();
      else return this.$store.getters.familiesWithSubsByPerfumes();
      // return (this.profileIngredients || this.profilePerfumes) && this.$store.getters.profileFamiliesWithSubs()
    },
    fragranceCount() {
      return this.profilePerfumes ? this.profilePerfumes.length : 0;
    },
    ingredientsCount() {
      return this.profileIngredients ? this.profileIngredients.length : 0;
      // return this.tempIngredients?this.tempIngredients.length:0
    },
    selectionCount() {
      // return this.fragranceCount || this.ingredientsCount
      if (this.$route.name === "IngredientSearch") return this.ingredientsCount;
      else return this.fragranceCount;
    },
  },
  methods: {
    handleTrash(){
      if(this.$route.name === RouteNames.IngredientSearch
        && this.isRecoView
        && this.profileIngredients.length === 1){
          this.$router.back()
          if(this.isMobile)
            this.showSummary = false
        }
    },
    tryGoToProfile() {
      if (this.profilePerfumes.length > 1 || this.profileConcepts.length > 0) {
        this.$router.push({ name: "Profile" });
      } else {
        this.$router.push({ name: "ConceptsSelect" });
      }
    },
    tryGoToRecos() {
      // brTracker.track(brTracker.eventNameEnum.CTA_RECO_BTN)
      if (this.mode === "perfumes") {
        if (
          this.profilePerfumes.length > 1 ||
          this.profileConcepts.length > 0
        ) {
          this.$router.push({
            name: "Recommendations",
            params: { mode: "perfumes" },
          });
        } else {
          this.$router.push({ name: "ConceptsSelect" });
        }
      } else {
        this.$router.push({ name: "RecommendationByIngredients" });
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.profile-summary
  display: flex
  flex-direction: column
  height: 100%
  position: relative

  >>>.back-button
    &.donut
      margin: vw(30px)

  .title
    align-items: flex-start
    display: flex
    // flex: 0 0 20%
    flex-direction: column
    margin-top: vw(30px)
    padding-left: vw(30px)
    padding-right: vw(30px)
    text-align: center

    &.disabled
      opacity: 0.4

    .text
      m-font-size(24, 30)

  >>> .profile-desc
    margin-top: vh(30px)
    padding-left: vw(30px)
    padding-right: vw(30px)

  .donut-wrapper
    flex: 1 1 0%
    width: 100%

    // height: "calc(100% - %s - %s)" % (vh(60px) vh(15px))
    .donut
      height: 100%
      width: 100%

  .actions
    align-items: center
    background: #fff
    display: flex
    // flex: 1 1 0%
    flex-direction: column
    justify-content: flex-end

    .goto-edit
      m-font-size(14, 20)
      margin-bottom: vw(60px)
      text-decoration: underline

    .buttons
      align-items: center
      display: flex
      justify-content: center
      margin-bottom: vh(15px)
      margin-left: vw(30px)
      margin-right: vw(30px)
      width: "calc(100% - (%s * 2))" % vw(30px)

      .button
        m-font-size(15, 20)
        flex: 0 0 "calc(50% - (%s / 2))" % vw(10px)
        height: vh(60px)
        padding: 0.75em 0.5em
        position: relative

        &:only-child
          flex: 0 0 100%

        + .button
          margin-left: vw(10px)

  .overlay
    background-color: #fff
    display: flex
    flex-direction: column
    height: "calc(100% - %s - %s)" % (vh(60px) vh(15px))
    // height: 100%
    left: 0
    overflow: hidden
    padding: vw(30px)
    position: absolute
    top: 0
    width: 100%
    z-index: 1

    &.full
      height: 100%

    &.slide-enter-active,
    &.slide-leave-active
      transition: transform 0.5s ease-in-out

    &.slide-enter,
    &.slide-leave-to
      transform: translate(100%, 0)

    .close-btn
      m-icon("close", 18)
      cursor: pointer
      position: absolute
      right: vw(20px)
      top: vh(20px)

    .overlay-title
      m-font-size(25, 30)
      // font-weight: $fw-bold
      margin-bottom: vh(30px)
      // margin-top: vh(30px)

    .overlay-list
      flex: 1 1 0%
      overflow-y: auto

  .mobile &
    .donut-wrapper,
    .title
      display: none

    .actions
      box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.05)
      flex: 0 0 vw(80px)
      justify-content: center
      z-index: 2

      .buttons
        align-items: stretch
        margin: 0
        width: 95%

        .button
          height: auto

      .goto-edit
        display: none

    // BASURA CALC....
    .overlay
      $transform = "calc(-100% + %s)" % $header-height-mobile
      display: flex
      flex-direction: column
      // height: "calc(100vh - %s)" % $header-height-mobile
      height: calc(var(--vh, 1vh) * 100)
      overflow-y: auto
      padding: vw(60px) vw(20px) vw(100px)
      top: 100% // "calc(100% + %s)" % vh(80px)
      transform: translateY(-100%)

      &.slide-enter-active,
      &.slide-leave-active
        transition: transform 0.5s ease-in-out

      &.slide-enter,
      &.slide-leave-to
        transform: translate(0, 10%)

      .overlay-title
        m-font-size(16, 20)
        margin-bottom: vw(20px)

      .close-btn
        top: vw(60px)
</style>