export default {
welcome_to_profiler: "<b>Notino Fragrance Finder</b>",
what_are_you_looking_for: "Lăsați-ne să vă recomandăm un nou parfum care vi se potrivește dvs. sau celor dragi ca o mănușă.<br> Pentru cine căutați parfumul perfect?",
build_your_profile_and: "Lăsați-ne să vă recomandăm un nou parfum care vi se potrivește dvs. sau celor dragi ca o mănușă.<br> Pentru cine căutați parfumul perfect?",
search_by: "Vă vom recomanda parfumuri în funcție de",
gender_m: "Pentru bărbați",
gender_f: "Pentru femei",
mode_perfumes: "Parfumuri preferate",
mode_ingredients: "Ingrediente",
back: "Înapoi",
novelty: "Noutate",
go_back_to_finder: "Întoarceți-vă la Fragrance Finder",
done: "Terminat",
best_sellers: "Bestseller",
from: "de la",
search_your_perfume: "<b>Alegeți<b> maximum 5 parfumuri care vă plac dvs. sau persoanei pentru care alegeți parfumul.",
search_your_perfume_flat: "Începeți să scrieți parfumul sau brandul dvs. preferat",
select_your_perfume: "<b>Alegeți</b> parfumurile dvs. preferate.",
no_perfumes_found: "Nu a fost găsit niciun parfum :(",
no_ingredients_found: "Nu a fost găsit niciun ingredient :(",
something_went_wrong: "Din păcate, nu am găsit niciun rezultat. Încercați să introduceți o denumire nouă",
select_fav_ings: "<b>Alegeți</b> ingredientele dvs. preferate și vă vom ajuta să găsiți parfumul potrivit.",
search_fav_ings: "Introduceți aici numele ingredientului dvs. preferat.",
building_your_profile: "<b>Creăm</b> profilul dvs.<br> de parfum",
edit_your_profile: "Modificați profilul",
fragrances: "Parfum",
ingredients: "Ingrediente",
concepts: "Concepte",
next_step: "Terminat",
what_gender: "Pentru cine este destinat acest profil?",
selected_ingredients: "Ingredientele dvs. preferate",
selected_perfumes: "Parfumurile dvs. preferate",
no_perfumes_yet: "Nu există parfumuri în profilul dvs.",
no_ingredients_yet: "Nu există ingrediente în profilul dvs.",
no_conceptss_yet: "Nu există concepte în profilul dvs.",
get_recomendations: "Obțineți recomandări",
this_are_your_ings: "Acestea sunt ingredientele dvs. preferate",
your_profile_to_edit: "Profilul dvs.",
add_more_fragrances: "Adăugați alte parfumuri",
add_more_ingredients: "Adăugați alte ingrediente",
add_more_concepts: "Adăugați alte concepte",
top_6_recos: "PARFUMURI PERSONALIZATE PENTRU DVS.",
recos_based_on: "Aceste recomandări se bazează pe profilul dvs. de parfum.",
rest_of_recos: "Alte recomandări",
view_details: "Vizualizați detalii",
your_profile_is_empty: "Profilul dvs. este gol!",
add_to_profile_to_get_recos: "Adăugați parfumuri sau ingrediente la profilul dvs. și primiți recomandări",
price: "preț",
buy_it_now: "Aflați mai multe",
prominent_ings: "Cele mai puternice ingrediente",
all_ings: "Lista completă de ingrediente",
why_do_we_recommend: "De ce recomandăm <b>acest parfum</b>?",
other_ings_present: "La fel ca și altele, mai puțin pronunțate",
description: "Descriere",
evaluate_this_reco: "Evaluați această recomandare",
how_relevant_do_you: "Cât de relevantă credeți că este <b>această recomandare</b>?",
submit: "Expediere",
check_your_new_recommendations_1: "<b>Profil actualizat.</b> Mulțumim!",
check_your_new_recommendations_2: "Aveți noi recomandări.",
view_new_recommendations: "Vedeți noile recomandări",
close: "închidere",
loading: "Se încarcă",

//Profile_desc,
you_love_perfumes_of_the: "<b>iubiți parfumurile</b><br>",
you_love_perfumes_of_the_plural: "<b>iubiți parfumurile</b><br>",
and: "și",
desc_family: "familia",
desc_families: "familiile",
you_also_love_plural: "vă plac și subgrupele",
you_also_love: "vă plac și subgrupa",
desc_sub_family: "subgrupa",
desc_sub_families: "subgrupe",

//RECOS:,
it: "asta",
also: "la fel",
$pronoun_self: "dvs.",
$possessive_singular_self: "a dvs.",
$possessive_refl_singular_self: "a mea",
$possessive_plural_self: "a dvs.",
$possessive_refl_plural_self: "a mea",
$possessive_singular_prep_self: "a dvs.",
$possessive_singular_liste_self: "a dvs.",
$you_self: "dvs.",
$you_prep_self: "dvs.",
$do_you_have_self: "aveți",
$that_you_love_self: "care vă plac",
$you_love_self: "vă plac",
$you_adore_self: "vă plac",
$apreciate_self: "vă place",
$you_have_self: "aveți",
reco_desc_title: "Recomandăm acest parfum pentru că",
possible_reco_title: "Poate $you_love acest parfum pentru că",
recommended_by_hero: "$you_adore ingredientele sale principale: <b>%HERO%</b>",
recommended_by_fam: "aparține uneia dintre familiile de parfumuri $possessive_plural preferate, <b>%FAMILY%</b>",
not_recommended_by_fam: "chiar dacă are un alt profil decât $possessive_singular_prep, $you_prep ar trebui sa îl încercați",
recommended_by_ings: "are unele dintre $possessive_plural ingredientele preferate: ",
furthermore: "Mai departe",
home: "Recomandare nouă",
my_profile: "Profilul meu",
no_concepts_yet: "Nu există concepte în profilul dvs.",
view_more: "Arată mai mult",
select_fav_ingredients_to_find_perfumes: "Selectați <b>ingredientele</b><br> preferate și găsiți<br> parfumurile <b>preferate</b>",
your_prefered_concepts: "Caracteristicile dvs. preferate",
concept_fresh: "Fresh (de exemplu, de citrice)",
concept_intense: "Intens (de exemplu, lemnos)",
concept_casual: "pentru fiecare zi (de exemplu, la serviciu)",
concept_special_occasion: "Pentru o ocazie specială (de exemplu, la o întâlnire)",
concept_sure_bet: "Succesul garantat",
concept_novelty: "Modern (de exemplu, noutate)",
change_concepts: "Schimbarea caracteristicilor selectate",
select_concepts: "Aproape am terminat",
add_concepts: "Adăugați mai multe caracteristici preferate",
or: "sau",
explore_by: "Explorați după",
brands: "Branduri",
your_selection: "Alegerea dvs.",
list_view: "Lista de parfumuri preferate",
graphic_view: "Profilul dvs. de parfum",
recos_based_on_selection: "Recomandări în funcție de alegerea dvs.",
add_to_profile: "Adăugați la profilul meu",
do_you_want_to_reset: "Doriți să vă configurați din nou profilul?",
start_new: "Începeți din nou",
keep_perfumes: "Păstrați parfumul",
click_to_view: "Faceți clic pentru a vizualiza",
already_in_profile: "Deja adăugat",
only_showing_that_combine_with: "Se afișează numai produse combinate cu",
see_all_ings_in_this_fam: "Vizualizați toate ingredientele din această familie",
select_concepts_subtitle: "Pentru a primi recomandări, trebuie să alegeți cel puțin 2 parfumuri (click înapoi) sau să adăugați cel puțin una dintre următoarele caracteristici ale parfumului.",
action: "promoții",
free_del: "livrare gratuită",
new_in: "nou",
cancel: "anulare",
see_more: "Vezi mai mult",
see_less: "Vezi mai puțin",
recommendation_by: "Parfumuri cu aceste ingrediente",
apply: "Aplică",
modal_limit_ingredients_title: "Numărul maxim de ingrediente",
modal_limit_ingredients_text: "Puteți adăuga cel mult 4 ingrediente. Dacă doriți să adăugați mai multe, scoateți unul.",
empty_selection: "Nu aveți nicio selecție",
empty_selection_text: "Selectați unul sau mai multe parfumuri, pentru recomandări",
search_perfume_subtitle: "Grozav! Acum aveți acces la recomandările dvs. personalizate.",
perfume_search_subtitle: "Grozav! Acum aveți acces la recomandările dvs. personalizate.",
filter_by_brand: "Filtrare în funcție de brand",
perfume_list: "Selectați un parfum",
others: "",
some_error: "Opa! Se pare că există o eroare pe site-ul web. Lucrăm la remedierea acesteia în cel mai scurt timp posibil. Vă mulțumim pentru răbdare!",
no_brands_found: "Nu s-a găsit niciun brand :("
}