<template>
  <div class="mode-select view">
    <div class="background"></div>
    <div class="step">
      <div class="title" v-html="$t('build_your_profile_and')"></div>
      <div class="description">{{$t('search_by')}}:</div>
      <div class="buttons">
        <router-link class="button" :to="{name:'PerfumeSearch'}">{{$t('mode_perfumes')}}</router-link>
        <router-link class="button" :to="{name:'IngredientSearch'}">{{$t('mode_ingredients')}}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { brTracker } from '../services/tracker'
export default {
  mounted() {
    brTracker.pageVisit(brTracker.TypePageVisitedEvent.VIEW_CHOOSE_PATH)
    this.eventTracker.emitEvent('VIEW_OPENED', { type: 'FIND_BY' })
  },
  computed:{
    
  },
  methods:{

  }
}
</script>

<style lang="stylus">
// Usa los estilos de Welcome.vue
</style>