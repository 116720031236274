<template>
  <div class="view perfume">
    <div class="content" v-if="data">
      <div class="close-btn" @click="$router.back()"></div>
      <div class="wrapper">
        <div class="left">
          <div class="info">
            <div class="name">{{ data.name }}</div>
            <div class="brand">{{ data.brand.name }}</div>
          </div>
          <div class="image-container">
            <div
              class="image"
              :style="{ backgroundImage: `url(${bestImage})` }"
            ></div>
          </div>
          <div class="price-container">
            <div class="weight">
              {{ productSize ? productSize + " ml" : "" }}
            </div>
            <div class="price">{{ productPrice ? price : "" }}</div>
          </div>
          <div class="actions">
            <a
              class="button"
              v-if="buyUrl"
              :href="buyUrl"
              target="_blank"
              @click="onBuyClicked"
              >{{ $t("buy_it_now") }}</a
            >
            <!-- <div class="button" :class="{disabled: isInProfile}" @click="onAddToProfile">{{isInProfile?$t('already_in_profile'):$t('add_to_profile')}}</div> -->
          </div>
        </div>
        <div class="right">
          <div class="quad-title">{{ $t("prominent_ings") }}</div>
          <quad-pack :proportions="data.ingredientProportions"></quad-pack>
          <div class="ings-title">{{ $t("all_ings") }}</div>
          <div class="ingredients">
            <div
              class="ingredient"
              v-for="ing in data.ingredientProportions"
              :key="ing.id"
            >
              {{ ing.ingredient.name }}
            </div>
          </div>
          <div class="why-container" v-if="profileIngredients">
            <div class="why-title" v-html="$t('why_do_we_recommend')"></div>
            <reco-desc :data="data"></reco-desc>
            <div
              class="ings-group main-ings"
              v-if="mainMatchIngs && mainMatchIngs.length"
            >
              <div class="ings-list">
                <ingredient-item
                  v-for="ing in mainMatchIngs"
                  :data="ing"
                  :key="ing.id"
                ></ingredient-item>
              </div>
            </div>
            <div
              class="ings-group other-ings"
              v-if="otherMatchIngs && otherMatchIngs.length"
            >
              <div class="ings-title">{{ $t("other_ings_present") }}</div>
              <div class="ings-list">
                <ingredient-item
                  v-for="ing in otherMatchIngs"
                  :data="ing"
                  :key="ing.id"
                ></ingredient-item>
              </div>
            </div>
          </div>
          <!-- LO QUITAMOS PORQUE NO LO TENEMOS EN CZ -->
          <!-- <div class="description">
          <div class="desc-title">{{$t('description')}}</div>
          <div class="desc-content">{{data.description}}</div>
        </div> -->
          <div class="evaluate-btn" @click="showEvaluate = true">
            {{ $t("evaluate_this_reco") }}
          </div>
        </div>
      </div>
    </div>
    <evaluate-drawer
      v-if="showEvaluate"
      @submit="onEvaluated"
      @close="showEvaluate = false"
    ></evaluate-drawer>
  </div>
</template>

<script>
import { getPerfume } from "@/services/api";
import { QuadPack as QuadPack } from "@bestiario/puig-quad-pack";
import EvaluateDrawer from "../components/EvaluateDrawer.vue";
import RecoDesc from "../components/Legacy/RecoDesc.vue";
import IngredientItem from "../components/Legacy/IngredientItem.vue";
import { brTracker } from "../services/tracker";
import { mapGetters } from "vuex";
import { formatPrice } from "@/utils/textUtils";
export default {
  props: [],
  components: {
    QuadPack,
    EvaluateDrawer,
    RecoDesc,
    IngredientItem,
  },
  data() {
    return {
      showEvaluate: false,
    };
  },
  asyncComputed: {
    data() {
      return getPerfume(this.$router.currentRoute.params.id);
    },

    //  let userMains = Utils.getProfileMainIngredients(this.props.profile)
    //   topIngs = userMains.filter((ui)=>this.state.data.ingredientProportions.slice(0,5).findIndex((i)=>(i.ingredientId || i.ingredient.id)===ui.id)>=0)
    //   otherIngs = userMains.filter((ui)=>this.state.data.ingredientProportions.slice(5).findIndex((i)=>(i.ingredientId || i.ingredient.id)===ui.id)>=0)
    //   topIngs.sort((a,b)=>this.state.data.ingredientProportions.findIndex((prp)=>prp.ingredient.id===a.id)-this.state.data.ingredientProportions.findIndex((prp)=>prp.ingredient.id===b.id))
    //   otherIngs.sort((a,b)=>this.state.data.ingredientProportions.findIndex((prp)=>prp.ingredient.id===a.id)-this.state.data.ingredientProportions.findIndex((prp)=>prp.ingredient.id===b.id))
    profileIngredients() {
      return this.$store.getters.profileIngredients();
    },
    profilePerfumes() {
      return this.$store.getters.perfumes();
    },
    profileConcepts() {
      return this.$store.getters.concepts();
    },
    profileSelectedIntredients() {
      return this.$store.getters.ingredients();
    },
  },
  computed: {
    completeProfileStore() {
      if (
        !this.profilePerfumes ||
        !this.profileConcepts ||
        !this.profileSelectedIntredients
      )
        return null;
      return {
        perfumes: this.profilePerfumes,
        concepts: this.profileConcepts,
        ingredients: this.profileSelectedIntredients,
      };
    },
    ...mapGetters(["installation"]),
    price() {
      const countryCode = this.installation?.countryCode;
      return formatPrice(this.productPrice, countryCode);
    },
    mainMatchIngs() {
      return this.profileIngredients.filter(
        (ing) =>
          this.data.ingredientProportions
            .slice(0, 5)
            .findIndex((prop) => prop.ingredient.id === ing.id) >= 0
      );
    },
    otherMatchIngs() {
      return this.profileIngredients.filter(
        (ing) =>
          this.data.ingredientProportions
            .slice(5)
            .findIndex((prop) => prop.ingredient.id === ing.id) >= 0
      );
    },
    bestEan() {
      if (!this.data || !this.data.eanData) return null;
      let bestEan = Object.values(this.data.eanData).find((d) => d.isMaster);
      if (!bestEan) bestEan = Object.values(this.data.eanData)[0];
      return bestEan;
    },
    bestImage() {
      return this.bestEan && this.bestEan.imageUrl
        ? this.bestEan.imageUrl
        : this.data.imageUrl;
    },
    buyUrl() {
      // return (this.data.productUrl || 'https://www.notino.cz/carolina-herrera/212-toaletni-voda-pro-muze/')+'?utm_source=fragrance-finder&utm_medium=referrer&utm_campaign=fragrance-finder_buy-link'
      // return this.data.eanData && Object.values(this.data.eanData)[0] && Object.values(this.data.eanData)[0].productUrl
      let url = this.bestEan ? this.bestEan.productUrl : null;
      return url
        ? url +
            "?utm_source=fragrance-finder&utm_medium=referrer&utm_campaign=fragrance-finder_buy-link"
        : null;
    },
    productPrice() {
      return this.bestEan && this.bestEan.availability > 0
        ? this.bestEan.price
        : null;
    },
    productSize() {
      return this.bestEan ? this.bestEan.size : null;
    },
    isInProfile() {
      return (
        this.profilePerfumes &&
        this.profilePerfumes.find((p) => p.id === this.data.id)
      );
    },
  },
  mounted() {},
  methods: {
    onEvaluated(rating) {
      console.log("EAVLUATED WITH ", rating);
      this.eventTracker.emitEvent("ACTION", {
        type: "EVALUATE",
        data: {
          id: this.data.id,
          sku: this.bestEan ? this.bestEan.sku : null,
          notinoPerfumeId: this.bestEan ? this.bestEan.notinoPerfumeId : null,
          rating,
        },
      });
      // if(rating>=3) {
      //   this.$store.dispatch('addPerfume',this.data)
      // }
      // else {
      //   this.$store.dispatch('removePerfume',this.data)
      //   this.$store.dispatch('addDisliked',this.data)
      // }
      this.showEvaluate = false;
    },
    onBuyClicked() {
      //navigate to whatever
      this.eventTracker.emitEvent("ACTION", {
        type: "BUY",
        data: {
          id: this.data.id,
          sku: this.bestEan ? this.bestEan.sku : null,
          notinoPerfumeId: this.bestEan ? this.bestEan.notinoPerfumeId : null,
        },
      });
      brTracker.track(brTracker.eventNameEnum.CTA_BUY, {
        ...brTracker.getPerfumeInfo(this.data),
        ...brTracker.getRecommendedByInfo(
          this.$route.query.fromReco ? this.completeProfileStore : {}
        ),
      });
    },
    onAddToProfile() {
      this.$store.dispatch("addPerfume", this.data);
    },
    bloomreachTrack() {
      brTracker.track(brTracker.eventNameEnum.VIEW_PERFUME, {
        ...brTracker.getPerfumeInfo(this.data),
        ...brTracker.getRecommendedByInfo(
          this.$route.query.fromReco ? this.completeProfileStore : {}
        ),
      });
    },
  },
  watch: {
    completeProfileStore() {
      if (this.completeProfileStore && this.data && !this.alreadyTracked) {
        this.bloomreachTrack();
        this.alreadyTracked = true;
      }
    },
    data() {
      this.eventTracker.emitEvent("VIEW_OPENED", {
        type: "PERFUME_VIEWED",
        from: this.$route.query.fromReco ? "RECOMMENDATIONS_VIEW" : undefined,
        data: {
          id: this.data.id,
          sku: this.bestEan ? this.bestEan.sku : null,
          notinoPerfumeId: this.bestEan ? this.bestEan.notinoPerfumeId : null,
        },
      });
      if (!this.alreadyTracked && this.completeProfileStore) {
        brTracker.track(brTracker.eventNameEnum.VIEW_PERFUME, {
          ...brTracker.getPerfumeInfo(this.data),
          ...brTracker.getRecommendedByInfo(
            this.$route.query.fromReco ? this.completeProfileStore : {}
          ),
        });
        this.alreadyTracked = true;
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.perfume
  .close-btn
    m-icon("close", 25)
    cursor: pointer
    position: absolute
    right: vw(50px)
    top: vw(30px)
    z-index 10

    .desktop &
      left: "calc(100% - %s)" % vw(50px)
      position: sticky
      right: auto

    .mobile &
      m-icon("close", 20)
      right: vw(20px)
      top: vw(22px)

  .content
    height: 100%
    overflow-y: auto
    position: relative

    .wrapper
      display: flex
      margin-left: auto
      margin-right: auto
      max-width: vw(1024px)

      .desktop &
        margin-top: vw(-25px)

    .left,
    .right
      padding: vw(30px)

    .right
      padding-right: vw(90px)

  .left
    $margin = vw(20px)
    display: flex
    flex: 0 0 "calc(50% - %s)" % $margin
    flex-direction: column
    margin-right: $margin

    .desktop &
      height: "calc(100vh - %s)" % $header-height
      position: sticky
      top: 0

    .info
      .mobile &
        margin-right: vw(40px)

      .name
        font-weight: $fw-medium
        m-font-size(28)
        margin-bottom: vh(5px)

        .mobile &
          m-font-size(20, 24)

      .brand
        m-font-size(24)

        .mobile &
          m-font-size(14, 20)

    .image-container
      flex: 1 1 0%
      margin: vh(20px) auto
      width: 75%

      .mobile &
        margin: vw(20px) auto

    .image
      background-position: center center
      background-repeat: no-repeat
      background-size: contain
      height: 100%
      width: 100%

    .price-container
      align-items: flex-end
      border-bottom: 1px solid #000
      display: flex
      justify-content: space-between
      margin-bottom: vh(30)

      .mobile &
        margin-bottom: 0
        padding-bottom: vw(10px)

    .weight
      m-font-size(16, 20)

    .price
      align-self: flex-end
      font-weight: $fw-bold
      m-font-size(40, 48)

      .mobile &
        m-font-size(32, 36)

    .actions
      display: flex

      .button
        margin: 0 auto
        width: vw(230px)

  .right
    $margin = vw(20px)
    flex: 0 0 "calc(50% - %s)" % $margin
    margin-left: $margin
    padding-left: vw(40px)
    padding-right: vw(40px)

    .quad-pack
      margin-bottom: vh(20px)
      margin-top: vh(20px)

    .quad-title,
    .ings-title
      m-font-size(16, 20)
      m-letter-spacing(20)
      font-weight: $fw-bold
      margin-bottom: vh(20px)

      .mobile &
        margin-bottom: vw(15px)

    .ingredients
      display: flex
      flex-wrap: wrap
      margin-bottom: vh(40px)

      .ingredient
        m-font-size(12, 14)
        m-letter-spacing(20)
        margin-bottom: vh(10px)
        margin-right: vw(20px)

        &:not(:last-child)
          position: relative

          &:after
            background-color: #000
            content: ""
            height: 100%
            margin-left: vw(10px)
            margin-right: vw(10px)
            position: absolute
            right: vw(-20px)
            top: 0
            width: 1px

    .why-container
      margin-bottom: vh(20px)

      .why-title
        m-font-size(16, 20)
        m-letter-spacing(20)
        margin-bottom: vh(10px)

        .mobile &
          border-bottom: 1px solid #ddd
          border-top: 1px solid #ddd
          margin-bottom: vw(10px)
          padding-bottom: vw(15px)
          padding-top: vw(15px + 0.25em)

      .reco-desc
        color: $tundora
        m-font-size(16, 20)
        m-letter-spacing(20)
        margin-bottom: vh(20px)

        .mobile &
          margin-bottom: vw(20px)

      .ingredient-item
        $gap = 10px
        $width = percentage((1 / 4))
        flex: 0 0 "calc(%s - (%s * 2))" % ($width vw($gap))
        margin-bottom: vw($gap * 2)
        margin-left: vw($gap)
        margin-right: vw($gap)

      .ings-group
        margin-bottom: vh(20px)

        .ings-list
          display: flex
          flex-wrap: wrap
          margin-left: vw(-10px)
          margin-right: vw(-10px)

      .main-ings,
      .other-ings
        .mobile &
          margin-bottom: vw(20px)

    .description
      margin-bottom: vh(30px)

      .mobile &
        margin-bottom: vw(20px)

      .desc-title
        font-weight: $fw-bold
        m-font-size(16, 20)
        m-letter-spacing(20)
        margin-bottom: vh(20px)

        .mobile &
          margin-bottom: vw(15px)

      .desc-content
        color: $tundora

        .mobile &
          m-font-size(16, 20)

    .evaluate-btn
      cursor: pointer
      m-font-size(16, 20)
      margin-bottom: vh(40px)
      text-decoration: underline

      .mobile &
        margin-bottom: 0
        margin-top: vw(20px)
        text-align: center

  .mobile &
    .content
      .wrapper
        display: block
        flex-direction: column
        overflow-y: auto

      .left,
      .right
        margin: 0

      .left
        padding: vw(20px)
        position: sticky
        top: 0

        .image-container
          flex: 0 0 80vw
          position: relative

          >>> .image
            left: 0
            position: absolute
            top: 0

        .actions
          margin-top: vw(20px)

      .right
        overflow-y: visible
        padding: vw(20px)
        padding-bottom: vw(40px)

        .quad-title
          font-weight: $fw-medium
          m-font-size(16, 20)
          margin-bottom: vw(20px)

        .quad-pack
          margin-bottom: vw(30px)
</style>