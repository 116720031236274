<template>
  <DynamicScroller
    :items="items"
    :min-item-size="20"
    key-field="id"
    :class="['filter-brand',isMobile ? 'horizontal' : 'vertical']"
    :direction="isMobile ? 'horizontal' : 'vertical'"
    v-slot="{ item, active }"
    ref="scroll"
  >
    <DynamicScrollerItem
      :item="item"
      :active="active"
      :size-dependencies="[item.name]"
      :data-index="item.id"
    >
      <div class="title" v-if="item.type === 'title'">{{ item.name }}</div>
      <div class="sub-group-title" v-else-if="item.type === 'subtitle'">
        {{ item.name }}
      </div>
      <button
        v-else
        :class="{ selected: selected === item.id }"
        class="brand-btn"
        @click="$emit('change', selected === item.id ? null : item.id)"
      >
        {{ item.name }}
      </button>
    </DynamicScrollerItem>
  </DynamicScroller>
</template>
<script>
import { DynamicScroller, DynamicScrollerItem } from "vue-virtual-scroller";
import { getBrandList } from "../../utils/brandListUtils";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";

export default {
  components: { DynamicScroller,DynamicScrollerItem },
  props: {
    selected: Number,
    brands: Object,
  },
  model: {
    event: "change",
    prop: "selected",
  },
  computed: {
    items() {
      const items = getBrandList(this.brands, this.isMobile);
      return items;
    },
  },
};
</script>
<style lang="stylus" scoped>
.vue-recycle-scroller.ready.direction-horizontal .vue-recycle-scroller__item-view
.filter-brand
  &.horizontal
    >>>.vue-recycle-scroller__item-view
      width auto !important
    
  .mobile &
    overflow-x: auto

  .sub-group-title
    m-font-size(22, 28)
    font-weight: 700
    margin-bottom: vw(10px)

    .mobile &
      display: none

  .brand-list
    mobile-list()

  .brand-btn
    &:hover
      background-color: rgba(#F2F2F2, 0.5)
      border-radius: vw(40px)
      cursor: pointer

  .brand-btn
    m-font-size(15, 18)
    m-ellipsis(100%)
    background-color: transparent
    border: none
    color: #000
    display: block
    padding: vw(12px) vw(20px)

    &.selected
      background-color: #F2F2F2
      border-radius: vw(40px)
      padding-right: vw(40px)
      position: relative

      .mobile &
        padding-right: vw(40px)

      &:after
        m-icon("close", 15)
        content: ""
        display: block
        flex: 0 0 vw(20px)
        margin-top: vw(-1px)
        position: absolute
        right: vw(15px)
        top: 50%
        transform: translateY(-50%)

        .mobile &
          m-icon("close", 12)

    .mobile &
      m-font-size(16, 18)
      font-weight: 300
      padding: vw(10px) vw(15px)
</style>