import { getPerfumePuig, getIngredient, getConcept } from '@/services/api'
import { eventTracker } from '@/services/tracker'
import { getEanData } from '@/utils/perfume'
import { brTracker } from '../../../services/tracker'
const emitEditProfileEvent = async (action, type, id, state) => {
  const perfumes = await Promise.all(
    state.perfumes.map(async p => ({
      id: p,
      ...getEanData(await getPerfumePuig(p))
    }))
  )
  let data = {}
  if (type === 'PERFUME') {
    data.notinoPerfumeId = getEanData(await getPerfumePuig(id)).notinoPerfumeId
  }
  eventTracker.emitEvent(action, {
    id: type !== 'CONCEPT' ? parseInt(id) : id,
    type,
    data,
    resultingProfile: {
      perfumes,
      ingredients: state.ingredients,
      concepts: state.concepts
    }
  })
}

export default {
  state: {
    // perfumes: [3730,3387,3388,15962,140,6405],
    // ingredients: [73,78,89,1,2,3],
    perfumes: [],
    ingredients: [],
    dislikedPerfumes: [],
    concepts: [],
    recosHash: true
  },
  mutations: {
    reset (state) {
      state.perfumes = []
      state.concepts = []
      state.ingredients = []
    },
    addDisliked (state, pid) {
      if (state.dislikedPerfumes.indexOf(pid) < 0)
        state.dislikedPerfumes = [...state.dislikedPerfumes, pid]
    },
    removeDisliked (state, pid) {
      state.dislikedPerfumes = state.dislikedPerfumes.filter(
        ppid => ppid !== pid
      )
    },
    addPerfume (state, { id }) {
      state.perfumes = [...state.perfumes, id]
    },
    removePerfume (state, { id }) {
      state.perfumes = state.perfumes.filter(ppid => ppid !== id)
    },
    addIngredient (state, { id }) {
      state.ingredients = [...state.ingredients, id]
    },
    removeIngredient (state, { id }) {
      state.ingredients = state.ingredients.filter(ppid => ppid !== id)
    },
    addConcept (state, id) {
      state.concepts = [...state.concepts, id]
    },
    removeConcept (state, id) {
      state.concepts = state.concepts.filter(ppid => ppid !== id)
    }
  },
  actions: {
    addDisliked ({ commit, dispatch }, perfume) {
      commit('addDisliked', perfume.id)
      dispatch('setNeedsNewRecos')
    },
    removeDisliked ({ commit, dispatch }, perfume) {
      commit('removeDisliked', perfume.id)
      dispatch('setNeedsNewRecos')
    },
    addPerfume ({ commit, state, dispatch }, perfume) {
      const { id } = perfume
      if (state.perfumes.indexOf(id) < 0) {
        const newState = { ...state, perfumes: [...state.perfumes, id] }
        console.log({ state, newState })
        emitEditProfileEvent('ADD_TO_PROFILE', 'PERFUME', id, newState)
        brTracker.addPerfume(perfume)
        commit('addPerfume', { id })
        dispatch('setNeedsNewRecos')
      }
    },
    removePerfume ({ commit, state, dispatch }, perfume) {
      const { id } = perfume
      if (state.perfumes.find(ppid => ppid === id)) {
        const newState = {
          ...state,
          perfumes: state.perfumes.filter(ppid => ppid !== id)
        }
        emitEditProfileEvent('REMOVE_FROM_PROFILE', 'PERFUME', id, newState)
        brTracker.removePerfume(perfume)
        commit('removePerfume', { id })
        dispatch('setNeedsNewRecos')
      }
    },
    addIngredient ({ commit, state, dispatch }, ingredient) {
      const { id } = ingredient
      if (state.ingredients.indexOf(ingredient.id) < 0) {
        emitEditProfileEvent('ADD_TO_PROFILE', 'INGREDIENT', id, {
          ...state,
          ingredients: [...state.ingredients, id]
        })
        brTracker.addIngredient(ingredient)
        commit('addIngredient', { id })
        dispatch('setNeedsNewRecos')
      }
    },
    removeIngredient ({ commit, state, dispatch }, ingredient) {
      const { id } = ingredient
      if (state.ingredients.find(ppid => ppid === id)) {
        emitEditProfileEvent('REMOVE_FROM_PROFILE', 'INGREDIENT', id, {
          ...state,
          ingredients: state.ingredients.filter(ppid => ppid !== id)
        })
        brTracker.removeIngredient(ingredient)
        commit('removeIngredient', { id })
        dispatch('setNeedsNewRecos')
      }
    },
    addConcept ({ commit, state, dispatch }, concept) {
      const { id } = concept
      if (state.concepts.indexOf(id) < 0) {
        emitEditProfileEvent('ADD_TO_PROFILE', 'CONCEPT', id, {
          ...state,
          concepts: [...state.concepts, id]
        })
        commit('addConcept', id)
        dispatch('setNeedsNewRecos')
      }
    },
    removeConcept ({ commit, state, dispatch }, concept) {
      const { id } = concept
      if (state.concepts.find(ppid => ppid === id)) {
        emitEditProfileEvent('REMOVE_FROM_PROFILE', 'CONCEPT', id, {
          ...state,
          concepts: state.concepts.filter(ppid => ppid !== id)
        })
        commit('removeConcept', id)
        dispatch('setNeedsNewRecos')
      }
    },
    reset ({ commit }) {
      commit('reset')
    }
  },
  getters: {
    perfumes: state => () => {
      return Promise.all(
        state.perfumes.map(id => {
          return getPerfumePuig(id)
        })
      )
    },
    perfumesIds: state => state.perfumes,
    ingredients: state => () => {
      return Promise.all(
        state.ingredients.map(iid => {
          return getIngredient(iid)
        })
      )
    },
    concepts: state => () => {
      return Promise.all(
        state.concepts.map(iid => {
          return getConcept(iid)
        })
      )
      // return state.concepts
    }
  }
}
