var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"slider"},[_c('div',{staticClass:"wrapper-input",on:{"click":function($event){return _vm.focusInput('left')}}},[(!_vm.leftInput)?_c('div',{staticClass:"price"},[_vm._v(_vm._s(_vm.formatPrice(_vm.minValue)))]):_c('input',{ref:"left",staticClass:"input-number",attrs:{"type":"number","step":_vm.step},domProps:{"value":_vm.minValue},on:{"blur":function($event){_vm.leftInput = false},"change":function (ref) {
	var target = ref.target;

	return _vm.updateMin(parseFloat(target.value));
}}})]),_c('div',{staticClass:"custom-slider minmax"},[_c('div',{ref:"slider",staticClass:"range-slider"},[_c('span',{staticClass:"range-selected"})]),_c('div',{staticClass:"range-input"},[_c('input',{attrs:{"type":"range","name":"min","id":"min","min":_vm.min,"max":_vm.max,"step":_vm.step},domProps:{"value":_vm.rangeMinValue},on:{"input":function (ref) {
	var target = ref.target;

	return _vm.setMin(parseFloat(target.value));
}}}),_c('input',{attrs:{"type":"range","name":"max","id":"max","min":_vm.min,"max":_vm.max,"step":_vm.step},domProps:{"value":_vm.rangeMaxValue},on:{"input":function (ref) {
	var target = ref.target;

	return _vm.setMax(parseFloat(target.value));
}}})])]),_c('div',{staticClass:"wrapper-input",on:{"click":function($event){return _vm.focusInput('right')}}},[(!_vm.rightInput)?_c('div',{staticClass:"price"},[_vm._v(_vm._s(_vm.formatPrice(_vm.maxValue)))]):_c('input',{ref:"right",staticClass:"input-number",attrs:{"type":"number","step":_vm.step},domProps:{"value":_vm.maxValue},on:{"blur":function($event){_vm.rightInput = false},"change":function (ref) {
	var target = ref.target;

	return _vm.updateMax(parseFloat(target.value));
}}})])])}
var staticRenderFns = []

export { render, staticRenderFns }