import devTokens from './setting_dev.json'
import prodTokens from './setting_prod.json'
console.debug('[BR] init ', process.env.NODE_ENV, 'token')
const tokens = process.env.NODE_ENV === 'production' ? prodTokens : devTokens

export async function initialize (installation) {
  const token =
    process.env.VUE_APP_CRM_TOKEN ||
    tokens[installation?.countryCode] ||
    tokens.default
  if (!process.env.VUE_APP_CRM_TOKEN)
    console.debug('initialize br for country: ', installation?.countryCode)
  // eslint-disable-next-line
  return window.exponeaInit(token)
}
