import { getPerfume } from '../api'
export { initialize } from './bloomreachInit'
// }
export function track (name, data) {
  //eslint-disable-next-line
  const exponea = window.exponea
  if (exponea) console.debug('[BR TRACKING] track event', name)
  exponea?.track(name, data)
}
export function identify ({ userId }) {
  const exponea = window.exponea
  if (exponea) console.debug('[BR TRACKING] identify')
  exponea?.identify({ google_analytics: userId })
}
export function sessionStart () {
  const exponea = window.exponea
  exponea?.track('FF_session_start')
}
export function getRecommendedByInfo (recoParams) {
  return {
    based_on_perfumes_name_list:
      recoParams?.perfumes?.map(perfume => perfume.name) ?? [],
    based_on_concepts_name_list:
      recoParams?.concepts?.map(concept => concept.name) ?? [],
    based_on_ingredients_name_list:
      recoParams?.ingredients?.map(ingredient => ingredient.name) ?? [],
    based_on_perfumes_id_list:
      recoParams?.perfumes?.map(perfume => perfume.id) ?? [],
    based_on_concepts_id_list:
      recoParams?.concepts?.map(concept => concept.id) ?? [],
    based_on_ingredients_id_list:
      recoParams?.ingredients?.map(ingredient => ingredient.id) ?? [],
    price_range_min: recoParams.min,
    price_range_max: recoParams.max
  }
}
export function getPerfumeInfo (perfume) {
  const productCode = Object.values(perfume.eanData).find(
    data => data.notinoPerfumeId
  )?.notinoPerfumeId

  return {
    puig_id: perfume.id,
    brand: perfume.brand.name,
    perfume_name: perfume.name,
    ingredients: perfume.ingredientProportions.map(ip => ({
      name: ip.ingredient.name,
      id: ip.ingredient.id
    })),
    ingredients_id_list: perfume.ingredientProportions.map(
      ip => ip.ingredient.id
    ),
    ingredients_name_list: perfume.ingredientProportions.map(
      ip => ip.ingredient.name
    ),
    perfume_family: { id: perfume.family.id, name: perfume.family.name },
    perfume_family_id: perfume.family.id,
    perfume_family_name: perfume.family.name,
    perfume_subfamily_id: perfume.secondaryFamily.id,
    perfume_subfamily_name: perfume.secondaryFamily.name,
    perfume_subfamily: {
      id: perfume.secondaryFamily.id,
      name: perfume.secondaryFamily.name
    },
    product_master_id: productCode ?? null
  }
}

export async function addPerfume (perfume) {
  var perfumeBackend
  try {
    perfumeBackend = await getPerfume(perfume.id)
  } catch (err) {
    perfumeBackend = null
  }
  const exponea = window.exponea
  if (exponea) console.debug('[BR TRACKING] add perfume', perfume.id)
  exponea?.track(
    eventNameEnum.ADD_PERFUME,
    getPerfumeInfo(perfumeBackend || perfume)
  )
}
export async function removePerfume (perfume) {
  var perfumeBackend
  try {
    perfumeBackend = await getPerfume(perfume.id)
  } catch (err) {
    perfumeBackend = null
  }
  const exponea = window.exponea
  if (exponea) console.debug('[BR TRACKING] add perfume', perfume.id)
  exponea?.track(
    eventNameEnum.REMOVE_PERFUME,
    getPerfumeInfo(perfumeBackend || perfume)
  )
}

export async function recoPerfumes (
  perfumes,
  isByIngredients,
  recoParams,
  startRank = 1
) {
  if (exponea)
    console.debug(
      '[BR TRACKING] reco perfumes',
      perfumes,
      isByIngredients,
      recoParams,
      startRank
    )
  const exponea = window.exponea

  perfumes.forEach((perfume, index) => {
    exponea?.track(eventNameEnum.PERFUME_RECO, {
      ...getPerfumeInfo(perfume),
      reco_rank: startRank + index,
      reco_by: isByIngredients ? 'ingredients' : 'perfumes',
      ...getRecommendedByInfo(recoParams)
    })
  })
}

export function addIngredient (ingredient) {
  const exponea = window.exponea
  if (exponea) console.debug('[BR TRACKING] add ingredient', ingredient.id)
  exponea?.track(eventNameEnum.ADD_INGREDIENT, {
    id: ingredient.id,
    name: ingredient.name
  })
}
export function removeIngredient (ingredient) {
  const exponea = window.exponea
  if (exponea) console.debug('[BR TRACKING] add ingredient', ingredient.id)
  exponea?.track(eventNameEnum.REMOVE_INGREDIENT, {
    id: ingredient.id,
    name: ingredient.name
  })
}

export function addConcept (concepts) {
  const exponea = window.exponea
  if (exponea) console.debug('[BR TRACKING] add concept', concepts)
  exponea?.track(eventNameEnum.ADD_CONCEPT, {
    concepts_id_list: concepts.map(concept => concept.id),
    concepts_name_list: concepts.map(concept => concept.name)
  })
}
export function removeConcept (concepts) {
  const exponea = window.exponea
  if (exponea) console.debug('[BR TRACKING] remove concept', concepts)
  exponea?.track(eventNameEnum.REMOVE_CONCEPT, {
    concepts_id_list: concepts.map(concept => concept.id),
    concepts_name_list: concepts.map(concept => concept.name)
  })
}

export function setGender (gender) {
  if (gender === 'MU' || gender === 'M') gender = 'man'
  else if (gender === 'FU' || gender === 'F') gender = 'woman'
  // case U?
  track(eventNameEnum.SET_GENDER, { gender })
}
/**
 *
 * @param {TypePageVisitedEvent} type
 */
export function pageVisit (type) {
  track(eventNameEnum.VIEW_PAGE, { type })
}
/**
 * @enum
 */
export const TypePageVisitedEvent = {
  VIEW_LANDING_PAGE: 'FF_view_landing_page',
  VIEW_EXPLORE_BY_INGREDIENTS: 'FF_view_explore_by_ingredients',
  VIEW_EXPLORE_BY_PERFUMES: 'FF_view_explore_by_perfumes',
  VIEW_CONCEPTS: 'FF_view_concepts',
  VIEW_RECOMMENDATIONS: 'FF_view_recommendation',
  VIEW_PROFILE: 'FF_view_profile',
  VIEW_CHOOSE_PATH: 'FF_view_choose_path'
}

/**
 * @enum
 */
export const eventNameEnum = {
  VIEW_PAGE: 'FF_page_visit',
  VIEW_PERFUME: 'FF_view_perfume',
  // SEARCH: 'FF_search_name_or_brand ',
  SET_GENDER: 'FF_gender',
  ADD_PERFUME: 'FF_add_perfume',
  ADD_INGREDIENT: 'FF_add_ingredient',
  ADD_CONCEPT: 'FF_add_concepts',
  REMOVE_PERFUME: 'FF_remove_perfume',
  REMOVE_INGREDIENT: 'FF_remove_ingredient',
  REMOVE_CONCEPT: 'FF_remove_concepts',
  PERFUME_RECO: 'FF_reco_perfume ',
  // CTA_RECO_BTN: 'FF_reco_button',
  // CTA_RECO_SHOW_MORE: 'FF_show_more_button',
  CTA_BUY: 'FF_buy_product'
}
