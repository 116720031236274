export default {
welcome_to_profiler: "<b>Notino Fragrance Finder</b>",
what_are_you_looking_for: "Lassen Sie sich einen neuen Duft empfehlen, der Ihnen oder Ihren Liebsten schmeicheln wird.<br>Für wen suchen Sie den perfekten Duft?",
build_your_profile_and: "Lassen Sie sich einen neuen Duft empfehlen, der Ihnen oder Ihren Liebsten schmeicheln wird.<br>Für wen suchen Sie den perfekten Duft?",
search_by: "Wir empfehlen Ihnen Düfte je nach Ihren Präferenzen",
gender_m: "Für Herren",
gender_f: "Für Damen",
mode_perfumes: "Lieblingsdüfte",
mode_ingredients: "Duftnoten",
back: "Zurück",
novelty: "Neuheit",
go_back_to_finder: "Zurück zum Fragrance Finder",
done: "Fertig",
best_sellers: "Bestseller",
from: "ab",
search_your_perfume: "<b>Wählen Sie<b> max. 5 Düfte, die Sie oder der/die Beschenkte mögen.",
search_your_perfume_flat: "Geben Sie Ihr Lieblingsparfüm oder Ihre Lieblingsmarke ein",
select_your_perfume: "<b>Wählen Sie</b> Ihre Lieblingsdüfte.",
no_perfumes_found: "Keine Düfte gefunden :(",
no_ingredients_found: "Keine Duftnoten gefunden :(",
something_went_wrong: "Leider ergab Ihre Suche keine Ergebnisse. Versuchen Sie, Ihre Suche zu ändern",
select_fav_ings: "<b>Wählen Sie</b> Ihre bevorzugten Duftstoffe und wir helfen Ihnen, den passenden Duft zu finden.",
search_fav_ings: "Geben Sie hier Ihre bevorzugten Duftnoten ein.",
building_your_profile: "<b>Wir erstellen</b>Ihr<br>Duftprofil",
edit_your_profile: "Profil bearbeiten",
fragrances: "Duft",
ingredients: "Duftstoffe",
concepts: "Konzepte",
next_step: "Fertig",
what_gender: "Für wen ist dieses Profil gedacht?",
selected_ingredients: "Ihre Lieblingsduftstoffe",
selected_perfumes: "Ihre Lieblingsdüfte",
no_perfumes_yet: "In Ihrem Profil sind keine Düfte vorhanden",
no_ingredients_yet: "In Ihrem Profil sind keine Duftstoffe vorhanden",
no_conceptss_yet: "In Ihrem Profil sind keine Konzepte vorhanden",
get_recomendations: "Empfehlungen erhalten",
this_are_your_ings: "Das sind Ihre Lieblingsnoten",
your_profile_to_edit: "Ihr Profil",
add_more_fragrances: "Weitere Düfte hinzufügen",
add_more_ingredients: "Weitere Duftnoten hinzufügen",
add_more_concepts: "Weitere Konzepte hinzufügen",
top_6_recos: "AUF SIE ZUGESCHNITTENE DÜFTE",
recos_based_on: "Diese Empfehlungen beruhen auf Ihrem Duftprofil.",
rest_of_recos: "Weitere Empfehlungen",
view_details: "Details anzeigen",
your_profile_is_empty: "Ihr Profil ist leer!",
add_to_profile_to_get_recos: "Fügen Sie Ihrem Profil Düfte oder Duftstoffe hinzu und erhalten Sie Empfehlungen",
price: "Preis",
buy_it_now: "Mehr erfahren",
prominent_ings: "Die ausgeprägtesten Duftstoffe",
all_ings: "Vollständige Liste der Duftnoten",
why_do_we_recommend: "Warum empfehlen wir Ihnen <b>diesen Duft</b>?",
other_ings_present: "Sowie andere, weniger ausgeprägte",
description: "Beschreibung",
evaluate_this_reco: "Bewerten Sie diese Empfehlung",
how_relevant_do_you: "Wie relevant ist Ihrer Meinung nach <b>diese Empfehlung</b>?",
submit: "Senden",
check_your_new_recommendations_1: "<b>Profil aktualisiert.</b> Danke!",
check_your_new_recommendations_2: "Sie haben neue Empfehlungen.",
view_new_recommendations: "Neue Empfehlungen anzeigen",
close: "Schließen",
loading: "Laden",

//Profile_desc,
you_love_perfumes_of_the: "<b>Sie lieben Düfte</b><br>",
you_love_perfumes_of_the_plural: "<b>Sie lieben Düfte</b><br>",
and: "und",
desc_family: "Duftfamilie",
desc_families: "Duftfamilien",
you_also_love_plural: "Sie mögen auch Untergruppen",
you_also_love: "Sie mögen auch die Untergruppe",
desc_sub_family: "Untergruppe",
desc_sub_families: "Untergruppen",

//RECOS:,
it: "das",
also: "auch",
$pronoun_self: "Sie",
$possessive_singular_self: "Ihr",
$possessive_refl_singular_self: "meine",
$possessive_plural_self: "Ihr",
$possessive_refl_plural_self: "meine",
$possessive_singular_prep_self: "Ihr",
$possessive_singular_liste_self: "Ihr",
$you_self: "Sie",
$you_prep_self: "Sie",
$do_you_have_self: "Sie haben",
$that_you_love_self: "die Sie mögen",
$you_love_self: "Sie mögen",
$you_adore_self: "Sie mögen",
$apreciate_self: "Sie mögen",
$you_have_self: "Sie haben",
reco_desc_title: "Wir empfehlen diesen Duft, weil",
possible_reco_title: "Vielleicht wird Ihnen dieser Duft gefallen, weil",
recommended_by_hero: "Sie seine Hauptduftnote mögen: <b>%HERO%</b>",
recommended_by_fam: "er zu einer Ihrer bevorzugten Duftfamilien gehört, <b>%FAMILY%</b>",
not_recommended_by_fam: "Obwohl er ein anderes Profil als $possessive_singular_prep hat, sollten Sie ihn $you_prep probieren",
recommended_by_ings: "er enthält einige Ihrer bevorzugten Duftnoten: ",
furthermore: "Weiter",
home: "Neue Empfehlungen",
my_profile: "Mein Profil",
no_concepts_yet: "In Ihrem Profil sind keine Konzepte vorhanden",
view_more: "Mehr anzeigen",
select_fav_ingredients_to_find_perfumes: "Wählen Sie Ihre bevorzugten <b>Duftnoten</b><br> und finden Sie Ihre <br>Lieblingsdüfte<b></b>",
your_prefered_concepts: "Ihre bevorzugten Eigenschaften",
concept_fresh: "Frisch (z.B. Zitrusfrüchte)",
concept_intense: "Intensiv (z. B. holzig)",
concept_casual: "Für den Alltag (z. B. zur Arbeit)",
concept_special_occasion: "Für einen besonderen Anlass (z. B. ein Date)",
concept_sure_bet: "Hiermit sind Sie auf der sicheren Seite",
concept_novelty: "Modern (z. B. Neuheiten)",
change_concepts: "Ausgewählte Eigenschaften ändern",
select_concepts: "Wir haben´s fast geschafft",
add_concepts: "Weitere bevorzugten Eigenschaften hinzufügen",
or: "oder",
explore_by: "basierend auf folgenden Kriterien durchstöbern",
brands: "Marken",
your_selection: "Ihre Auswahl",
list_view: "Liste der Lieblingsdüfte",
graphic_view: "Ihr Duftprofil",
recos_based_on_selection: "Empfehlungen basierend auf Ihrer Auswahl",
add_to_profile: "Zu meinem Profil hinzufügen",
do_you_want_to_reset: "Möchten Sie Ihr Profil neu einrichten?",
start_new: "Wieder von vorne anfangen",
keep_perfumes: "Düfte speichern",
click_to_view: "Zur Ansicht klicken",
already_in_profile: "Bereits hinzugefügt",
only_showing_that_combine_with: "Es werden nur Duftnoten mit XXX angezeigt",
see_all_ings_in_this_fam: "Alle Duftnoten dieser Duftfamilie anzeigen",
select_concepts_subtitle: "Um Empfehlungen zu erhalten, müssen Sie mindestens 2 Düfte auswählen (gehen Sie einen Schritt zurück) oder wählen Sie mindestens eines von Merkmalen.",
action: "Aktionen",
free_del: "kostenloser Versand",
new_in: "neu",
cancel: "abbrechen",
see_more: "Mehr anzeigen",
see_less: "Weniger anzeigen",
recommendation_by: "Empfehlungen mit diesen Inhaltsstoffen",
apply: "Anwenden",
modal_limit_ingredients_title: "Maximale Anzahl an Inhaltsstoffen",
modal_limit_ingredients_text: "Sie haben die Höchstgrenze für die Auswahl von Inhaltsstoffen erreicht (4). Bitte entfernen Sie einen, bevor Sie einen weiteren hinzufügen.",
empty_selection: "Sie haben keine Auswahl getroffen",
empty_selection_text: "Wählen Sie für Empfehlungen einen oder mehrere Düfte aus",
search_perfume_subtitle: "Toll! Sie haben nun Zugang zu Ihren personalisierten Empfehlungen.",
perfume_search_subtitle: "Toll! Sie haben nun Zugang zu Ihren personalisierten Empfehlungen.",
filter_by_brand: "Nach Marke filtern",
perfume_list: "Duft auswählen",
others: "",
some_error: "Ups! Es sieht so aus, als ob die Website einen Fehler hat. Wir arbeiten daran, ihn so schnell wie möglich zu beheben. Vielen Dank für Ihre Geduld!",
no_brands_found: "Keine Marken gefunden :("
}