import en_UK from './lang/en-UK.js'
import bg_BG from './lang/bg-BG.js'
import cs_CZ from './lang/cs-CZ.js'
import de_AT from './lang/de-AT.js'
import de_DE from './lang/de-DE.js'
import fr_FR from './lang/fr-FR.js'
import hu_HU from './lang/hu-HU.js'
import it_IT from './lang/it-IT.js'
import pl_PL from './lang/pl-PL.js'
import ro_RO from './lang/ro-RO.js'
import sk_SK from './lang/sk-SK.js'
import store from '@/store'

let storeInstance = store
let allStrings = {
  'en-UK': en_UK,
  'bg-BG': bg_BG,
  'cs-CZ': cs_CZ,
  'de-AT': de_AT,
  'de-DE': de_DE,
  'fr-FR': fr_FR,
  'hu-HU': hu_HU,
  'it-IT': it_IT,
  'pl-PL': pl_PL,
  'ro-RO': ro_RO,
  'sk-SK': sk_SK
}
export { allStrings }
function flatT (key) {
  return allStrings[storeInstance.state.i18n.lang][key]
}
function replaceArticles (str) {
  let keys, propperKey, propperKeyNoG, g, w
  if (!str || str.indexOf('$') < 0 || str.indexOf('[') === 0) return str
  else keys = str.match(/\$[a-zA-Z_]*/g)
  // g = store.getState().session.gender
  // if(g==='U') g = 'M'
  g = 'M' //Remember this is diferents that GENDER_ENUM.MALE. This is in order to find the key in lang
  // w = store.getState().session['for-who']
  w = 'self'
  return keys.reduce((result, key) => {
    propperKey = w === 'self' ? key + '_self' : key + '_other_' + g
    propperKeyNoG = w === 'self' ? key + '_self' : key + '_other'
    return result.replace(
      key,
      flatT(propperKey) || flatT(propperKeyNoG) || '[' + propperKey + ']'
    )
  }, str)
}
export function t (key) {
  // return allStrings[storeInstance.state.i18n.lang][key]|| '['+key+']'
  // lo anterior no funciona pq el string buit es un falsy
  let aux = replaceArticles(allStrings[storeInstance.state.i18n.lang][key])
  // Before = aux!=undefined. Removed for not show empty string
  return aux ? aux : '[' + key + ']'
}
export default {
  methods: {
    $t: t
  }
}
