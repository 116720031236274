var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view concepts-select"},[_c('div',{staticClass:"back-button",on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('div',{staticClass:"back-icon"}),_c('div',{staticClass:"back-text"},[_vm._v(_vm._s(_vm.$t("back")))])]),_c('div',{staticClass:"bottom concepts-grid"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("select_concepts")))]),_c('div',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.$t("select_concepts_subtitle")))]),_c('div',{staticClass:"concepts-container"},[(_vm.currentConcepts)?_c('div',{staticClass:"concept-pairs"},_vm._l((_vm.conceptPairs),function(pair){return _c('div',{key:pair[0].id,staticClass:"pair"},[_c('div',{staticClass:"concept",class:{
              active: _vm.currentConcepts.find(function (d) { return d.id === pair[0].id; }),
            },on:{"click":function($event){return _vm.toggleConcept(pair[0])}}},[_vm._v(" "+_vm._s(_vm.$t(pair[0].name))+" ")]),_c('concept-toggle',{attrs:{"value":_vm.currentConcepts.find(function (d) { return d.id === pair[1].id; })
                ? -1
                : _vm.currentConcepts.find(function (d) { return d.id === pair[0].id; })
                ? 1
                : 0},on:{"click":function (dir) { return _vm.togglePair(pair, dir); }}}),_c('div',{staticClass:"concept",class:{
              active: _vm.currentConcepts.find(function (d) { return d.id === pair[1].id; }),
            },on:{"click":function($event){return _vm.toggleConcept(pair[1])}}},[_vm._v(" "+_vm._s(_vm.$t(pair[1].name))+" ")])],1)}),0):_vm._e(),_c('span',{on:{"click":function () { return _vm.handleClickNext(); }}},[_c('router-link',{staticClass:"button",class:{
            disabled: !_vm.currentConcepts || _vm.currentConcepts.length < 1,
          },attrs:{"to":{
            name: 'RecommendationsBySearch',
            query: this.$route.query.restricted
              ? { restricted: this.$route.query.restricted }
              : {},
          },"replace":""}},[_vm._v(_vm._s(_vm.$t("get_recomendations")))])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }