<template>
  <div class="profile-desc">
    <div class="main" v-if="families && families.length > 0">
      <span
        class="you-love-text"
        v-if="families.length > 1"
        v-html="$t('you_love_perfumes_of_the_plural')"
      ></span>
      <span
        class="you-love-text has-colon"
        v-else
        v-html="$t('you_love_perfumes_of_the')"
      ></span>
      <span class="fam-group" v-for="(fam, i) in families" :key="fam.id">
        <template v-if="families.length > 1 && i === families.length - 1"
          >&nbsp;{{ $t("and") }}&nbsp;</template
        >
        <span
          :class="['fam-word', { selected: selection === fam }]"
          @click="onFamClick(fam)"
        >
          {{ fam.name }}</span
        >
        <span v-if="i < families.length - 2">,&nbsp;</span>
      </span>
      <!-- <span v-if="families.length>1">&nbsp;{{$t('desc_families')}}</span>
      <span v-else>&nbsp;{{$t('desc_family')}}</span> -->
    </div>
    <div class="sub" v-if="subFamilies && subFamilies.length > 0">
      <span class="you-also-love-text" v-if="subFamilies.length > 1"
        >{{ $t("you_also_love_plural") }}:</span
      >
      <span class="you-also-love-text" v-else>{{ $t("you_also_love") }}:</span
      ><br />
      <span
        class="sub-fam-group"
        v-for="(subfam, i) in subFamilies"
        :key="subfam.id"
      >
        <template v-if="subFamilies.length > 1 && i === subFamilies.length - 1"
          >&nbsp;{{ $t("and") }}&nbsp;</template
        >
        <span :class="['sub-fam-word']">{{ subfam.name }}</span>
        <span v-if="i < subFamilies.length - 2">,&nbsp;</span>
      </span>
      <!-- <span v-if="subFamilies.length>1">&nbsp;{{$t('desc_sub_families')}}</span>
      <span v-else>&nbsp;{{$t('desc_sub_family')}}</span> -->
    </div>
  </div>
</template>

<script>
// import {joinWithAnd} from '@/utils'

export default {
  name: "ProfileDesc",
  components: {},
  model: {
    prop: "selection",
    event: "change",
  },
  props: ["selection"],
  asyncComputed: {
    families() {
      return this.$store.getters.familiesWithSubsByPerfumes().then((fams) => {
        return fams.map((f) => {
          return f.family;
        });
      });
    },
    subFamilies() {
      return this.$store.getters.familiesWithSubsByPerfumes().then((fams) => {
        let subs = fams
          .map((f) => f.subFamilies.map((s) => s.subFamily))
          .flat(); // aqui llistes totes les subfamilies de qualsevol familia en la mateixa array
        subs = subs.filter(
          (s, i) => subs.findIndex((s) => s.id === subs[i].id) === i
        ); // elimines subfam repes
        subs = subs.filter(
          (s) => fams.findIndex((f) => f.family.id === s.id) < 0
        ); // elimines les subfams q es diuen igual q una fam?
        subs = subs.slice(0, 3); // nomes pilles 3 subfam
        return subs;
      });
    },
  },
  methods: {
    capitalize(word) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    },
    onFamClick(fam) {
      if (fam !== this.selection) this.$emit("change", fam);
      else this.$emit("change", null);
    },
  },
};
</script>

<style scoped lang="stylus">
.profile-desc
  display: flex
  flex-direction: column

  .main,
  .sub
    display: inline
    margin-bottom: vh(20px)
    text-align: left

    .fam-group,
    .sub-fam-group
      display: inline-block
      white-space: pre

      .fam-word,
      .sub-fam-word
        display: inline-block
        white-space: pre

        &.selected
          font-weight: bold
          text-decoration: underline

  .main
    m-font-size(24, 32)

    .mobile &
      m-font-size(22, 28)
      margin-bottom: vw(20px)

  .you-love-text,
  .you-also-love-text
    m-first-letter-case()
    display: inline-block

    .mobile &
      display: block

      >>> > br
        display: none

  .sub
    // m-first-letter-case()
    m-font-size(16, 20)

    .mobile &
      m-font-size(18, 22)
</style>