<template>
  <div class="debounced-input">
    <input
      v-model="internalValue"
      ref="input"
      :placeholder="placeholder"
      @keyup="onKeyUp"
    />
    <div class="icon" :class="{ cross: internalValue }" @click="onClick"></div>
  </div>
</template>

<script>
import debounce from "debounce";
export default {
  props: ["value", "placeholder"],
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      internalValue: this.value,
    };
  },
  methods: {
    emitChange() {
      this.$emit("change", this.internalValue);
    },
    debounceInput: debounce(function () {
      this.emitChange();
    }, 500),
    onKeyUp(e) {
      if (e.keyCode === 13) this.$refs.input.blur();
    },
    onClick() {
      this.internalValue = "";
    },
  },
  watch: {
    internalValue() {
      this.debounceInput();
    },
    value() {
      if (this.value !== this.internalValue) this.internalValue = this.value;
    },
  },
};
</script>

<style lang="stylus" scoped>
.debounced-input
  position: relative

  input
    appearance: none
    border: 1px solid #A7A5A5
    border-radius: 0
    border-radius: vw(4px)
    height: vh(45px)
    outline: none
    padding: vh(5px) vw(40px) vh(5px) vw(15px)
    width: 100%

    .mobile &
      height: vw(50px)
      padding: vw(5px) vw(40px) vw(5px) vw(10px)

    &::placeholder
      m-font-size(14, 20)

  .icon
    m-icon("search", 15)
    position: absolute
    right: vw(15px)
    top: 50%
    transform: translateY(-50%)

    &.cross
      cursor: pointer
      m-icon("close", 18)
</style>