<template>
  <div class="recommendation-by-ingredients">
    <loading
      v-if="
        $asyncComputed.ingredientCombinations.updating ||
        !ingredientCombinationsFiltered ||
        loading
      "
    ></loading>
    <div class="container" v-if="ingredientCombinationsFiltered">
      <back v-if="isMobile" />
      <div class="slider-container">
        <div class="text">{{ $t("price") }}</div>
        <slider-range
          :min="minPrice"
          :max="maxPrice"
          :minValue.sync="minValue"
          :maxValue.sync="maxValue"
          :step="1"
        />
        <div
          class="button slider-btn"
          :class="{
            disabled:
              minValue === filterMinPrice && maxValue === filterMaxPrice,
          }"
          @click="applyFilter"
        >
          {{ $t("apply") }}
        </div>
      </div>
      <div
        v-for="(group, index) in ingredientCombinationsFiltered"
        :key="index"
        class="section"
      >
        <div class="header-reco">
          <div class="header-title">{{ $t("recommendation_by") }}:</div>
          <div class="header-ingredients">
            <ingredient-item
              v-for="ing in group.ingredients"
              :data="ing"
              :key="ing.id"
            ></ingredient-item>
          </div>
        </div>
        <div class="perfumes">
          <perfume-card
            v-for="perfume in group.perfumes"
            :data="perfume"
            :key="perfume.id"
            @click="onPerfumeClick(perfume)"
          ></perfume-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getCombinationsIngredients } from "../services/api";
import IngredientItem from "./Legacy/IngredientItem.vue";
import PerfumeCard from "./PerfumeCard.vue";
import Loading from "./Loading.vue";
import { brTracker } from "../services/tracker";
import SliderRange from "../components/SliderRange.vue";
import { lowerPrice } from "../utils/perfume";
import routeParams from "../mixins/RouteParams";
import Back from "./Back.vue";
export default {
  components: {
    IngredientItem,
    PerfumeCard,
    Loading,
    SliderRange,
    Back,
  },
  data() {
    return {
      loading: false,
      minValue: null,
      maxValue: null,
      // filterMinPrice: 0,
      // filterMaxPrice: Infinity,
    };
  },
  asyncComputed: {
    ingredients: {
      get() {
        return this.$store.getters.ingredients();
      },
      default: [],
    },
    profileIngredients() {
      return this.$store.getters.ingredients();
    },
    profilePerfumes() {
      return this.$store.getters.perfumes();
    },
    profileConcepts() {
      return this.$store.getters.concepts();
    },
    ingredientCombinations: {
      get() {
        if (!this.ingredients) return null;
        return getCombinationsIngredients(this.ingredients).then((data) => {
          data.map(({ perfumes, ingredients }) => {
            this.eventTracker.emitEvent("SEARCH_PERFUMES", {
              type: "INGREDIENT",
              data: {
                ingredients: ingredients.map((i) => i.id),
              },
              topResults: perfumes.map((p) => p.id),
            });
          });
          return data;
        });
      },
      default: [],
    },
  },
  computed: {
    ingredientCombinationsFiltered() {
      var result = [];
      if (this.filterMinPrice === null || this.filterMaxPrice === null)
        result = this.ingredientCombinations;
      else
        result = this.ingredientCombinations
          .map((data) => {
            const perfumes = data.perfumes.filter((perfume) => {
              let price = lowerPrice(perfume);
              return (
                this.filterMinPrice <= price && this.filterMaxPrice >= price
              );
            });
            return { ...data, perfumes };
          })
          .filter((data) => data.perfumes.length > 0);

      return result.map((data) => ({
        ...data,
        perfumes: data.perfumes.slice(0, 6),
      }));
    },
    allPerfumes() {
      return this.ingredientCombinations.reduce(
        (acumulator, ingCombo) => acumulator.concat(ingCombo.perfumes),
        []
      );
    },
    completeProfileStore() {
      return {
        perfumes: this.profilePerfumes,
        concepts: this.profileConcepts,
        ingredients: this.profileIngredients,
      };
    },
    minPrice() {
      const min = this.allPerfumes?.reduce(
        (price, perfume) => Math.min(price, lowerPrice(perfume)),
        Infinity
      );
      return min === Infinity ? 0 : min;
    },
    maxPrice() {
      return this.allPerfumes?.reduce(
        (price, perfume) => Math.max(price, lowerPrice(perfume)),
        0
      );
    },
  },
  methods: {
    applyFilter() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.filterMinPrice = this.minValue;
        this.filterMaxPrice = this.maxValue;
      }, 150);
    },
    updateRangeData() {
      this.minValue = this.filterMinPrice
        ? Math.max(this.filterMinPrice, this.minPrice)
        : this.minPrice;
      this.maxValue = this.filterMaxPrice
        ? Math.min(this.filterMaxPrice, this.maxPrice)
        : this.maxPrice;
    },
    onPerfumeClick(perfume) {
      this.$router.push({
        name: `Perfume`,
        params: { id: perfume.id },
        query: { fromReco: true },
      });
    },
  },
  mixins: [routeParams(["filterMinPrice", "filterMaxPrice"], "number")],
  mounted() {
    brTracker.pageVisit(brTracker.TypePageVisitedEvent.VIEW_RECOMMENDATIONS);
    this.eventTracker.emitEvent("VIEW_OPENED", {
      type: "RECOMMENDATIONS_VIEW",
    });
  },
  watch: {
    ingredientCombinations() {
      this.updateRangeData();
    },
    ingredientCombinationsFiltered() {
      this.ingredientCombinationsFiltered.map(({ perfumes, ingredients }) => {
        brTracker.recoPerfumes(perfumes, true, {
          ingredients,
          min: this.filterMinPrice,
          max: this.filterMaxPrice,
        });
      });
    },
    minPrice() {
      this.updateRangeData();
    },
    maxPrice() {
      this.updateRangeData();
    },
  },
};
</script>
<style lang="stylus" scoped>
.recommendation-by-ingredients
  height: 100%
  overflow-y: auto
  padding: vw(30px) vw(50px)
  position: relative

  .mobile &
    padding: vw(10px)

  .slider-container
    align-items: center
    display: flex
    gap: vw(15px)
    margin-bottom: vw(40px)
    margin-left: auto
    margin-right: auto
    width: 100%

    .mobile &
      flex-direction: column

    .text
      m-font-size(16, 20)
      text-transform: uppercase

    .slider-btn
      m-font-size(14, 18)
      padding: vw(6px) vw(20px)
      text-transform: uppercase

  .loading-overlay
    top: 0

  .section
    margin-bottom: vh(60px)

  .header-reco
    align-items: center
    border-bottom: 1px solid black
    display: flex
    gap: vw(10px)
    margin-bottom: vh(30px)
    padding-bottom: vh(20px)

    .mobile &
      display: block
      margin-bottom: vw(20px)
      padding-bottom: vw(10px)

  .header-title
    m-font-size(18, 24)
    font-weight: $fw-bold

    .mobile &
      m-font-size(16, 20)
      margin-bottom: vw(10px)

  .header-ingredients
    display: flex
    gap: vw(10px)

    >>> .ingredient-item
      height: vw(40px)
      width: vw(40px)

      .image
        margin: 0
        width: 100%

      .label
        display: none

  .perfumes
    display: grid
    gap: vw(15px)
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr

    @media (max-width: 1919px)
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr

    @media (max-width: 1439px)
      grid-template-columns: 1fr 1fr 1fr 1fr

    @media (max-width: 1279px)
      grid-template-columns: 1fr 1fr 1fr

    @media (max-width: 1023px)
      grid-template-columns: 1fr 1fr

    >>> .perfume-card
      .image
        height: vw(90px)
        margin: vw(10px)
        margin-top: 0
        width: vw(90px)

      .family-and-icon
        margin-bottom: vw(2px)
        margin-top: 0

        .label
          m-font-size(10, 12)

      .info
        m-font-size(12, 14)

        .name
          m-font-size(12, 14)

      .tags
        .tag
          m-font-size(10, 12)

      .price
        m-font-size(14, 16)
</style>