import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import AsyncComputed from 'vue-async-computed'
import { EventTrackerPlugin } from './services/tracker'

import DeviceDetect from './mixins/DeviceDetect'
// import ResponsiveCss from './mixins/ResponsiveCss'
import i18n from './i18n'
import VueMq from 'vue-mq'

Vue.use(VueMq, {
  breakpoints: {
    // default breakpoints - customize this
    mobile: 450,
    tablet: 1024,
    desktop: Infinity
  },
  defaultBreakpoint: 'desktop' // customize this for SSR
})

Vue.mixin(DeviceDetect)
// Vue.mixin(ResponsiveCss)
Vue.mixin(i18n)
Vue.config.productionTip = false
Vue.use(AsyncComputed)
Vue.use(EventTrackerPlugin)

Vue.directive('scroll', {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        el.removeEventListener('scroll', f)
      }
    }
    el.addEventListener('scroll', f)
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
