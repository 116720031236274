<template>
  <div class="view ingredient-search">
    <transition name="slice-content">
      <div v-if="isRecommendation" class="content right" key="right">
        <recommendation-by-ingredients></recommendation-by-ingredients>
      </div>
      <div
        v-else
        class="content"
        :class="{ 'state-perfumes': state === 'perfumes' }"
        key="left"
      >
        <div class="ingredients">
          <div class="top">
            <div class="title" v-html="$t('select_fav_ings')"></div>
            <debounced-input
              v-model="str"
              :placeholder="$t('search_fav_ings')"
            />
          </div>
          <loading v-if="$asyncComputed.allIngredients.updating"></loading>
          <div
            class="no-results"
            v-else-if="groupedIngredients && groupedIngredients.length === 0"
          >
            {{ $t("no_ingredients_found") }}
          </div>
          <div v-else class="ingredients-list">
            <div
              class="group"
              v-for="group in groupedIngredients"
              :key="group.id"
              :id="`group-${group.id}`"
            >
              <intersect @enter="() => onGroupEnter(group)">
                <div class="group-wrapper">
                  <div class="group-label">
                    {{ group.name }} ({{ group.ingredients.length }})
                  </div>
                  <!-- {{group.ingredients}} -->
                  <div
                    class="group-items main"
                    v-if="visibleGroups.indexOf(group.id) >= 0"
                  >
                    <ingredient-item
                      v-for="ing in group.ingredients.slice(
                        0,
                        moreOpen.indexOf(group.id) >= 0 ? 6 : 5
                      )"
                      :class="{ disabled }"
                      :key="ing.id"
                      :data="ing"
                      :selectable="true"
                      @select="toggleIngredient(ing)"
                      :value="isSelected(ing)"
                    ></ingredient-item>
                    <div
                      class="ingredient-item more-less-button-container"
                      v-if="!moreOpen.includes(group.id)"
                      @click="moreOpen.push(group.id)"
                    >
                      <div class="more-less-button-wrapper">
                        <div class="more-less-button">
                          <div class="more-button-icon"></div>
                        </div>
                        <div class="label">{{ $t("see_more") }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="more" v-if="visibleGroups.indexOf(group.id) >= 0">
                    <collapse-transition
                      v-show="moreOpen.indexOf(group.id) >= 0"
                    >
                      <div class="group-items">
                        <ingredient-item
                          v-for="ing in group.ingredients.slice(6)"
                          :key="ing.id"
                          :data="ing"
                          :class="{ disabled }"
                          :selectable="true"
                          @select="toggleIngredient(ing)"
                          :value="isSelected(ing)"
                        ></ingredient-item>
                        <div
                          class="ingredient-item more-less-button-container"
                          @click="onClickSeeLess(group)"
                        >
                          <div class="more-less-button-wrapper">
                            <div class="more-less-button">
                              <div class="less-button-icon"></div>
                            </div>
                            <div class="label">{{ $t("see_less") }}</div>
                          </div>
                        </div>
                      </div>
                    </collapse-transition>
                  </div>
                </div>
              </intersect>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div class="right-panel" :class="{ left: isRecommendation }">
      <profile-summary
        :mode="state"
        :tempIngredients="selection"
        @nextStep="state = 'perfumes'"
      ></profile-summary>
    </div>
    <transition name="fade">
      <div v-if="openModal" class="modal">
        <div class="modal-container">
          <div class="modal-title">
            {{ $t("modal_limit_ingredients_title") }}
          </div>
          <div class="modal-text">{{ $t("modal_limit_ingredients_text") }}</div>
          <div class="actions">
            <button class="btn btn--primary" @click="openModal = false">
              {{ $t("close") }}
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { getAllIngredients, searchByIngredients } from "@/services/api";
import { matchWords } from "@/utils/textUtils";
import ProfileSummary from "@/components/ProfileSummary";
import IngredientItem from "../components/Legacy/IngredientItem.vue";
import DebouncedInput from "../components/DebouncedInput.vue";
import Loading from "../components/Loading.vue";
import Intersect from "vue-intersect";
import { CollapseTransition } from "@ivanv/vue-collapse-transition";
import { brTracker } from "../services/tracker";
import RecommendationByIngredients from "../components/RecommendationByIngredients.vue";
// import PerfumeItem from '../components/PerfumeItem.vue'
// import ProfileEditItem from '../components/ProfileEditItem.vue'

export default {
  components: {
    ProfileSummary,
    IngredientItem,
    DebouncedInput,
    Loading,
    Intersect,
    CollapseTransition,
    RecommendationByIngredients,
    // PerfumeItem,
    // ProfileEditItem
  },
  props: ["isRecommendation"],
  data() {
    return {
      openModal: false,
      str: "",
      visibleGroups: [1, 2, 3],
      // selection: [],
      state: "ingredients",
      moreOpen: [],
    };
  },
  mounted() {
    this.eventTracker.emitEvent("VIEW_OPENED", {
      type: "EXPLORE_BY_INGREDIENTS",
    });
    brTracker.pageVisit(
      brTracker.TypePageVisitedEvent.VIEW_EXPLORE_BY_INGREDIENTS
    );
  },
  methods: {
    onClickSeeLess(group) {
      this.moreOpen.splice(this.moreOpen.indexOf(group.id), 1);
      document
        .getElementById("group-" + group.id)
        ?.scrollIntoView({ behavior: "smooth" });
    },
    onGroupEnter(group) {
      if (this.visibleGroups.indexOf(group.id) < 0)
        this.visibleGroups.push(group.id);
    },
    toggleIngredient(ingredient) {
      if (this.disabled) this.openModal = true;
      else if (
        this.selection.findIndex((ing) => ing.id === ingredient.id) >= 0
      ) {
        // this.selection = this.selection.filter((d)=>d!==ingredient)
        this.$store.dispatch("removeIngredient", ingredient);
        brTracker.addIngredient(ingredient);
        // this.moreOpen = []
      } else {
        if (this.selection.length === 4) return;
        // this.selection.push(ingredient)
        this.$store.dispatch("addIngredient", ingredient);
        // this.moreOpen = []
        if (this.selection.length == 3)
          this.$router.push({ name: "RecommendationByIngredients" });
      }
    },
    isSelected(ingredient) {
      return this.selection.some((ing) => ing.id === ingredient.id);
    },
  },
  computed: {
    selection() {
      return this.profileIngredients || [];
    },
    disabled() {
      return this.selection.length >= 4;
    },
  },
  asyncComputed: {
    profileIngredients() {
      return this.$store.getters.ingredients();
    },
    allIngredients() {
      return getAllIngredients().then((res) => {
        return res.filter((ing) => {
          return (
            (!ing.useGenericImageUrl && ing.imageUrl) ||
            (!ing.useGenericLightImageUrl && ing.lightImageUrl)
          );
        });
      });
    },
    filteredIngredients() {
      // sorted in api.js
      return (this.allIngredients || []).filter((ing) => {
        return (
          matchWords(ing.name, this.str) ||
          (ing.family && matchWords(ing.family.name, this.str))
        );
      });
    },
    groupedIngredients() {
      if (!this.filteredIngredients) return null;
      return Object.values(
        this.filteredIngredients.reduce((res, ing) => {
          if (!res[ing.family.id])
            res[ing.family.id] = {
              id: ing.family.id,
              name: ing.family.name,
              ingredients: [],
            };
          res[ing.family.id].ingredients.push(ing);
          return res;
        }, {})
      );
    },
    results() {
      return this.state === "perfumes"
        ? searchByIngredients(this.selection)
        : null;
    },
  },
};
</script>

<style lang="stylus" scoped>
$profile-width = vw(400px)

.view
  flex: 1 1 0%
  overflow: hidden
  position: relative

  .top
    padding: vh(30px) vw(30px) vh(20px)

    .mobile &
      padding: vw(30px) vw(20px) vw(20px)

    .title
      m-font-size(16, 20)
      margin-bottom: vh(20px)

    .debounced-input
      width: 100%

  .content
    background-color: #fff
    display: flex
    flex: 1 1 0%
    flex-direction: column
    height: 100%
    left: 0
    overflow: hidden
    position: absolute
    width: "calc(100% - %s )" % $profile-width

    .mobile &
      width: 100%

    &.slice-content-enter-active,
    &.slice-content-leave-active
      transition: all 0.5s ease-in-out

    &.slice-content-enter,
    &.slice-content-leave-to
      transform: translate(-100%, 0)

      &.right
        transform: translate(100%, 0)

    &.right
      left: $profile-width

      .mobile &
        left: 0

    &.state-perfumes
      .selection
        padding-bottom: vh(20px)

        & + .ingredients
          margin-top: 0

    .selection
      background-color: #fff
      padding: vh(30px) vw(30px) vh(20px)

      .mobile &
        padding: vw(30px) vw(20px) vw(20px)

      & + .ingredients
        margin-top: vh(-20px)

      .title
        font-weight: $fw-bold
        m-font-size(16, 20)
        margin-bottom: vh(20px)

      .selected-ingredients-container
        display: flex

      .selected-ingredients-list
        align-items: center
        display: flex
        flex: 1 1 0%
        // flex-wrap: wrap
        margin: vw(-5px)
        overflow-x: auto
        overflow-y: hidden

      .profile-edit-item
        border-radius: vw(4px)
        margin: vw(5px)
        padding: vw(5px)

        // &:not(:last-child)
        // margin-right: vw(10px)
        >>> .image
          height: vw(30px)
          width: vw(30px)

        >>> .info
          .name
            font-weight: $fw-regular
            white-space: nowrap

        >>> .trash-button
          margin-left: vw(30px)

      .next
        cursor: pointer
        display: flex
        flex: 1 1 auto
        justify-content: flex-end

      .icon-back
        cursor: pointer
        m-icon("plus", 50)
        margin-left: vw(30px)

        .mobile &
          m-icon("plus", 40)
          margin-left: vw(20px)

    .ingredients
      display: flex
      flex: 1 1 100%
      flex-direction: column
      overflow: auto
      transition: flex 0.4s ease-in-out

    .state-toggle
      flex: 0 0 auto

      .search-btn
        margin: vw(20px) auto
        width: vw(200px)

    .perfumes
      display: flex
      flex: 0 0 0%
      flex-direction: column
      overflow: auto
      transition: flex 0.4s ease-in-out

    &.state-perfumes
      .ingredients
        flex: 0 0 0%

      .perfumes
        flex: 1 1 100%

    .no-results
      left: 50%
      margin-top: vw(-50px)
      opacity: 0.4
      position: absolute
      top: 50%
      transform: translate(-50%, -50%)
      m-font-size(28)
      text-align: center

    .ingredients-list
      display: flex
      flex-direction: column
      overflow: auto
      padding-left: vw(30px)
      padding-right: vw(30px)

      .disabled
        opacity: 0.7

      .disclaimer
        color: $manatee
        margin-bottom: vw(20px)
        m-font-size(14, 18)

        .mobile &
          m-font-size(16, 20)

      .mobile &
        display: block
        padding-bottom: vh(100px)
        padding-left: vw(20px)
        padding-right: vw(20px)

      .group
        display: flex
        flex-direction: column

        .group-wrapper
          min-height: vh(200px)

        .group-label
          m-font-size(18, 21)
          border-bottom: 2px solid #000
          font-weight: $fw-bold
          margin-bottom: vh(10px)
          padding-bottom: vh(10px)

          .mobile &
            m-font-size(16, 20)

        .group-items
          display: flex
          flex-wrap: wrap
          justify-content: flex-start
          margin-bottom: vh(30px)

          .mobile &
            margin-bottom: vw(30px)

          .more-less-button-container
            display: flex
            justify-content: center

          .more-less-button-wrapper
            align-items: center
            display: flex
            flex-direction: column

            .more-less-button
              align-items: center
              background-color: $concrete
              border-radius: 50%
              cursor: pointer
              display: flex
              height: vw(80px)
              justify-content: center
              margin: 1px
              margin-bottom: vh(15px)
              text-align: center
              text-transform: uppercase
              width: vw(80px)

              .mobile &
                margin-bottom: vw(10px)

              &:hover
                background-color: $mercury
                color: #fff

              .more-button-icon
                m-icon("more", 24)

              .less-button-icon
                m-icon("less", 24)

            .label
              m-font("Lelo", "light")
              m-font-size(14, 16)

          .ingredient-item
            cursor: pointer
            flex: 0 0 percentage((1 / 6))
            margin: vh(20px) 0

            .mobile &
              $gap = 10px
              $width = percentage((1 / 3))
              flex: 0 0 "calc(%s - (%s * 2))" % ($width vw($gap))
              margin-bottom: vw($gap * 2)
              margin-left: vw($gap)
              margin-right: vw($gap)

            >>> .image
              .mobile &
                margin-bottom: vw(10px)

        .more
          margin-bottom: vw(40px)

    .results
      display: flex
      flex: 1 1 0%
      flex-direction: column
      overflow-y: auto
      position: relative

    .loading-overlay
      top: 0 !important

    .no-results
      left: 50%
      margin-top: vw(-50px)
      opacity: 0.4
      position: absolute
      top: 50%
      transform: translate(-50%, -50%)
      m-font-size(28)
      text-align: center

    .results-list
      display: flex
      flex-wrap: wrap
      padding-left: vw(30px)
      padding-right: vw(30px)

      .mobile &
        padding: 0
        padding-bottom: vh(200px)

      .perfume-item
        $gap = vw(10px)
        flex: 0 0 "calc(50% - %s)" % $gap
        padding: vh(30px) vw(20px) vh(20px)

        &:nth-child(odd)
          margin-right: $gap

        &:nth-child(even)
          margin-left: $gap

        .mobile &
          flex: 0 0 100%
          margin: 0
          padding: vw(20px)

  .right-panel
    background-color: #fff
    box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.1)
    flex: 0 0 vw(400px)
    height: 100%
    position: absolute
    right: 0
    top: 0
    transition: all 0.5s ease-in-out
    width: $profile-width
    z-index: 11

    @media (orientation: portrait)
      .tablet &
        flex: 0 0 vw(500px)

    &.left
      right: 100%
      transform: translateX(100%)

      .mobile &
        right: 0
        transform: translateX(0)

      >>>.slide-enter,
      >>>.slide-leave-to
        transform: translate(-100%, 0)

        .mobile &
          transform: translate(0, 0)

      >>> .overlay-title
        margin-top: vh(30px)

    .mobile &
      bottom: 0
      box-shadow: none
      height: unset
      position: fixed
      top: unset
      width: 100%

  .modal
    background-color: rgba(#000, 0.5)
    height: 100%
    left: 0
    position: absolute
    top: 0
    width: 100%
    z-index: 11

    &.fade-enter-active,
    &.fade-leave-active
      transition: opacity 0.5s ease-in-out

    &.fade-enter,
    &.fade-leave-to
      opacity: 0

    .modal-container
      background-color: #fff
      border-radius: 8px
      left: 50%
      min-width: vw(500px)
      padding: vw(40px)
      position: fixed
      text-align: center
      top: 50%
      transform: translate(-50%, -50%)

      .modal-title
        m-font-size(24, 28)
        font-weight: 600

      .modal-text
        m-font-size(21, 23)
        margin-bottom: vw(40px)
        margin-top: vw(40px)

      .actions
        .btn
          margin-left: auto
          margin-right: auto
          width: vw(300px)
</style>