<template>
  <div
    class="view recommendations"
    :class="{ scrollable: !$asyncComputed.recommendations.updating }"
  >
    <div class="top">
      <div class="back-button" @click="() => $router.back()">
        <div class="back-icon"></div>
        <div class="back-text">{{ $t("back") }}</div>
      </div>
      <!-- <div class="top-reco-list"> -->
      <!-- <template v-if="this.$store.state.profile.perfumes.length===0 && !$asyncComputed.recommendations.updating">
          <div class="no-data-disclaimer">
            <div class="no-data-content">
              <div class="main">{{$t('your_profile_is_empty')}}</div>
              <div class="sub">{{$t('add_to_profile_to_get_recos')}}</div>
              <div class="actions">
                <router-link class="button" to="/perfume-search">{{$t('mode_perfumes')}}</router-link>
                <router-link class="button" to="/ingredient-search">{{$t('mode_ingredients')}}</router-link>
              </div>
            </div>
          </div>
        </template> -->
      <div
        class="slider-container"
        v-if="recommendations && recommendations.length !== 0"
      >
        <div class="text">{{ $t("price") }}</div>
        <slider-range
          :min="minPrice"
          :max="maxPrice"
          :minValue.sync="minValue"
          :maxValue.sync="maxValue"
          :step="1"
        />
        <div
          class="button slider-btn"
          :class="{
            disabled:
              minValue === filterMinPrice && maxValue === filterMaxPrice,
          }"
          @click="applyFilter"
        >
          {{ $t("apply") }}
        </div>
      </div>
      <template v-if="!recommendations">
        <div class="no-data-disclaimer">
          <div class="no-data-content">{{ $t("something_went_wrong") }}</div>
        </div>
      </template>

      <template v-else-if="isMobile">
        <!-- <perfume-card v-for="perfume in topRecos" :key="perfume.id" :data="perfume" @click="()=>onPerfumeClick(perfume)"></perfume-card> -->
        <!-- <carousel :perPage="1" :navigationEnabled="true" navigationNextLabel="" navigationPrevLabel="">
            <slide v-for="perfume in topRecos" :key="perfume.id" @slideclick="()=>onPerfumeClick(perfume)">
              <perfume-card :data="perfume"></perfume-card>
            </slide>
          </carousel> -->
        <perfume-card
          v-for="perfume in topRecos"
          :key="perfume.id"
          :data="perfume"
          @click="() => onPerfumeClick(perfume)"
        ></perfume-card>
      </template>
      <template v-else>
        <!-- <div class="profile-link" @click="$router.push('/profile')">{{$t('goto_my_profile')}}</div> -->
        <div class="top-reco-list">
          <perfume-card
            v-for="perfume in topRecos"
            :key="perfume.id"
            :data="perfume"
            @click="() => onPerfumeClick(perfume)"
          ></perfume-card>
        </div>
      </template>
      <!-- </div> -->
    </div>
    <div class="bottom" :class="{ open: bottomOpen }">
      <transition-group name="fade" tag="div">
        <div
          class="bottom-content"
          v-if="otherRecos && otherRecos.length"
          key="list"
        >
          <div class="description">
            <div class="title">{{ $t("rest_of_recos") }}</div>
          </div>
          <div class="bottom-reco-list">
            <perfume-card
              v-for="perfume in otherRecos"
              :key="perfume.id"
              :data="perfume"
              @click="() => onPerfumeClick(perfume)"
            ></perfume-card>
          </div>
        </div>
        <div
          class="bottom-buttons"
          v-if="otherRecos && availableRecos.length > slice"
          key="toggle"
        >
          <div class="btn btn--secondary" @click="handleOpenMore">
            {{ $t("view_more") }}
          </div>
        </div>
      </transition-group>
      <div
        class="bottom-toggle button"
        @click="$router.push({ name: 'Profile' })"
        v-if="$store.getters.canShowProfile()"
      >
        {{ $t("my_profile") }}
      </div>
    </div>
    <loading
      v-if="$asyncComputed.recommendations.updating || loading"
    ></loading>
  </div>
</template>

<script>
import { getRecommendations } from "@/services/api";
import PerfumeCard from "../components/PerfumeCard.vue";
import Loading from "../components/Loading.vue";
import { getEanData } from "@/utils/perfume";
import { brTracker } from "../services/tracker";
import SliderRange from "../components/SliderRange.vue";
import { lowerPrice } from "../utils/perfume";
import routeParams from "../mixins/RouteParams";
// import { Carousel, Slide } from 'vue-carousel';

export default {
  components: {
    PerfumeCard,
    Loading,
    // Carousel,
    // Slide
    SliderRange,
  },
  props: ["mode"],
  data() {
    return {
      needsRefresh: true,
      bottomOpen: false,
      minValue: null,
      maxValue: null,
      slice: 6,
      loading: false,
      // lastRecosHash: null,
      // cachedRecos: null
    };
  },
  mixins: [routeParams(["filterMinPrice", "filterMaxPrice"], "number")],
  mounted() {
    this.updateRangeData();
    this.eventTracker.emitEvent("VIEW_OPENED", {
      type: "RECOMMENDATIONS_VIEW",
    });
    brTracker.pageVisit(brTracker.TypePageVisitedEvent.VIEW_RECOMMENDATIONS);
    let restricted = this.$route.query.restricted
      ? this.$route.query.restricted.split(",")
      : null;
    let totalPerfumes = restricted || this.$store.state.profile.perfumes;
    if (
      this.mode === "perfumes" &&
      totalPerfumes.length < 2 &&
      this.$store.state.profile.concepts.length < 1
    ) {
      this.$router.replace({
        name: "ConceptsSelect",
        query: this.$route.query,
      });
    }
    this.profilePerfumes && this.updateRecos(restricted);
  },
  watch: {
    availableRecos() {
      this.trackRecos();
    },
    profilePerfumes() {
      if (this.profilePerfumes) {
        let restricted = this.$route.query.restricted
          ? this.$route.query.restricted.split(",")
          : null;
        this.updateRecos(restricted);
        this.trackRecos();
      }
    },
    minPrice() {
      this.updateRangeData();
    },
    maxPrice() {
      this.updateRangeData();
    },
  },
  computed: {
    minPrice() {
      return this.recommendations?.reduce(
        (price, perfume) => Math.min(price, lowerPrice(perfume)),
        Infinity
      );
    },
    maxPrice() {
      return this.recommendations?.reduce(
        (price, perfume) => Math.max(price, lowerPrice(perfume)),
        0
      );
    },
    availableRecos() {
      if (this.filterMinPrice === null || this.filterMaxPrice === null)
        return this.recommendations;
      return (
        this.recommendations &&
        this.recommendations.filter((perfume) => {
          let price = lowerPrice(perfume);
          return this.filterMinPrice <= price && this.filterMaxPrice >= price;
        })
      );
    },
    topRecos() {
      return this.availableRecos && this.availableRecos.slice(0, 6);
    },
    otherRecos() {
      return this.availableRecos && this.availableRecos.slice(6, this.slice);
    },
    selection() {
      let restricted = this.$route.query.restricted;
      if (this.mode === "ingredients") return this.profileIngredients || [];
      else {
        let perfs = (this.profilePerfumes || []).filter((p) => {
          return restricted ? restricted.indexOf(p.id) >= 0 : true;
        });
        let concepts = (this.profileConcepts || []).map((c) => ({
          name: this.$t(c.name),
        }));
        return [...perfs, ...concepts];
      }
    },
    selectionString() {
      let list = this.selection.map((p) => p.name);
      if (list.length > 3) {
        return list.slice(0, 4).join(", ") + "...";
      } else if (list.length > 1) {
        return (
          list.slice(0, -1).join(", ") +
          ` ${this.$t("and")} ` +
          list[list.length - 1]
        );
      } else return list[0];
    },
    completeProfileStore() {
      return {
        perfumes: this.profilePerfumes,
        concepts: this.profileConcepts,
        ingredients: this.profileIngredients,
        min: this.filterMinPrice,
        max: this.filterMaxPrice,
      };
    },
  },
  asyncComputed: {
    // _recommendations(){
    //   // let recos = this.needsRefresh ? getRecommendations() : this.$store.state.recos.latestRecos
    //   // // this.cachedRecos = recos
    //   // return recos
    //   return getRecommendations()
    // },
    profileIngredients() {
      return this.$store.getters.ingredients();
    },
    profilePerfumes() {
      return this.$store.getters.perfumes();
    },
    profileConcepts() {
      return this.$store.getters.concepts();
    },
    recommendations() {
      return this.$store.state.recos.latestRecos;
    },
    // recommendations(){
    //   // if(this.$asyncComputed._recommendations.updating) return null
    //   // else return this._recommendations
    //    return this._recommendations
    // },
  },
  methods: {
    applyFilter() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.slice = 6;
        this.filterMinPrice = this.minValue;
        this.filterMaxPrice = this.maxValue;
        let restricted = this.$route.query.restricted
          ? this.$route.query.restricted.split(",")
          : null;
        this.updateRecos(restricted);
      }, 150);
    },
    trackRecos() {
      if (!this.availableRecos || !this.profilePerfumes) return;
      const topResults = this.availableRecos
        .map((perfume) => ({
          id: perfume.id,
          ...getEanData(perfume),
        }))
        .slice(0, 10);
      let restricted = this.$route.query.restricted
        ? this.$route.query.restricted.split(",")
        : null;
      brTracker.recoPerfumes(
        this.availableRecos.slice(0, 6),
        this.mode === "ingredients",
        this.completeProfileStore
      );
      if (this.mode === "ingredients") {
        this.eventTracker.emitEvent("SEARCH_PERFUMES", {
          type: "INGREDIENT",
          data: {
            ingredients:
              restricted || this.profileIngredients.map(({ id }) => id),
          },
          topResults,
        });
      } else {
        const perfumes = this.profilePerfumes.map((perfume) => ({
          id: perfume.id,
          ...getEanData(perfume),
        }));
        this.eventTracker.emitEvent("RECOMMENDATION_FETCH", {
          resultingProfile: {
            perfumes: perfumes,
          },
          topResults,
        });
      }
    },
    updateRangeData() {
      this.minValue = this.filterMinPrice ?? this.minPrice;
      this.maxValue = this.filterMaxPrice ?? this.maxPrice;
    },
    handleOpenMore() {
      this.slice += 6;
      // brTracker.track(brTracker.eventNameEnum.CTA_RECO_SHOW_MORE)
      brTracker.recoPerfumes(
        this.availableRecos.slice(this.slice - 6, this.slice),
        this.mode === "ingredients",
        this.completeProfileStore,
        this.slice - 5
      );
    },
    updateRecos(restricted) {
      this.$store.state.recos.needsUpdate &&
        getRecommendations(this.mode, restricted);
    },
    onPerfumeClick(perfume) {
      // setTimeout(()=>{
      // if(!this.dragged) this.$router.push(`/perfume/${perfume.id}`)
      // this.dragged = false
      // },200)
      this.$router.push({
        name: `Perfume`,
        params: { id: perfume.id },
        query: { fromReco: true },
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.recommendations
  display: flex
  flex-direction: column
  padding-bottom: 0 !important

  .loading-overlay
    top: 0

  .perfume-card
    $width = percentage((1 / 3))
    $gap = vw(20px)
    flex: 0 0 "calc(%s - (%s * 2))" % ($width $gap)
    margin: $gap

    .mobile &
      $width = percentage((1 / 2))
      $gap = vw(10px)
      flex: 0 0 "calc(%s - (%s * 2))" % ($width $gap)
      margin: $gap

    >>> .image
      .mobile &
        height: vw(180px)
        margin: 0
        width: vw(180px)
        width: 100%

    >>> .info
      .mobile &
        width: 100%

  .top
    background-color: #fff
    flex: 1 1 0%
    padding: vw(40px) vw(30px) vh(30px)

    .mobile &
      display: flex
      flex-wrap: wrap
      padding-bottom: vw(20px)
      padding-left: vw(10px)
      padding-right: vw(10px)
      padding-top: vw(40px)

    .slider-container
      align-items: center
      display: flex
      gap: vw(15px)
      margin-bottom: vw(40px)
      margin-left: auto
      margin-right: auto
      width: 60%

      .mobile &
        flex-direction: column
        width: 100%

      .text
        m-font-size(16, 20)
        text-transform: uppercase

      .slider-btn
        m-font-size(14, 18)
        padding: vw(6px) vw(20px)
        text-transform: uppercase

    .description
      align-items: center
      display: flex
      flex-direction: column
      margin-bottom: vh(30px)
      margin-left: auto
      margin-right: auto
      width: 80%

      .mobile &
        margin-bottom: vw(20px)
        margin-top: vw(20px)

      .title
        font-weight: $fw-medium
        m-font-size(18, 22)
        margin-bottom: vh(20px)
        text-transform: uppercase

        .mobile &
          m-font-size(16, 20)

      .subtitle
        color: $manatee
        m-font-size(14, 18)
        text-align: center
        width: 80%

        .mobile &
          m-font-size(16, 20)

    .profile-link
      m-font-size(14, 18)
      cursor: pointer
      margin-bottom: vw(20px)
      text-align: center
      text-decoration: underline

    .top-reco-list
      display: flex
      flex-wrap: wrap
      justify-content: center
      margin: auto
      position: relative
      width: 80%

      .tablet &
        width: 100%

      .mobile &
        width: 100%

    .no-data-disclaimer
      left: 50%
      position: absolute
      top: 50%
      transform: translate(-50%, -50%)

      .mobile &
        width: 90%

      .no-data-content
        align-items: center
        display: flex
        flex-direction: column
        text-align: center

        .main
          m-font-size(24)
          margin: vw(20px)

        .sub
          m-font-size(20)
          color: $manatee

        .actions
          align-items: center
          display: flex
          flex-wrap: wrap
          justify-content: center
          margin: vw(15px)

          .button
            margin: vw(15px)
            min-width: vw(180px)

    // .perfume-card
    // $width = percentage((1 / 3))
    // $gap = vw(20px)
    // flex: 0 0 "calc(%s - (%s * 2))" % ($width $gap)
    // margin: $gap

    // .mobile &
    // $width = percentage((1 / 2))
    // $gap = vw(10px)
    // flex: 0 0 "calc(%s - (%s * 2))" % ($width $gap)
    // margin: $gap

    // >>> .image
    // .mobile &
    // height: vw(180px)
    // width: vw(180px)
    .back-button
      align-items: center
      background-color: rgba(#fff, 0.9)
      box-shadow: 0 0 0 10px rgba(#fff, 0.9)
      cursor: pointer
      display: flex
      left: vw(25px)
      position: fixed
      top: "calc(%s + %s)" % ($header-height vw(32px))
      z-index: 1

      .mobile &
        left: vw(15px)
        top: "calc(%s + %s)" % ($header-height-mobile vw(20px))

      .back-icon
        m-icon("arrow-left", 14)
        margin-right: vw(5px)

      .back-text
        font-weight: $fw-medium
        m-font-size(14, 20)
        margin-top: 0.1em
        text-transform: uppercase

  .bottom
    background-color: #fff

    // flex: 1 1 0%
    &.open
      .bottom-content
        flex-direction: column

    .description
      margin-bottom: vh(25px)

      .mobile &
        margin-bottom: vw(20px)

    .title
      font-weight: $fw-bold
      m-font-size(18, 22)

    .bottom-content
      align-items: center
      display: flex
      flex-direction: column
      justify-content: center
      padding: vw(30px)
      padding-top: 0

      .mobile &
        padding-left: vw(10px)
        padding-right: vw(10px)

      &.fade-enter-active
        transition: opacity 0.25s ease-in-out

      &.fade-leave-active
        transition: opacity 0.25s ease-in-out

      &.fade-enter,
      &.fade-leave-to
        opacity: 0

    .bottom-reco-list
      display: flex
      flex-wrap: wrap
      justify-content: center
      margin: auto
      width: 80%

      .tablet &
        width: 100%

      .mobile &
        margin-left: vw(0)
        margin-right: vw(0)
        width: 100%

  .bottom-buttons
    align-items: center
    display: flex
    flex-direction: column
    justify-content: center
    padding: vw(120px)
    padding-top: 0

    .mobile &
      padding: vw(100px)
      padding-left: vw(10px)
      padding-right: vw(10px)
      padding-top: 0

  .bottom-toggle
    bottom: vw(30px)
    left: 50%
    position: fixed
    transform: translateX(-50%)

    .desktop &
      margin-left: vw(-7.5px)
</style>