<template>
  <div class="header" :class="{ ['open-sub-header']: isSearchPerfumes }">
    <div class="main-header">
      <a class="logo" :href="notinoURL" target="_blank"></a>
      <!-- <select class="lang-select" @change="(e)=>$store.dispatch('setLanguage',e.target.value)" :value="$store.state.i18n.lang">
        <option value="en">en</option>
        <option value="cz">cz</option>
      </select> -->
      <div class="left"></div>
      <div class="center">
        <!-- <header-dropdown v-if="!isMobile && selectedOption" v-model="selectedOption" @change="onSelectChange"></header-dropdown> -->
        <Multiselect
          v-if="instalationLangs.length > 1"
          :value="lang"
          @input="changeLang"
          :options="instalationLangs"
        ></Multiselect>
      </div>
      <div class="right">
        <div
          class="home-btn"
          @click="onHomeClicked"
          v-if="$route.name !== 'Welcome' && $route.name !== 'ModeSelect'"
        >
          <div class="icon" />
          <div class="text" v-if="!isMobile">{{ $t("home") }}</div>
        </div>
        <heart-like v-if="iSearchPerfumePath" />
        <router-link
          v-if="iSearchPerfumePath"
          class="profile-btn"
          :to="{
            name: 'Profile',
            params: { mode: iSearchPerfumePath ? 'perfume' : 'ingredient' },
          }"
          :class="{ disabled: !$store.getters.canShowProfile() }"
        >
          <div class="icon" />
          <div class="text" v-if="!isMobile">{{ $t("my_profile") }}</div>
        </router-link>
        <!-- <router-link class="home-btn" :to="'?confirm-reset'">{{$t("reset_selection")}}</router-link> -->
      </div>
    </div>
    <!-- <transition name="slide">
      <div class="new-recos-notification" v-if="showNotification" >
        <div class="texts">
          <div class="text" @click="onClick" v-html="$t('check_your_new_recommendations_1')"></div>
          <div class="text" @click="onClick" v-html="$t('check_your_new_recommendations_2')"></div>
        </div>
        <div class="right">
          <div class="action btn" @click="onClick">{{$t('view_new_recommendations')}}</div>
          <div class="close" @click="onClose"></div>
        </div>
      </div>
    </transition> -->
    <template v-if="showResetWarning">
      <div class="reset-confirmation">
        <div class="content">
          <div class="text">{{ $t("do_you_want_to_reset") }}</div>
          <div class="actions">
            <div class="button" @click="confirmReset">
              {{ $t("start_new") }}
            </div>
            <div class="button" @click="rejectReset">
              {{ $t("keep_perfumes") }}
            </div>
          </div>
        </div>
        <div class="close-btn" @click="pendingNav = null"></div>
      </div>
    </template>
    <template v-if="confirmHome">
      <div class="reset-confirmation">
        <div class="content">
          <div class="text">{{ $t("do_you_want_to_reset") }}</div>
          <div class="actions">
            <div
              class="button"
              @click="
                pendingNav = 'Welcome';
                confirmReset();
                confirmHome = false;
              "
            >
              {{ $t("start_new") }}
            </div>
            <div
              class="button"
              @click="
                pendingNav = 'Welcome';
                rejectReset();
                confirmHome = false;
              "
            >
              {{ $t("keep_perfumes") }}
            </div>
          </div>
        </div>
        <div class="close-btn" @click="confirmHome = false"></div>
      </div>
    </template>
    <template v-if="isSearchPerfumes">
      <div class="sub-header">
        <div class="subtitle">{{ $t("perfume_search_subtitle") }}</div>
        <button
          class="go-reco btn--primary"
          @click="$router.push({ name: RouteNames.RecommendationsBySearch })"
        >
          {{ $t("get_recomendations") }}
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import { EVENT_NAME_ENUM } from "@bestiario/puig-event-tracker/eventTypes";
import Multiselect from "vue-multiselect";
import { mapActions, mapGetters } from "vuex";
import { getLangs } from "../services/api";
import "vue-multiselect/dist/vue-multiselect.min.css";
import { allStrings } from "../i18n";
import notinoOrigin from "@/settings/notinoOrigin";
// import { brTracker } from '../services/tracker';
// import HeaderDropdown from "./HeaderDropdown.vue";
import { RouteNames } from "../router";
import HeartLike from "./HeartLike.vue";
export default {
  components: { Multiselect, HeartLike },
  data() {
    return {
      selectedOption: null,
      pendingNav: null,
      confirmHome: null,
    };
  },
  computed: {
    ...mapGetters(["lang", "instalation", "referer", "perfumesIds"]),
    RouteNames() {
      return RouteNames;
    },
    iSearchPerfumePath() {
      return (
        [RouteNames.SearchPerfume, RouteNames.ConceptsSelect].includes(
          this.$route.name
        ) ||
        ([RouteNames.Recommendations, RouteNames.Profile].includes(
          this.$route.name
        ) &&
          this.$route.params.mode === "perfume")
      );
    },
    notinoURL() {
      let queryParams =
        "?utm_source=fragrance-finder&utm_medium=referrer&utm_campaign=fragrance-finder_logo";
      if (this.referer) return "https://" + this.referer + queryParams;
      const countryCode = this.instalation?.countryCode;
      const url = notinoOrigin[countryCode] || notinoOrigin.other;
      return url + queryParams;
    },
    newRecos() {
      return this.$store.state.recos.needsUpdate;
    },
    showNotification() {
      return this.$store.state.recos.shouldShowNotification;
    },
    showResetWarning() {
      return this.pendingNav !== null;
    },
    instalationLangs() {
      return Object.keys(allStrings).filter(
        (lang) => lang.split("-")[1] === this.instalation?.countryCode
      );
    },
    isSearchPerfumes() {
      return (
        this.$route.name === RouteNames.SearchPerfume &&
        this.perfumesIds.length > 0
      );
    },
  },
  methods: {
    ...mapActions(["setApiLanguage", "setLanguage"]),
    async changeLang(newLang) {
      const langs = await getLangs();
      if (langs.map((e) => e.id).includes(newLang)) {
        this.setApiLanguage(newLang);
      } else {
        const [lang] = newLang.split("-");
        this.setApiLanguage(lang);
      }
      this.setLanguage(newLang);
    },
    // onClick(){
    //   this.$store.dispatch('setNoNotification')
    //   this.$router.push('/recommendations')
    //   this.viewed = true
    // },
    // onClose(){
    //   this.$store.dispatch('setNoNotification')
    // }
    onHomeClicked() {
      if (this.$store.state.profile.perfumes.length > 0){
        this.confirmHome = true;
      }
      else {
        // ingredient path no validation => reset store and new SESSION
        this.reset();
        // add this.$route for strange warning: "installationId missing"
        this.$router.push({ ...this.$route, name: "Welcome" });
      }
    },
    onSelectChange(value) {
      if (value && this.$route.name !== value) {
        this.pendingNav = value;
      }
    },
    onMobileModeClick() {
      this.pendingNav = "ModeSelect";
    },
    reset() {
      this.$store.dispatch("reset");
      this.eventTracker.emitEvent(EVENT_NAME_ENUM.NEW_SESSION);
    },
    confirmReset() {
      this.reset();
      // brTracker.sessionStart()
      this.$router.push({ name: this.pendingNav });
      this.pendingNav = null;
    },
    rejectReset() {
      this.$router.push({ name: this.pendingNav });
      this.pendingNav = null;
    },
  },
  // watch:{
  //   newRecos(value){
  //     if(value) this.viewed = false
  //   }
  // }
  watch: {
    $route: {
      handler() {
        if (
          this.$route.name === "PerfumeSearch" ||
          this.$route.name === "IngredientSearch"
        )
          this.selectedOption = this.$route.name;
        else if (this.$route.name === "Profile")
          this.selectedOption = "PerfumeSearch";
        else if (this.$route.params.mode === "ingredients")
          this.selectedOption = "IngredientSearch";
        else if (this.$route.params.mode === "perfumes")
          this.selectedOption = "PerfumeSearch";
        else this.selectedOption = null;
      },
      immediate: true,
    },
    // selectedOption(value){
    //   if(value && this.$route.name!==value) this.$router.push({name:value})
    // }
  },
};
</script>

<style lang="stylus" scoped>
.header
  background-color: #000
  color: #fff
  height: $header-height
  position: relative
  transition: height 0.25s ease-in-out

  .mobile &
    height: $header-height-mobile

  &.open-sub-header
    height: "calc(%s + %s)" % ($header-height $sub-header-height)

    .mobile &
      height: "calc(%s + %s)" % ($header-height-mobile $sub-header-height-mobile)

  .main-header
    m-font-size(22, 26)
    align-items: center
    display: flex
    height: $header-height
    padding: 0 vw(30px)
    text-align: center
    width: 100%
    z-index: 3

    .mobile &
      height: $header-height-mobile
      padding-left: vw(20px)
      padding-right: vw(20px)

    .logo
      background-image: url("~@/assets/images/notino-logo.svg")
      background-position: left center
      background-repeat: no-repeat
      background-size: contain
      cursor: pointer
      height: vh(30px)
      width: vw(120px)

      .desktop &,
      .tablet &
        margin-right: vw(40px)

      .mobile &
        flex: 1 1 0%
        height: vw(20px)
        width: vw(80px)

    .left
      align-items: flex-start
      display: flex
      justify-content: center
      position: relative

      .mobile &
        justify-content: flex-end
        margin-left: auto

    .center
      align-items: center
      display: flex
      flex: 1 1 0%
      justify-content: center
      position: relative

      .mobile &
        justify-content: flex-end

    .right
      display: flex
      gap: vw(20px)
      justify-content: flex-end

    .home-btn,
    .profile-btn,
    .mode-btn
      align-items: center
      cursor: pointer
      display: flex

      &.disabled
        opacity: 0.4
        pointer-events: none

      .mobile &
        m-font-size(14, 20)

      .text
        margin-left: vw(10px)

    .home-btn
      .icon
        m-icon("restart", 30)

        .mobile &
          m-icon("restart", 20)

    .profile-btn
      .icon
        m-icon("profile", 30)

        .mobile &
          m-icon("profile", 20)

    .mode-btn
      .icon
        m-icon("magnifying-glass", 30)

        .mobile &
          m-icon("magnifying-glass", 18)
          margin-right: vw(20px)

  .new-recos-notification
    align-items: center
    background-color: $notino-pink
    box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.1)
    color: #000
    display: flex
    height: vh(70px)
    justify-content: space-between
    padding-left: vw(30px)
    padding-right: vw(30px)
    position: absolute
    top: $header-height
    width: 100%
    z-index: 2

    .mobile &
      align-items: flex-start
      flex-direction: column
      height: auto
      padding: vw(15px)

    &.slide-enter-active,
    &.slide-leave-active
      transition: transform 0.5s ease-in-out

    &.slide-enter,
    &.slide-leave-to
      transform: translate(0, -100%)

    .texts
      align-items: flex-start
      display: flex

      .mobile &
        flex-direction: column

      .text
        m-font-size(18, 22)
        margin-right: 8px

        .mobile &
          m-font-size(16, 20)
          margin-right: 0

    .right
      align-items: center
      display: flex

      .action
        m-font-size(14, 20)
        m-letter-spacing(50)
        padding: 0.75em 1em

        .mobile &
          margin: vw(10px) 0 0

      .close
        cursor: pointer
        m-icon("close", 18)
        margin-left: vw(40px)

        .mobile &
          margin: vw(15px)
          position: absolute
          right: 0
          top: 0

  .reset-confirmation
    background-color: rgba(#fff, 0.9)
    height: 100%
    left: 0
    position: fixed
    top: 0
    width: 100%

    .content
      align-items: center
      display: flex
      flex-direction: column
      left: 50%
      position: absolute
      top: 50%
      transform: translate(-50%, -50%)
      width: 90%

      .text
        color: #000
        m-font-size(24)
        margin-bottom: vw(30px)
        text-align: center

      .actions
        display: flex

        >>> .button
          padding: 1em

  .close-btn
    m-icon("close", 18)
    cursor: pointer
    position: absolute
    right: vw(20px)
    top: vw(80px)

    .mobile &
      top: vw(65px)

  .sub-header
    align-items: center
    background-color: #000
    display: flex
    height: $sub-header-height
    padding: vw(10px) vw(30px)

    .mobile &
      height: $sub-header-height-mobile
      padding: 0 vw(20px)

    .subtitle
      m-font-size(22, 28)
      flex: 1 1 0%
      font-weight: 700

      .mobile &
        display: none

    .go-reco
      m-font-size(18, 18)
      background-color: #CA2B69
      border-radius: vw(4px)
      color: #fff
      font-weight: 700
      max-height: 100%
      padding: 0.5em 1em

      .mobile &
        margin-left: auto

      .desktop &
        align-self: flex-start
</style>