<template>
  <div class="input-letters">
    <ul>
      <li
        v-for="letter in letters"
        :key="letter"
        :class="{ selected: selected === letter }"
      >
        <button
          class="btn btn--ghost button-letter"
          @click="$emit('change', letter === selected ? null : letter)"
        >
          <div class="text">{{ letter }}</div>
        </button>
      </li>
    </ul>
  </div>
</template>
<script>
import letters from "../../settings/letters";

export default {
  props: {
    selected: String,
  },
  model: {
    event: "change",
    prop: "selected",
  },
  data() {
    return {
      letters,
    };
  },
};
</script>

<style lang="stylus" scoped>
.input-letters
  ul
    align-items: center
    display: flex
    height: 100%
    justify-content: space-between

    li
      color: black
      display: flex
      justify-content: center

      .desktop &
        m-font-size(12, 25)

      .mobile &
        m-font-size(12, 20)

      .tablet &
        m-font-size(12, 22)

      &:hover
        color: #fff

        button
          background-color: rgba(#000, 0.5)

      &.selected
        color: #fff

        button
          background-color: #000

      button
        $size = vw(34px)
        m-font-size(20, 26)
        border-radius: 50%
        font-weight: 300
        height: $size
        margin: auto
        padding: 0
        text-decoration: none !important
        width: $size

        &.button-letter
          color: inherit

        .text
          margin-top: 0.1em
          white-space: nowrap
</style>