const SET_REFERRER = 'setReferrer'
export default {
  state: {
    referer: null
  },
  mutations: {
    [SET_REFERRER] (state, referer) {
      state.referer = referer
    }
  },
  actions: {
    setReferer ({ commit }, referer) {
      console.debug('set referer', referer)
      commit(SET_REFERRER, referer)
    }
  },
  getters: {
    referer: state => state.referer
  }
}
