export default {
welcome_to_profiler: "<b>Notino Fragrance Finder</b>",
what_are_you_looking_for: "Lasciati consigliare su un nuovo profumo, perfetto per te o i tuoi cari.<br>Per chi stai cercando il profumo ideale?",
build_your_profile_and: "Lasciati consigliare su un nuovo profumo, perfetto per te o i tuoi cari.<br>Per chi stai cercando il profumo ideale?",
search_by: "Ti consigliamo profumi come piace a te",
gender_m: "Da uomo",
gender_f: "Da donna",
mode_perfumes: "I favoriti",
mode_ingredients: "Ingredienti",
back: "Indietro",
novelty: "Novità",
go_back_to_finder: "Torna al Fragrance Finder",
done: "Fatto",
best_sellers: "Bestseller",
from: "da",
search_your_perfume: "<b>Scegli<b> al massimo 5 profumi che piacciono a te o a chi vuoi fare un regalo.",
search_your_perfume_flat: "Comincia a scrivere il nome del profumo preferito o della marca",
select_your_perfume: "<b>Scegli</b> i tuoi profumi preferiti.",
no_perfumes_found: "Nessun profumo trovato :(",
no_ingredients_found: "Nessun ingrediente trovato :(",
something_went_wrong: "Purtroppo non è stato trovato nessun risultato. Prova a inserire un nuovo termine",
select_fav_ings: "<b>Scegli</b> i tuoi ingredienti preferiti e noi ti aiuteremo a trovare il profumo giusto.",
search_fav_ings: "Inserisci qui il nome dei tuoi ingredienti preferiti.",
building_your_profile: "<b>Creeremo</b> il tuo<br>profilo profumato",
edit_your_profile: "Modifica profilo",
fragrances: "Profumo",
ingredients: "Ingredienti",
concepts: "Concept",
next_step: "Fatto",
what_gender: "Per chi è pensato questo profilo?",
selected_ingredients: "I tuoi ingredienti preferiti",
selected_perfumes: "I tuoi profumi preferiti",
no_perfumes_yet: "Nel tuo profilo non ci sono profumi",
no_ingredients_yet: "Nel tuo profilo non ci sono ingredienti",
no_conceptss_yet: "Nel tuo profilo non ci sono concept",
get_recomendations: "Fatti consigliare",
this_are_your_ings: "Questi sono i tuoi ingredienti preferiti",
your_profile_to_edit: "Il tuo profilo",
add_more_fragrances: "Aggiungi un altro profumo",
add_more_ingredients: "Aggiungi un altro ingrediente",
add_more_concepts: "Aggiungi un altro concept",
top_6_recos: "PROFUMI SU MISURA PER TE",
recos_based_on: "Questi sono i consigli in base al tuo profilo profumato.",
rest_of_recos: "Altri consigli",
view_details: "Visualizza i dettagli",
your_profile_is_empty: "Il tuo profilo è vuoto!",
add_to_profile_to_get_recos: "Aggiungi al tuo profilo profumi o ingredienti e ottieni i nostri consigli",
price: "Prezzo",
buy_it_now: "Scopri di più",
prominent_ings: "Gli ingredienti più intensi",
all_ings: "Elenco completo degli ingredienti",
why_do_we_recommend: "Perché ti consigliamo <b>questo profumo</b>?",
other_ings_present: "Come gli altri, ma meno intensi",
description: "Descrizione",
evaluate_this_reco: "Valuta questo consiglio",
how_relevant_do_you: "Secondo te, quanto è pertinente <b>questo consiglio</b>?",
submit: "Invia",
check_your_new_recommendations_1: "<b>Profilo aggiornato.</b> Grazie!",
check_your_new_recommendations_2: "Ci sono dei nuovi consigli per te.",
view_new_recommendations: "Visualizza i nuovi consigli",
close: "chiudi",
loading: "Lettura in corso",

//Profile_desc,
you_love_perfumes_of_the: "<b>ami i profumi</b><br>",
you_love_perfumes_of_the_plural: "<b>ami i profumi</b><br>",
and: "e",
desc_family: "la famiglia",
desc_families: "le famiglie",
you_also_love_plural: "ami anche i sottogruppi",
you_also_love: "ami anche il sottogruppo",
desc_sub_family: "sottogruppo",
desc_sub_families: "sottogruppi",

//RECOS:,
it: "questo",
also: "anche",
$pronoun_self: "tu",
$possessive_singular_self: "il tuo",
$possessive_refl_singular_self: "il mio",
$possessive_plural_self: "il tuo",
$possessive_refl_plural_self: "il mio",
$possessive_singular_prep_self: "il tuo",
$possessive_singular_liste_self: "il tuo",
$you_self: "tu",
$you_prep_self: "tu",
$do_you_have_self: "hai",
$that_you_love_self: "che ti piacciono",
$you_love_self: "ti piacciono",
$you_adore_self: "ti piacciono",
$apreciate_self: "ti piace",
$you_have_self: "hai",
reco_desc_title: "Ti consigliamo questo profumo perché",
possible_reco_title: "Forse $you_love questo profumo perché",
recommended_by_hero: "$you_adore i suoi ingredienti principali: <b>%HERO%</b>",
recommended_by_fam: "appartiene a una delle $possessive_plural famiglie di profumi, <b>%FAMILY%</b>",
not_recommended_by_fam: "Anche se ha un altro profilo rispetto a $possessive_singular_prep, $you_prep dovresti provarlo",
recommended_by_ings: "ha alcuni degli ingredienti preferiti di $possessive_plural: ",
furthermore: "Avanti",
home: "Nuovo consiglio",
my_profile: "Il mio profilo",
no_concepts_yet: "Nel tuo profilo non ci sono concept",
view_more: "Mostra di più",
select_fav_ingredients_to_find_perfumes: "Scegli i tuoi <b>ingredienti</b><br> preferiti e trova i tuoi<br> profumi <b>preferiti</b>",
your_prefered_concepts: "Le tue caratteristiche preferite",
concept_fresh: "Rinfrescante (per esempio di agrumi)",
concept_intense: "Intenso (per esempio legnoso)",
concept_casual: "Da usare ogni giorno (per esempio a lavoro)",
concept_special_occasion: "Per le occasioni speciali (per esempio un appuntamento)",
concept_sure_bet: "Punta sul sicuro",
concept_novelty: "Di moda (per esempio una novità)",
change_concepts: "Cambiare le caratteristiche selezionate",
select_concepts: "Quasi fatto",
add_concepts: "Aggiungi altre caratteristiche preferite",
or: "oppure",
explore_by: "Esplora per",
brands: "Marchi",
your_selection: "La nostra selezione",
list_view: "Elenco di profumi top",
graphic_view: "Il tuo profilo olfattivo",
recos_based_on_selection: "Consigli basati sulle tue scelte",
add_to_profile: "Aggiungi al mio profilo",
do_you_want_to_reset: "Vuoi impostare il tuo profilo dall’inizio?",
start_new: "Comincia daccapo",
keep_perfumes: "Mantieni i profumi",
click_to_view: "Visualizza con un click",
already_in_profile: "Già aggiunto",
only_showing_that_combine_with: "Visualizzati solamente gli ingredienti in combinazione con",
see_all_ings_in_this_fam: "Visualizza tutti gli ingredienti di questa famiglia",
select_concepts_subtitle: "Se vuoi ottenere un consiglio, devi scegliere almeno 2 profumi (clicca indietro) o aggiungere almeno una delle seguenti caratteristiche di profumo.",
action: "offerte",
free_del: "spedizione gratuita",
new_in: "nuovi",
cancel: "annulla",
see_more: "Mostra di più",
see_less: "Mostra meno",
recommendation_by: "Raccomandazioni con questi ingredienti",
apply: "Applica",
modal_limit_ingredients_title: "Limite di ingredienti",
modal_limit_ingredients_text: "Hai raggiunto il limite massimo per la selezione degli ingredienti (4). Rimuovi un ingrediente prima di aggiungerne un altro.",
empty_selection: "Nessuna selezione",
empty_selection_text: "Seleziona una o più fragranze da consigliare",
search_perfume_subtitle: "Ottimo! Ora hai accesso ai tuoi consigli personalizzati.",
perfume_search_subtitle: "Ottimo! Ora hai accesso ai tuoi consigli personalizzati.",
filter_by_brand: "Filtra per marchio",
perfume_list: "Seleziona una fragranza",
others: "",
some_error: "Ops! Sembra che ci sia un errore sul sito web. Risolveremo il problema il prima possibile. Grazie per la pazienza.",
no_brands_found: "Nessun marchio trovato :("
}