<template>
  <div class="reco-desc" v-if="texts">
    <!-- <div class="intro" v-html="texts.intro"></div> -->
    <div class="content" v-html="texts.content"></div>
  </div>
</template>

<script>

function firstLetterCase (text) {
  // return text.replace(/^\w/, c => c.toUpperCase())
  return text.charAt(0).toUpperCase() + text.slice(1)
}
function concatWithAnd (array,andString) {
  if(array.length===1) return array[0]
  if(array.length===2) return array.join( ' ' + andString + ' ')
  return array.slice(0,-1).join(', ') + ' ' + andString + ' ' + array[array.length-1]
}
export default {
  name: 'RecoDesc',
  props: ['data'],
  mounted(){

  },
  /*
  let descHero,descFamilies,descIng
      let descs = []
      
      // if(this.props.isRecomended) descIntro = this.$t('reco_desc_title')
      // else descIntro = this.$t('possible_reco_title')
      // descIntro = this.$t('reco_desc_title')
      
      let famsAndSubs = await this.$store.getters.profileFamiliesWithSubs() // FALTAN LAS profile fams ? Utils.getProfileFamiliesAndSubs(this.props.profile)
      let userIngredients = await this.$store.getters.profileIngredients() // Faltan los profile ings
      let commonFams = famsAndSubs.filter((f)=>{
        return (this.data.family && f.family.id === this.data.family.id) || (this.data.secondaryFamily && f.family.id === this.data.secondaryFamily.id)
      })
      
      if(commonFams.length>0) {
        descFamilies = this.$t('recommended_by_fam').replace('%FAMILY%',concatWithAnd(commonFams.map((f)=>f.family.name),this.$t("and")))
      } else {
        descFamilies = this.$t('not_recommended_by_fam')
      }
      
      if(this.data.type==='hero') {
        descHero = this.$t('recommended_by_hero').replace('%HERO%',this.data.heroIngredient.name)
        descs.push(descHero)
      }
      
      
      let commonIngs = this.data.ingredientProportions.slice(0,5).filter((p)=>userIngredients.find((i)=>i.id === p.ingredient.id))
      if(this.data.type==='hero') commonIngs = commonIngs.filter((i)=>i.ingredient.id !== this.data.heroIngredient.id)
      if(commonIngs.length > 0) {
        // if(this.data.type==='hero') descIng = this.$t('recommended_by_ings_short').replace('%INGS%',Utils.concatWithAnd(commonIngs.map((i)=>i.ingredient.name),this.$t('and')))
        // else descIng = this.$t('recommended_by_ings').replace('%INGS%',Utils.concatWithAnd(commonIngs.map((i)=>i.ingredient.name),this.$t('and')))
        descIng = this.$t('recommended_by_ings').replace('%INGS%',concatWithAnd(commonIngs.map((i)=>i.ingredient.name),this.$t('and')))
        descs.push(descIng)
      }
      
      // if(this.data.type==='expert') isExpert = true
      
      let fullDesc = 'nodesc'

      if(this.data.type==='hero') fullDesc = descHero
      if(this.data.type==='family') {
        fullDesc = `${this.$t('it')} `+descFamilies
        if(descIng) fullDesc += `, ${this.$t('and')} `+descIng
      }
      if(this.data.type==='ingredient' || this.data.type==='expert'){
        if (commonFams.length>0) {
          if(descIng) fullDesc =  `${this.$t('it')} ` + descIng //+`, ${this.$t('and')} ${this.$t('it')} `+descFamilies
          else fullDesc =  `${this.$t('it')} `+descFamilies
        } else {
          if(descIng) fullDesc = `${this.$t('it')} `+ descIng //+`.<br/><br/>${descFamilies}`
          else fullDesc = descFamilies
        }
      }
      fullDesc = firstLetterCase(fullDesc)
      */
  asyncComputed:{
    async texts(){
      let descHero = ''
      let descFamilies = ''
      let descIng = ''
      let descs = []
    
      // let descIntro
      // if(this.props.isRecomended) descIntro = this.$t('reco_desc_title')
      // else descIntro = this.$t('possible_reco_title')
      
      let famsAndSubs = await this.$store.getters.familiesWithSubsByPerfumes()
      let commonFams = famsAndSubs.filter((f)=>{
        return (this.data.family && f.family.id === this.data.family.id) || (this.data.secondaryFamily && f.family.id === this.data.secondaryFamily.id)
      })
      
      if(commonFams.length>0) {
        descFamilies = `${this.$t('it')} `+ this.$t('recommended_by_fam').replace('%FAMILY%',concatWithAnd(commonFams.map((f)=>f.family.name),this.$t('and')))
      } else {
        descFamilies = this.$t('not_recommended_by_fam')
      }
      
      
      let hero
      let profileHeroes = await this.$store.getters.getProfileHeroes()
      // let profileMains = Utils.getProfileMainIngredients(this.props.profile)
      let matchingHeroes = this.data.ingredientProportions.slice(0,5).filter((ip)=>profileHeroes.find((ph)=>ph.id===ip.ingredient.id)).map((ip)=>ip.ingredient)
      // ONLY TALK ABOUT HEROES if the ingredient is hero in the PROFILE!
      // if(this.data.heroIngredient && profileMains.findIndex((ph)=>ph.id===this.data.heroIngredient.id)>=0){
      //   hero = this.data.heroIngredient
      // } else if(matchingHeroes.length>0){
      //   hero = matchingHeroes[0]
      // }
      if(this.data.type==='hero' || (matchingHeroes.length>0 && commonFams.length>0)){
        hero = matchingHeroes[0]
      }
      
      if(hero) {
        descHero = this.$t('recommended_by_hero').replace('%HERO%',hero.name)
        descs.push(descHero)
      }
      
      let profileIngs = await this.$store.getters.profileIngredients()
      let commonIngs = this.data.ingredientProportions.filter((p)=>profileIngs.find((i)=>i.id === p.ingredient.id))
      if(hero) commonIngs = commonIngs.filter((i)=>i.ingredient.id !== hero.id)
      if(commonIngs.length > 0) {
        // descIng = this.$t('recommended_by_ings').replace('%INGS%',Utils.concatWithAnd(commonIngs.map((i)=>i.ingredient.name),this.$t('and')))
        descIng = this.$t('recommended_by_ings').replace('%INGS%','')
        descs.push(descIng)
      }
      
      // if(this.data.type==='expert') isExpert = true
      
      let fullDesc

      if(hero) {
        // if(commonIngs.length>0) fullDesc = descHero +`, ${this.$t('and')} ${this.$t('it')} `+descIng
        if(commonIngs.length>0) fullDesc = descHero +`. <br/> ${this.$t('furthermore')}, `+descIng
        else fullDesc = descHero +`.`
      } else if(this.data.type==='family') {
        if(descIng) fullDesc = descFamilies+`, ${this.$t('and')} `+descIng
        else fullDesc = descFamilies
      } else {//if(this.data.type==='ingredient' || this.data.type==='explicit_ingredient' || this.data.type==='expert' || this.data.type==='goodGuy'  || this.data.type==='hero'){
        // if (commonFams.length>0) {
        //   if(descIng) fullDesc =  `${this.$t('it')} ` + descIng //+`, ${this.$t('and')} ${this.$t('it')} `+descFamilies
        //   else fullDesc =  `${this.$t('it')} `+descFamilies
        // } else {
        //   if(descIng) fullDesc = `${this.$t('it')} `+ descIng //+`.<br/><br/>${descFamilies}`
        //   else fullDesc = descFamilies
        // }
        if (commonFams.length>0){
          if (descIng === '') {
            fullDesc =  `${descFamilies}.`
          }
          else {
            fullDesc =  `${descFamilies}, ${this.$t('and')} `+ descIng
          }
        } else {
          fullDesc = `${descFamilies} `+ descIng
        }
      }
      fullDesc = firstLetterCase(fullDesc || '')
      return {
        // intro: descIntro+':',
        content: fullDesc,
      }
      // return (
      //   <div className='reco-description'>
      //     {isExpert && <div className="expert-tag"><div className="expert-icon"/>{this.$t('expert_reco')}</div>}
      //     <div className='intro' dangerouslySetInnerHTML={innerHTML(descIntro+'...')}></div>
      //     <div className='text' dangerouslySetInnerHTML={innerHTML(fullDesc)}></div>

      //   </div>
      // )
    }
  }
}
</script>

<style>

</style>