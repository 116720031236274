import Vue from "vue";
import VueRouter from "vue-router";
import PerfumeSearch from "../views/PerfumeSearch.vue";

import IngredientSearchVue from "../views/IngredientSearch.vue";
import ConceptsSelectVue from "../views/ConceptsSelect.vue";
import ProfileVue from "../views/Profile.vue";
import ProfileEditVue from "../views/ProfileEdit.vue";
import RecommendationsVue from "../views/Recommendations.vue";
import PerfumeVue from "../views/Perfume.vue";
import WelcomeVue from "../views/Welcome.vue";
import Root from "../views/Root.vue";

import ModeSelectVue from "../views/ModeSelect.vue";
import { getInstalations, getLangs } from "../services/api";

import store from "../store";
import { allStrings } from "../i18n";

Vue.use(VueRouter);

export const RouteNames = {
  SearchPerfume: "PerfumeSearch",
  RecommendationsBySearch: "RecommendationsBySearch",
  ConceptsSelect: "ConceptsSelect",
  Recommendations: "Recommendations",
  Profile: "Profile",
  IngredientSearch: "IngredientSearch",
};

const routesChild = [
  {
    path: "welcome",
    alias: "",
    name: "Welcome",
    component: WelcomeVue,
  },
  {
    path: "mode-select",
    name: "ModeSelect",
    component: ModeSelectVue,
  },
  {
    path: "perfume-search",
    name: RouteNames.SearchPerfume,
    component: PerfumeSearch,
  },
  {
    path: "ingredient-search/:recommendation?",
    name: RouteNames.IngredientSearch,
    component: IngredientSearchVue,
    props: (route) => ({ isRecommendation: !!route.params.recommendation }),
  },
  {
    path: "recommendations/ingredients",
    redirect: "ingredient-search/recommendation",
    name: "RecommendationByIngredients",
  },
  {
    path: "concepts-select",
    name: RouteNames.ConceptsSelect,
    component: ConceptsSelectVue,
  },
  {
    path: "profile/:mode?",
    name: RouteNames.Profile,
    component: ProfileVue,
  },
  {
    path: "profile-edit",
    name: "ProfileEdit",
    component: ProfileEditVue,
  },
  {
    path: "recommendations-perfume",
    redirect: "recommendations/perfume",
    name: RouteNames.RecommendationsBySearch,
  },
  {
    path: "recommendations/:mode?",
    name: RouteNames.Recommendations,
    component: RecommendationsVue,
    props: true,
    beforeEnter(from, to, next) {
      if (
        from.params.mode === "perfume" &&
        store.getters.perfumesIds.length < 2 &&
        store.state.profile.concepts.length === 0
      )
        next({ name: RouteNames.ConceptsSelect });
      else next();
    },
  },
  {
    path: "perfume/:id",
    name: "Perfume",
    component: PerfumeVue,
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
];
var setted = false;

function getLangFromCountryCode(contryCode) {
  return Object.keys(allStrings).filter(
    (lang) => lang.split("-")[1] === contryCode
  );
}
const routes = [
  {
    path: "/:instalationId",
    name: "Root",
    children: routesChild,
    component: Root,
    beforeEnter: async (to, from, next) => {
      /** @type {string | undefined} */
      const instalationParam = to.params.instalationId;
      var installationId = instalationParam;
      // is is already setted, stop
      if (setted && !installationId) return;
      try {
        // DEFAULT CASE
        if (instalationParam === "default") {
          const installations = await getInstalations();
          const defaultSensor = installations.find(
            (inst) => !inst.installationName
          );
          if (!defaultSensor) {
            console.error("Default sensor not found");
            await store.dispatch("setInstalation", defaultSensor);
            return;
          } else {
            setted = true;
            await store.dispatch("setInstalation", defaultSensor);
            console.debug(
              "%c set default sensor",
              "color:green",
              "host id = ",
              defaultSensor.hostId
            );
            const langContry =
              getLangFromCountryCode(defaultSensor.countryCode)[0] ||
              process.env.VUE_APP_FALLBACK_LANG;
            const [lang] = langContry.split("-");
            store.dispatch("setLanguage", langContry);
            store.dispatch("setApiLanguage", lang);

            return next();
          }
        }
        const installationIncludedLang = installationId?.includes("-");
        var instalation = null;
        // CASE 1:  instalation has a format en-US.
        const [lang, countryCode] = installationId.split("-");
        if (installationIncludedLang) {
          // in this case we find with countryCode
          const installations = await getInstalations();
          console.debug("INSTALLATIONS", installations);
          instalation = installations.find(
            (inst) => inst.installationName == countryCode || inst.countryCode == countryCode
          );
          console.debug("find by country code", countryCode, instalation);

          // CASE 2: instalation is a normal string like brno1
        } else {
          console.error(
            "installation name doesnt have the format lang-countryCode"
          );
        }
        setted = true;
        if (instalation) {
          const hostId = instalation.hostId;
          await store.dispatch("setInstalation", instalation);
          console.debug(
            "%c instalation id founded",
            "color:green",
            instalation.installationName,
            "; Country code =",
            instalation.countryCode,
            "; Host id =",
            hostId
          );
          const langs = await getLangs();
          if (installationIncludedLang) {
            if (langs.map((e) => e.id).includes(instalationParam)) {
              store.dispatch("setApiLanguage", instalationParam);
            } else {
              store.dispatch("setApiLanguage", lang);
            }
            if (Object.keys(allStrings).includes(instalationParam))
              store.dispatch("setLanguage", instalationParam);
            else if (Object.keys(allStrings).includes(lang))
              store.dispatch("setLanguage", lang);
            else
              store.dispatch("setLanguage", process.env.VUE_APP_FALLBACK_LANG);
          }
          next();
        } else {
          next("/default");
        }
      } catch (err) {
        console.warn("instalation endpoint error", err);
        next();
      }
    },
  },
  {
    path: "/",
    redirect: "/default",
  },
];

const router = new VueRouter({
  routes,
});

// router.beforeEach((to, from, next) => {
//   if (to.name == 'Recommendations') {
//     next({ name: 'ConceptsSelect' });
//   } else {
//     next();
//   }
// });

export default router;
