<template>
  <div class="view concepts-select">
    <div class="back-button" @click="$router.go(-1)">
      <div class="back-icon"></div>
      <div class="back-text">{{ $t("back") }}</div>
    </div>
    <!-- <div class="top current-perfumes">
      <div class="back-button" @click="$router.go(-1)">
        <div class="back-icon"></div>
        <div class="back-text">{{$t("back")}}</div>
      </div>
      <div class="head">
        <div class="title">{{$t("selected_perfumes")}}</div>
        <router-link class="more-fragrances" to="perfume-search">{{$t("add_more_fragrances")}}</router-link>
      </div>
      <div class="list-container">
        <div class="list">
          <profile-edit-item v-for="perf in currentPerfumes" :data="perf" :key="perf.id" type="perfume"></profile-edit-item>
        </div>
      </div>
    </div> -->
    <div class="bottom concepts-grid">
      <div class="title">{{ $t("select_concepts") }}</div>
      <div class="subtitle">{{ $t("select_concepts_subtitle") }}</div>
      <div class="concepts-container">
        <div class="concept-pairs" v-if="currentConcepts">
          <div class="pair" v-for="pair in conceptPairs" :key="pair[0].id">
            <div
              class="concept"
              :class="{
                active: currentConcepts.find((d) => d.id === pair[0].id),
              }"
              @click="toggleConcept(pair[0])"
            >
              {{ $t(pair[0].name) }}
            </div>
            <!-- <div class="or">{{$t('or')}}</div> -->
            <concept-toggle
              :value="
                currentConcepts.find((d) => d.id === pair[1].id)
                  ? -1
                  : currentConcepts.find((d) => d.id === pair[0].id)
                  ? 1
                  : 0
              "
              @click="(dir) => togglePair(pair, dir)"
            ></concept-toggle>
            <div
              class="concept"
              :class="{
                active: currentConcepts.find((d) => d.id === pair[1].id),
              }"
              @click="toggleConcept(pair[1])"
            >
              {{ $t(pair[1].name) }}
            </div>
          </div>
        </div>
        <!-- <router-link class="button" :class="{disabled: !$store.getters.canShowProfile()}" to="/profile">{{$t('next_step')}}</router-link> -->
        <span @click="() => handleClickNext()">
          <router-link
            class="button"
            :class="{
              disabled: !currentConcepts || currentConcepts.length < 1,
            }"
            :to="{
              name: 'RecommendationsBySearch',
              query: this.$route.query.restricted
                ? { restricted: this.$route.query.restricted }
                : {},
            }"
            replace
            >{{ $t("get_recomendations") }}</router-link
          >
        </span>
      </div>
    </div>
  </div>
</template>

<script>
// import ProfileEditItem from '../components/ProfileEditItem.vue'
import ConceptToggle from "../components/ConceptToggle.vue";
import { getConcept } from "../services/api";
import { brTracker } from "../services/tracker";
export default {
  components: {
    // ProfileEditItem,
    ConceptToggle,
  },
  data() {
    return {
      concepts: [...this.$store.state.profile.concepts],
    };
  },
  mounted() {
    brTracker.pageVisit(brTracker.TypePageVisitedEvent.VIEW_CONCEPTS);
    this.eventTracker.emitEvent("VIEW_OPENED", { type: "SET_CONCEPTS" });
  },
  computed: {
    conceptPairs() {
      return [
        [getConcept(15), getConcept(2)],
        [getConcept(31), getConcept(30)],
        [getConcept("sure_bet"), getConcept("novelty")],
      ];
    },
  },
  asyncComputed: {
    async currentPerfumes() {
      // return this.$store.state.profile.perfumes
      return await this.$store.getters.perfumes();
    },
    async currentConcepts() {
      return await this.$store.getters.concepts();
    },
  },
  methods: {
    handleClickNext() {
      const currentConcepts = this.$store.state.profile.concepts;
      const newConcepts = currentConcepts.filter(
        (concept) =>
          !this.concepts.find((lastConcept) => lastConcept === concept)
      );
      const removedConcepts = this.concepts.filter(
        (lastConcept) =>
          !currentConcepts.find((concept) => lastConcept === concept)
      );
      if (newConcepts.length)
        brTracker.addConcept(newConcepts.map((concept) => getConcept(concept)));
      if (removedConcepts.length)
        brTracker.removeConcept(
          removedConcepts.map((concept) => getConcept(concept))
        );
      console.log("On Next", newConcepts, removedConcepts);
    },
    toggleConcept(concept) {
      if (this.currentConcepts.find((c) => c.id == concept.id)) {
        this.$store.dispatch("removeConcept", concept);
      } else {
        let pair = this.conceptPairs.find(
          (p) => p[0].id === concept.id || p[1].id === concept.id
        );
        if (pair)
          this.$store.dispatch(
            "removeConcept",
            pair.find((c) => c.id !== concept.id)
          );
        this.$store.dispatch("addConcept", concept);
      }
    },
    togglePair(pair, dir) {
      if (!dir)
        dir = this.currentConcepts.find((c) => c.id == pair[0].id) ? 1 : -1;
      if (dir === 1) {
        this.toggleConcept(pair[1]);
      } else {
        this.toggleConcept(pair[0]);
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.concepts-select
  padding: vw(30px) vw(30px) vh(20px)

  .mobile &
    padding: vw(30px) vw(20px) vw(20px)

  .back-button
    align-items: center
    cursor: pointer
    display: flex
    left: vw(30px)
    position: absolute
    top: vh(40px)

  .back-icon
    m-icon("arrow-left", 14)
    margin-right: vw(5px)

  .back-text
    m-font-size(14, 20)
    font-weight: $fw-medium
    margin-top: 0.1em
    text-transform: uppercase

  .top
    padding-top: vh(60px)

    .head
      align-items: center
      display: flex
      margin-bottom: vh(10px)

      .title
        font-weight: $fw-bold
        m-font-size(16, 20)
        margin-right: vw(20px)

      .more-fragrances
        m-font-size(14, 20)
        text-decoration: underline

    .list-container
      border-bottom: 1px solid #707070
      margin-bottom: vw(30px)
      padding-bottom: vw(20px)

    .list
      display: flex
      flex-wrap: nowrap
      overflow-x: auto
      overflow-y: hidden

      .profile-edit-item
        flex: 0 0 vw(350px)
        height: vh(90px)
        margin-bottom: 0
        overflow: hidden

        &:not(:last-child)
          margin-right: vw(10px)

        >>> .info
          overflow: hidden

          .name
            m-ellipsis(100%)

        >>> .trash-button
          margin-left: vw(20px)

  .bottom
    left: 50%
    position: absolute
    text-align: center
    top: 50%
    transform: translate(-50%, -50%)
    width: 50%

    .mobile &
      width: 90%

    .title
      font-weight: $fw-bold
      m-font-size(20, 24)
      margin-bottom: vh(20px)

    .subtitle
      color: $manatee
      margin-bottom: vh(40px)
      m-font-size(14, 18)

      .mobile &
        m-font-size(16, 20)

    .concepts-container
      display: flex
      flex-direction: column

    .concept-pairs
      display: flex
      flex: 1 1 0%
      flex-direction: column
      margin-bottom: vh(40px)

      .pair
        align-items: center
        display: flex
        gap: vw(10px)
        overflow: hidden
        padding-bottom: vh(20px)
        padding-top: vh(20px)

        .mobile &
          padding-bottom: vw(20px)
          padding-top: vw(20px)

        &:not(:last-child)
          border-bottom: 1px solid #000

        .concept
          align-items: center
          border-radius: 4px
          cursor: pointer
          display: flex
          flex: 1 1 0%
          font-weight: $fw-bold
          justify-content: center
          m-font-size(24, 30)
          opacity: 0.25

          &:hover
            opacity: 0.5

          &.active
            opacity: 1

    .button
      align-self: center
      background-color: #CA2B69
      border-color: #CA2B69
</style>