<template>
  <DynamicScroller
    :items="items"
    key-field="id"
    sizeField="size"
    v-slot="{ item, index, active }"
    :min-item-size="50"
    :itemClass="'type'"
    @resize="handleResize"
    @scroll-end="$emit('scroll-end', $event)"
    ref="scroller"
  >
    
    <DynamicScrollerItem
      :item="item"
      :active="active"
      :size-dependencies="[item.name, item.type]"
      :data-index="item.id"
      :class="item.type"
    >
      <template v-if="index === 0" name="after">
        <slot name="header"> </slot>
      </template>
      
      <div class="title" v-if="item.tag === 'title'">{{ item.name }}</div>
      <div class="sub-group-title" v-else-if="item.tag === 'subtitle'">
        {{ item.name }}
      </div>
      <div class="space" v-else-if="item.tag === 'space'"></div>
      <div class="perfumes-container" v-else>
        <div
          class="wrap-perfume-item"
          v-for="perfume in item.array"
          :key="`bs-${perfume.id}`"
        >
          <perfume-item
            :data="perfume"
            @click="$emit('click', perfume)"
            :class="item.tag"
          ></perfume-item>
        </div>
      </div>
      <template v-if="index === items.length-1" name="before">
        <slot name="footer"> </slot>
      </template>
    </DynamicScrollerItem>
  </DynamicScroller>
</template>
<script>
import PerfumeItem from "../PerfumeItem.vue";
import { DynamicScroller, DynamicScrollerItem } from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
// const SIZE = 40;

export default {
  components: {
    PerfumeItem,
    DynamicScroller,
    DynamicScrollerItem,
  },
  props: {
    showBestSeller: { type: Boolean, default: false },
    data: Object,
    title: String,
  },
  data() {
    return {
      width: 0,
    };
  },
  created(){
    this.id = 0
  },
  mounted() {
    this.handleResize();
    
  },
  computed: {
    items() {
      return Object.entries(this.data || {}).reduce(
        (array, [title, perfumes]) => {
          const start = this.addTittle(title, "subtitle");
          return array.concat(start.concat(this.getItems(perfumes)));
        },
        this.addTittle(this.title, "title",this.isMobile)
      );
    },
  },
  methods: {
    group(tag, array) {
      if (this.isMobile)
        return array.map((perfume) => ({
          tag,
          id: perfume.id,
          array: [perfume],
        }));
      return array.reduce((result, perfume, index, arr) => {
        if (index % 2 === 0) {
          const perf = [perfume, arr[index + 1]].filter((p) => p);
          result.push({
            id: perf.map((p) => p.id).join("-"),
            tag,
            array: perf,
          });
        }
        return result;
      }, []);
    },
    handleResize() {
      this.width = this.$refs.scroller.$refs.scroller.$refs.wrapper.clientWidth;
    },
    addTittle(title, type,isFirst = false) {
      if (!title) return isFirst ? [{id:'space-first',tag:"space", name:""}] :[];
      else return [{ id: title, name: title, tag: type }];
    },
    getItems(perfumes) {
      var bestSellers = this.group("best-seller", this.bestSellers(perfumes));

      var items =
        bestSellers.length > 0
          ? this.addTittle(this.$t("best_sellers"), "title").concat(bestSellers)
          : [];
      // Concat title if there are bst sellers
      if (items.length)
        items = items.concat(this.addTittle(this.$t("fragrances"), "title"));

      var nonBestSellers = this.group(
        "non-best-sellers",
        this.nonBestSellers(perfumes)
      );

      items = items.concat(nonBestSellers);
      return items;
    },
    bestSellers(perfumes) {
      if (this.showBestSeller && perfumes)
        return perfumes
          .filter(
            (r) => r.searchPinToTop !== undefined && r.searchPinToTop !== null
          )
          .sort((a, b) => a.searchPinToTop - b.searchPinToTop);
      else return [];
    },
    nonBestSellers(perfumes) {
      if (this.showBestSeller && perfumes) {
        const bestSellers = this.bestSellers(perfumes);
        return perfumes.filter(
          (r) => !bestSellers?.find((bs) => bs.id === r.id)
        );
      } else return perfumes || [];
    },
  },
};
</script>
<style lang="stylus">
.result
  .best-sellers
    margin-bottom: vh(20px)

    .best-sellers-title
      m-font-size(16, 20)
      font-weight: bold
      margin-bottom: vh(10px)

    .best-sellers-content
      border-bottom: 2px solid black
      border-top: 2px solid black
      padding: 0

    .best-sellers-results-list
      display: flex
      flex-wrap: wrap

    .perfume-item
      $gap = vw(10px)
      $percentage = percentage((1 / 2))
      $width = "calc(%s - %s)" % ($percentage $gap)
      flex: 0 0 $width
      padding: vh(30px) vw(20px) vh(20px)
      width: $width

      &:nth-child(odd)
        margin-right: $gap

      &:nth-child(even)
        margin-left: $gap

      @media (orientation: portrait)
        .tablet &
          flex: 0 0 100%
          margin: 0
          padding: vw(20px)

      .mobile &
        flex: 0 0 100%
        margin: 0
        padding: vw(20px)

  .results-list
    column-gap: vw(70px)
    display: grid
    grid-template-columns: 1fr 1fr
    row-gap: vw(10px)

.perfumes-container
  display: flex

.wrap-perfume-item
  flex: 0 0 40%
  margin-bottom: 2px
  margin-left: 40px
  margin-right: 40px
  margin-top: 2px
  overflow: hidden
  padding: vw(20px) vw(10px)

  .tablet &
    flex: 1 1 30%
    margin-left: 20px
    margin-right: 20px

  .mobile &
    flex: 1 1 0%
    margin: 0
    padding-left: 0
    padding-right: 0

  &:hover
    border-radius: vw(10px)
    cursor: pointer

    .desktop &
      outline: 1px solid #000

  .perfume-item
    .image
      height: vw(90px)
      width: vw(90px)
</style>