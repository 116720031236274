import { v1 as uuidv1 } from 'uuid'
import { eventTracker } from '@/services/tracker'
import { brTracker } from '../../../services/tracker'

export default {
  state: {
    gender: 'FU',
    id: localStorage.getItem('notino_local_id') || uuidv1(),
    loggedUser: null
  },
  mutations: {
    setGender (state, gender) {
      state.gender = gender
    },
    setUser (state, user) {
      state.loggedUser = user
    }
  },
  actions: {
    setUser ({ commit }, user) {
      commit('setUser', user)
    },
    setGender ({ commit, dispatch, state }, gender) {
      if (gender != state.gender) dispatch('setNeedsNewRecos')
      eventTracker.emitEvent('GENDER_CHANGED', { gender })
      brTracker.setGender(gender)
      commit('setGender', gender)
    }
  },
  getters: {
    loggedUser: state => state.loggedUser
  }
}
