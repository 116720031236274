<template>
  <div class="perfume-card" @click="onClick">
    <div class="image" :style="{ backgroundImage: `url(${bestImage})` }">
      <div class="tags">
        <div v-if="isNovelty" class="tag tag-novelty">{{ $t("novelty") }}</div>
        <div v-if="showAction" class="tag tag-action">{{ $t("action") }}</div>
        <div v-if="showFreeDel" class="tag free_del">{{ $t("free_del") }}</div>
        <!-- <div v-if="showNew" class="tag new">{{ $t("new_in") }}</div> -->
      </div>
    </div>
    <div class="info">
      <div class="brand">{{ data.brand.name }}</div>
      <!-- <div class="name">{{data.name}}</div> -->
      <v-clamp class="name" :max-lines="2">{{ data.name }}</v-clamp>
    </div>
    <div class="price">{{ lowestPrice ? `${$t("from")} ${price}` : "" }}</div>
    <!-- <div class="action">{{$t('view_details')}}</div> -->
    <families-with-icon
      :family="data.family"
      :subfamily="data.secondaryFamily"
    ></families-with-icon>
    <div
      v-if="showRemove"
      class="trash-button"
      @click="removeFromProfile"
    ></div>
  </div>
</template>

<script>
import FamiliesWithIcon from "./Legacy/FamiliesWithIcon.vue";
import VClamp from "vue-clamp";
import { mapGetters } from "vuex";
import { formatPrice } from "@/utils/textUtils";
export default {
  components: {
    FamiliesWithIcon,
    VClamp,
  },
  props: ["data", "showRemove"],
  methods: {
    onClick() {
      this.$emit("click", this.data.id);
    },
    removeFromProfile() {
      // this.$emit('remove')
      this.$store.dispatch("removePerfume", this.data);
    },
  },
  computed: {
    ...mapGetters(["installation"]),
    price() {
      if (!this.installation?.countryCode) return this.lowestPrice;
      return formatPrice(this.lowestPrice, this.installation?.countryCode);
    },
    bestEan() {
      if (!this.data || !this.data.eanData) return null;
      let bestEan = Object.values(this.data.eanData).find((d) => d.isMaster);
      if (!bestEan) bestEan = Object.values(this.data.eanData)[0];
      return bestEan;
    },
    lowestPrice() {
      if (!this.data || !this.data.eanData) return null;
      return Object.values(this.data.eanData).reduce((acc, curr) => {
        return acc === null
          ? curr.price
          : curr.price === null
          ? acc
          : acc > curr.price
          ? curr.price
          : acc;
      }, null);
    },
    bestImage() {
      return this.bestEan && this.bestEan.imageUrl
        ? this.bestEan.imageUrl
        : this.data.imageUrl;
    },
    isNovelty() {
      return Object.values(this.data.eanData).some(
        ({ isNovelty }) => isNovelty
      );
    },
    showAction() {
      return this.bestEan ? this.bestEan.isAction : false;
    },
    showFreeDel() {
      return this.bestEan ? this.bestEan.isFreeDelivery : false;
    },
    productPrice() {
      return this.bestEan && this.bestEan.availability > 0
        ? this.bestEan.price
        : null;
    },
  },
};
</script>

<style lang="stylus" scoped>
.perfume-card
  align-items: center
  background-color: #fff
  cursor: pointer
  display: flex
  flex-direction: column
  overflow: hidden
  position: relative

  &:hover
    .name,
    .brand
      text-decoration: underline

  .family-and-icon
    margin-bottom: vw(10px)
    margin-left: auto
    margin-right: auto
    max-width: 100%

  .image
    align-items: flex-end
    background-position: center
    background-repeat: no-repeat
    background-size: contain
    display: flex
    height: vw(180px)
    justify-content: center
    margin-bottom: vw(10px)
    margin-left: vw(20px)
    margin-right: vw(20px)
    width: vw(180px)

    .mobile &
      height: vw(120px)
      width: vw(120px)

  .tags
    align-items: center
    display: flex
    flex-direction: column
    gap: vw(2px)

    .tag
      m-font-size(12, 14)
      background-color: #000
      color: #fff
      margin-right: 1px
      padding: 0.3em
      padding-top: 0.45em
      text-transform: uppercase

      &.tag-action
        background-color: $notino-pink

  .info
    m-font-size(14, 20)
    align-items: center
    color: $tundora
    display: flex
    flex-direction: column
    overflow: hidden
    text-align: center
    width: 75%

    .tablet &
      width: 90%

    .brand
      m-ellipsis(100%)
      // margin-bottom: vw(5px)

    .name
      m-font-size(14, 16)
      font-weight: $fw-bold
      height: vw(32px)
      margin-bottom: vw(5px)

      @media (orientation: portrait)
        .tablet &
          height: vw(55px)

      .mobile &
        height: vw(30px)

  .price
    m-font-size(16, 20)
    font-weight: $fw-bold

  .action
    m-font-size(14, 20)
    color: $tundora
    cursor: pointer
    margin: vh(30px) 0 vh(15px)
    text-decoration: underline

    .mobile &
      margin-bottom: vw(20px)

  .trash-button
    m-icon("trash", 16)
    cursor: pointer
    position: absolute
    right: vw(5px)
    top: vw(5px)
</style>