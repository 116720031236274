export default {
welcome_to_profiler: "<b>Notino Fragrance Finder</b>",
what_are_you_looking_for: "Позволете ни да ви препоръчаме нов аромат, който ще пасне като ръкавица на вас и близките ви.<br>За кого търсите идеалния аромат?",
build_your_profile_and: "Позволете ни да ви препоръчаме нов аромат, който ще пасне като ръкавица на вас и близките ви.<br>За кого търсите идеалния аромат?",
search_by: "Ще ви препоръчаме парфюми по вашите критерии",
gender_m: "За мъж",
gender_f: "За жена",
mode_perfumes: "Любими парфюми",
mode_ingredients: "Съставки",
back: "Назад",
novelty: "Ново",
go_back_to_finder: "Връщане към Fragrance Finder",
done: "Готово",
best_sellers: "Бестселър",
from: "от",
search_your_perfume: "<b>Изберете<b> до 5 аромата, които харесвате вие, или този, за когото избирате парфюм.",
search_your_perfume_flat: "Започнете да изписвате харесвания парфюм или марка",
select_your_perfume: "<b>Изберете</b> любимия си аромат.",
no_perfumes_found: "Не бяха намерени никакви аромати :(",
no_ingredients_found: "Не бяха намерени никакви съставки :(",
something_went_wrong: "За съжаление не намерихме никакъв резултат. Опитайте с ново наименование",
select_fav_ings: "<b>Изберете</b> любимите си съставки и ние ще ви помогнем да намерите правилния парфюм.",
search_fav_ings: "Тук въведете наименованието на любимата си съставка.",
building_your_profile: "<b>Създаваме</b> вашия<br>парфюмен профил",
edit_your_profile: "Корекция на профил",
fragrances: "Аромати",
ingredients: "Съставки",
concepts: "Концепции",
next_step: "Готово",
what_gender: "За кого е предназначен този профил?",
selected_ingredients: "Любимите ви съставки",
selected_perfumes: "Любимите ви аромати",
no_perfumes_yet: "Във вашия профил няма никакви аромати",
no_ingredients_yet: "Във вашия профил няма никакви съставки",
no_conceptss_yet: "Във вашия профил няма никакви концепции",
get_recomendations: "Получаване на препоръки",
this_are_your_ings: "Това са любимите ви съставки",
your_profile_to_edit: "Вашият профил",
add_more_fragrances: "Добави други аромати",
add_more_ingredients: "Добави други съставки",
add_more_concepts: "Добави други концепции",
top_6_recos: "Аромати, които ви отиват",
recos_based_on: "Тази препоръка се основава на вашия парфюмен профил.",
rest_of_recos: "Друга препоръка",
view_details: "Показване на детайли",
your_profile_is_empty: "Профилът ви е празен!",
add_to_profile_to_get_recos: "Добавете в профила си аромати или съставки и получете препоръки",
price: "цена",
buy_it_now: "Научете повече",
prominent_ings: "Най-изразителните съставки",
all_ings: "Пълен списък на съставките",
why_do_we_recommend: "Защо препоръчваме <b>този аромат</b>?",
other_ings_present: "Също като другите, по-малко изразителни",
description: "Описание",
evaluate_this_reco: "Оценете тази препоръка",
how_relevant_do_you: "Доколко релевантна е според вас <b>тази препоръка</b>?",
submit: "Изпращане",
check_your_new_recommendations_1: "<b>Профилът е актуализиран.</b> Благодаря!",
check_your_new_recommendations_2: "Имате нови препоръки.",
view_new_recommendations: "Показване на новите препоръки",
close: "затваряне",
loading: "Зареждане",

//Profile_desc,
you_love_perfumes_of_the: "<b>обичате парфюмите</b><br>",
you_love_perfumes_of_the_plural: "<b>обичате парфюмите</b><br>",
and: "и",
desc_family: "семейството",
desc_families: "семействата",
you_also_love_plural: "обичате и подгрупата",
you_also_love: "обичате и подгрупите",
desc_sub_family: "подгрупа",
desc_sub_families: "подгрупи",

//RECOS:,
it: "това",
also: "също",
$pronoun_self: "вие",
$possessive_singular_self: "ваш",
$possessive_refl_singular_self: "мой",
$possessive_plural_self: "ваш",
$possessive_refl_plural_self: "мой",
$possessive_singular_prep_self: "ваш",
$possessive_singular_liste_self: "ваш",
$you_self: "вие",
$you_prep_self: "вие",
$do_you_have_self: "имате",
$that_you_love_self: "които харесвате",
$you_love_self: "харесвате",
$you_adore_self: "харесвате",
$apreciate_self: "харесва ви",
$you_have_self: "имате",
reco_desc_title: "Препоръчваме този парфюм, защото",
possible_reco_title: "Може би $you_love този парфюм, защото",
recommended_by_hero: "$you_adore основните му съставки: <b>%HERO%</b>",
recommended_by_fam: "е част от едно от $possessive_plural любими парфюмни семейства, <b>%FAMILY%</b>",
not_recommended_by_fam: "Макар да има различен профил от $possessive_singular_prep, $you_prep трябва да го пробвате",
recommended_by_ings: "има някои от $possessive_plural любими съставки: ",
furthermore: "Още",
home: "Нова препоръка",
my_profile: "Моят профил",
no_concepts_yet: "Във вашия профил няма никакви концепции",
view_more: "Показване на повече",
select_fav_ingredients_to_find_perfumes: "Изберете любимите си <b>съставки</b><br> и намерете своите<br> любими <b>аромати</b>",
your_prefered_concepts: "Любимите ви характеристики",
concept_fresh: "Свежи (напр. цитрусови)",
concept_intense: "Интензивни (напр. дървесни)",
concept_casual: "За ежедневно носене (напр. на работа)",
concept_special_occasion: "За специален случай (напр. за среща)",
concept_sure_bet: "Сигурен залог",
concept_novelty: "Модерни (напр. нови)",
change_concepts: "Промяна на избраните характеристики",
select_concepts: "Почти сме готови",
add_concepts: "Добавяне на други любими характеристики",
or: "или",
explore_by: "Открийте по",
brands: "Марка",
your_selection: "Вашият избор",
list_view: "Списък с любимите аромати",
graphic_view: "Вашият парфюмен профил",
recos_based_on_selection: "Препоръки според вашия избор",
add_to_profile: "Добавяне в моя профил",
do_you_want_to_reset: "Искате ли да настроите отново профила си?",
start_new: "Започване отново",
keep_perfumes: "Запазване на парфюмите",
click_to_view: "Преглед чрез кликване",
already_in_profile: "Вече е добавено",
only_showing_that_combine_with: "Ще се покажат само съставки, комбинирани с",
see_all_ings_in_this_fam: "Показване на всички съставки от това семейство",
select_concepts_subtitle: "Ако искате да получите препоръка, трябва да изберете поне 2 аромата (кликнете назад) или да добавите поне една от следните характеристики на парфюма.",
action: "промоции",
free_del: "безплатна доставка",
new_in: "нови",
cancel: "отмяна",
see_more: "Вижте повече",
see_less: "Вижте по-малко",
recommendation_by: "Препоръки с тези съставки",
apply: "Приложи",
modal_limit_ingredients_title: "Максимум съставки",
modal_limit_ingredients_text: "Стигнахте до лимита за избор на съставки (4). Моля, премахнете една съставка, преди да добавите друга.",
empty_selection: "Не сте избрали нищо",
empty_selection_text: "Изберете един или повече парфюми за препоръка",
search_perfume_subtitle: "Чудесно! Вече имате достъп до персонализираните препоръки.",
perfume_search_subtitle: "Чудесно! Вече имате достъп до персонализираните препоръки.",
filter_by_brand: "Филтър по марка",
perfume_list: "Изберете аромат",
others: "",
some_error: "Хмм! Изглежда, че има грешка в уебсайта. Опитваме се да я отстраним възможно най-бързо. Благодарим ви за търпението!",
no_brands_found: "Не са открити марки :("
}