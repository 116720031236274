import EventTracker from '@bestiario/puig-event-tracker/browser'
export * as brTracker from './bloomreach'
export const eventTracker = new EventTracker({
  appId: process.env.VUE_APP_ID,
  apiURL: process.env.VUE_APP_API_URL
})

export const EventTrackerPlugin = {
  install (Vue) {
    Vue.prototype.eventTracker = eventTracker
  }
}
