<template>
  <div class="slider">
    <div class="wrapper-input" @click="focusInput('left')">
      <div class="price" v-if="!leftInput">{{ formatPrice(minValue) }}</div>
      <input
        v-else
        ref="left"
        class="input-number"
        type="number"
        :step="step"
        :value="minValue"
        @blur="leftInput = false"
        @change="({ target }) => updateMin(parseFloat(target.value))"
      />
    </div>
    <div class="custom-slider minmax">
      <div class="range-slider" ref="slider">
        <span class="range-selected"></span>
      </div>
      <div class="range-input">
        <input
          type="range"
          name="min"
          id="min"
          :min="min"
          :max="max"
          :value="rangeMinValue"
          :step="step"
          @input="({ target }) => setMin(parseFloat(target.value))"
        />
        <input
          type="range"
          name="max"
          id="max"
          :min="min"
          :max="max"
          :value="rangeMaxValue"
          :step="step"
          @input="({ target }) => setMax(parseFloat(target.value))"
        />
      </div>
    </div>
    <div class="wrapper-input" @click="focusInput('right')">
      <div v-if="!rightInput" class="price">{{ formatPrice(maxValue) }}</div>
      <input
        v-else
        ref="right"
        class="input-number"
        type="number"
        @blur="rightInput = false"
        :step="step"
        :value="maxValue"
        @change="({ target }) => updateMax(parseFloat(target.value))"
      />
    </div>
  </div>
</template>
  
  
<script >
import { mapGetters } from "vuex";
import { formatPrice } from "../utils/textUtils";
export default {
  props: {
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    step: {
      type: Number,
      default: 0.01,
    },
    minValue: {
      type: Number,
      default: 50,
    },
    maxValue: {
      type: Number,
      default: 80,
    },
  },
  data() {
    return {
      leftInput: false,
      rightInput: false,
      invert: false,
      // sliderMinValue: this.minValue ?? this.min,
      // sliderMaxValue: this.maxValue ?? this.max
    };
  },
  methods: {
    focusInput(type) {
      if (type === "left") this.leftInput = true;
      else this.rightInput = true;
      this.$nextTick(() => this.$refs[type]?.focus());
    },
    formatPrice(price) {
      const countryCode = this.installation?.countryCode;
      return formatPrice(price, countryCode);
    },
    updateMin(value) {
      this.$emit(
        "update:minValue",
        Math.min(this.maxValue, Math.max(this.min, value))
      );
    },
    updateMax(value) {
      this.$emit(
        "update:maxValue",
        Math.max(this.minValue, Math.min(this.max, value))
      );
    },
    setMin(value) {
      if (value > this.rangeMaxValue) {
        this.invert = true;
        this.updateMax(value);
      } else {
        this.invert = false;
        this.updateMin(value);
      }
    },
    setMax(value) {
      if (value < this.rangeMinValue) {
        this.invert = true;
        this.updateMin(value);
      } else {
        this.invert = false;
        this.updateMax(value);
      }
    },
    getPercent(value, min, max) {
      return ((value - min) / (max - min)) * 100;
    },
    setCSSProps(width, left, right) {
      this.$refs.slider.style.setProperty("--width", `${width}%`);
      this.$refs.slider.style.setProperty("--progressLeft", `${left}%`);
      this.$refs.slider.style.setProperty("--progressRight", `${right}%`);
    },
    update() {
      if (this.$refs.slider) {
        // emit slidet values when updated
        // this.$emit("update:minValue", this.sliderMinValue);
        // this.$emit("update:maxValue", this.sliderMaxValue);
        // calculate values in percentages
        const differencePercent = this.getPercent(
          this.sliderDifference,
          this.min,
          this.max
        );
        const leftPercent = this.getPercent(this.minValue, this.min, this.max);
        const rightPercent =
          100 - this.getPercent(this.maxValue, this.min, this.max);
        // set the CSS variables
        this.setCSSProps(differencePercent, leftPercent, rightPercent);
      }
    },
  },
  mounted() {
    this.update();
  },
  computed: {
    rangeMinValue() {
      return !this.invert ? this.minValue : this.maxValue;
    },
    rangeMaxValue() {
      return !this.invert ? this.maxValue : this.minValue;
    },
    ...mapGetters(["installation"]),
    sliderDifference() {
      return Math.abs(this.maxValue - this.minValue);
    },
  },
  watch: {
    minValue() {},
    ...["minValue", "maxValue", "min", "max", "sliderDifference"].reduce(
      (obj, key) => {
        obj[key] = function () {
          this.update();
        };
        return obj;
      },
      {}
    ),
  },
};
</script>
// <style lang="stylus">
.slider
  align-items: center
  display: flex
  gap: vw(15px)
  width: 100%

.custom-slider
  flex: 1 1 0%

.wrapper-input
  background-color: #fafafa
  border: 1px solid #bdbdbd
  border-radius: 2px
  color: #828282
  padding: vw(6px) vw(8px)
  width: vw(120px)

  .mobile &
    padding-left: 0
    padding-right: 0
    width: vw(80px)

  > div
    margin: auto
    text-align: center

  input
    border: none
    outline: none
    width: 100%

.range-slider
  background-color: #ddd
  border-radius: 2px
  height: 5px
  position: relative

.range-selected
  background-color: #000
  border-radius: 5px
  height: 100%
  left: var(--progressLeft)
  position: absolute
  right: var(--progressRight)

.range-input
  position: relative

.range-input input
  background: none
  height: 5px
  pointer-events: none
  position: absolute
  top: -7px
  width: 100%
  -moz-appearance: none
  -webkit-appearance: none

.range-input input::-webkit-slider-thumb
  background-color: #fff
  border: 3px solid #000
  border-radius: 50%
  height: 20px
  pointer-events: auto
  width: 20px
  -webkit-appearance: none

.range-input input::-moz-range-thumb
  background-color: #fff
  border: 3px solid #000
  border-radius: 50%
  height: 15px
  pointer-events: auto
  width: 15px
  -moz-appearance: none
</style>
  