var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"recommendation-by-ingredients"},[(
      _vm.$asyncComputed.ingredientCombinations.updating ||
      !_vm.ingredientCombinationsFiltered ||
      _vm.loading
    )?_c('loading'):_vm._e(),(_vm.ingredientCombinationsFiltered)?_c('div',{staticClass:"container"},[(_vm.isMobile)?_c('back'):_vm._e(),_c('div',{staticClass:"slider-container"},[_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t("price")))]),_c('slider-range',{attrs:{"min":_vm.minPrice,"max":_vm.maxPrice,"minValue":_vm.minValue,"maxValue":_vm.maxValue,"step":1},on:{"update:minValue":function($event){_vm.minValue=$event},"update:min-value":function($event){_vm.minValue=$event},"update:maxValue":function($event){_vm.maxValue=$event},"update:max-value":function($event){_vm.maxValue=$event}}}),_c('div',{staticClass:"button slider-btn",class:{
          disabled:
            _vm.minValue === _vm.filterMinPrice && _vm.maxValue === _vm.filterMaxPrice,
        },on:{"click":_vm.applyFilter}},[_vm._v(" "+_vm._s(_vm.$t("apply"))+" ")])],1),_vm._l((_vm.ingredientCombinationsFiltered),function(group,index){return _c('div',{key:index,staticClass:"section"},[_c('div',{staticClass:"header-reco"},[_c('div',{staticClass:"header-title"},[_vm._v(_vm._s(_vm.$t("recommendation_by"))+":")]),_c('div',{staticClass:"header-ingredients"},_vm._l((group.ingredients),function(ing){return _c('ingredient-item',{key:ing.id,attrs:{"data":ing}})}),1)]),_c('div',{staticClass:"perfumes"},_vm._l((group.perfumes),function(perfume){return _c('perfume-card',{key:perfume.id,attrs:{"data":perfume},on:{"click":function($event){return _vm.onPerfumeClick(perfume)}}})}),1)])})],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }