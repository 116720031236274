import { eventTracker, brTracker } from "@/services/tracker";

const SET_INSTALATION_ID = "SET_INSTALATION_ID";

export default {
  state: {
    lang: localStorage.getItem("lang") || "cz",
    apiLang: localStorage.getItem("api_lang") || "pl",
    instalation: null,
  },
  getters: {
    lang: (state) => state.lang,
    installation: (state) => state.instalation,
    instalation: (state) => state.instalation,
    hostId: (state) => state.instalation?.hostId ?? process.env.VUE_APP_HOST_ID,
    strategy: (state) => state.instalation?.host.strategy ?? "notino_profiler",
  },
  mutations: {
    [SET_INSTALATION_ID](state, instalation) {
      state.instalation = instalation;
      eventTracker.installationId = instalation?.installationName || null;
      console.debug(
        "[Track] Set installation id to",
        eventTracker.installationId
      );
      console.debug("set instalation to", instalation);
    },
    setLanguage(state, lang) {
      console.debug("lang changed to", lang);
      state.lang = lang;
    },
    setApiLanguage(state, lang) {
      console.debug("api lang changed to", lang);
      state.apiLang = lang;
    },
  },
  actions: {
    setInstalation(context, instalation) {
      brTracker.initialize(instalation);
      context.commit(SET_INSTALATION_ID, instalation);
    },
    setLanguage(context, lang) {
      console.debug("Lang changed to ", lang);
      eventTracker.emitEvent("LANGUAGE_CHANGED", { lang });
      localStorage.setItem("lang", lang);
      //context.dispatch('clearCache')
      context.commit("setLanguage", lang);
    },
    setApiLanguage(context, lang) {
      // context.dispatch('trackEvent', {
      //   name: 'change language',
      //   data: lang
      // })
      localStorage.setItem("api_lang", lang);
      context.commit("setApiLanguage", lang);
    },
  },
};
