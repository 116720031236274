var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header",class:( _obj = {}, _obj['open-sub-header'] = _vm.isSearchPerfumes, _obj )},[_c('div',{staticClass:"main-header"},[_c('a',{staticClass:"logo",attrs:{"href":_vm.notinoURL,"target":"_blank"}}),_c('div',{staticClass:"left"}),_c('div',{staticClass:"center"},[(_vm.instalationLangs.length > 1)?_c('Multiselect',{attrs:{"value":_vm.lang,"options":_vm.instalationLangs},on:{"input":_vm.changeLang}}):_vm._e()],1),_c('div',{staticClass:"right"},[(_vm.$route.name !== 'Welcome' && _vm.$route.name !== 'ModeSelect')?_c('div',{staticClass:"home-btn",on:{"click":_vm.onHomeClicked}},[_c('div',{staticClass:"icon"}),(!_vm.isMobile)?_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t("home")))]):_vm._e()]):_vm._e(),(_vm.iSearchPerfumePath)?_c('heart-like'):_vm._e(),(_vm.iSearchPerfumePath)?_c('router-link',{staticClass:"profile-btn",class:{ disabled: !_vm.$store.getters.canShowProfile() },attrs:{"to":{
          name: 'Profile',
          params: { mode: _vm.iSearchPerfumePath ? 'perfume' : 'ingredient' },
        }}},[_c('div',{staticClass:"icon"}),(!_vm.isMobile)?_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t("my_profile")))]):_vm._e()]):_vm._e()],1)]),(_vm.showResetWarning)?[_c('div',{staticClass:"reset-confirmation"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t("do_you_want_to_reset")))]),_c('div',{staticClass:"actions"},[_c('div',{staticClass:"button",on:{"click":_vm.confirmReset}},[_vm._v(" "+_vm._s(_vm.$t("start_new"))+" ")]),_c('div',{staticClass:"button",on:{"click":_vm.rejectReset}},[_vm._v(" "+_vm._s(_vm.$t("keep_perfumes"))+" ")])])]),_c('div',{staticClass:"close-btn",on:{"click":function($event){_vm.pendingNav = null}}})])]:_vm._e(),(_vm.confirmHome)?[_c('div',{staticClass:"reset-confirmation"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t("do_you_want_to_reset")))]),_c('div',{staticClass:"actions"},[_c('div',{staticClass:"button",on:{"click":function($event){_vm.pendingNav = 'Welcome';
              _vm.confirmReset();
              _vm.confirmHome = false;}}},[_vm._v(" "+_vm._s(_vm.$t("start_new"))+" ")]),_c('div',{staticClass:"button",on:{"click":function($event){_vm.pendingNav = 'Welcome';
              _vm.rejectReset();
              _vm.confirmHome = false;}}},[_vm._v(" "+_vm._s(_vm.$t("keep_perfumes"))+" ")])])]),_c('div',{staticClass:"close-btn",on:{"click":function($event){_vm.confirmHome = false}}})])]:_vm._e(),(_vm.isSearchPerfumes)?[_c('div',{staticClass:"sub-header"},[_c('div',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.$t("perfume_search_subtitle")))]),_c('button',{staticClass:"go-reco btn--primary",on:{"click":function($event){return _vm.$router.push({ name: _vm.RouteNames.RecommendationsBySearch })}}},[_vm._v(" "+_vm._s(_vm.$t("get_recomendations"))+" ")])])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }