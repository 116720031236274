<template>
  <div class="view" ref="viewRef">
    <div
      class="top"
      :class="{ hidden: this.selectedBrand && !this.searchString }"
    >
      <div class="title" v-html="$t('search_your_perfume')"></div>
      <div class="input-wrapper" :class="{ hide: searchString }">
        <debounced-input
          v-model="searchString"
          :placeholder="$t('search_your_perfume_flat')"
        />
      </div>
      <letter-input
        :class="{ hide: searchString }"
        class="letter-input"
        :selected="selectedLetter"
        @change="handleClickLetter"
      ></letter-input>
    </div>
    <div class="container">
      <!-- Brands: Copy not Mobile -->
      <div v-if="!isMobile" class="brand-list-wrapper">
        <div class="title">{{ $t("filter_by_brand") }}</div>
        <div
          class="message"
          v-if="!isLetters && searchBrands && searchBrands.length === 0"
        >
          {{ $t("no_brands_found") }}
        </div>
        <brand-list
          class="list brand-list"
          :selected="selectedBrand"
          @change="handleChangeSelectedBrand"
          :brands="groupedBrands"
          ref="brandList"
        >
        </brand-list>
      </div>
      <!-- Perfumes -->
      <div class="perfume-list-wrapper">
        <loading class="loading" v-if="loading"></loading>
        <div v-if="error" class="error">{{ $t("some_error") }}</div>
        <template v-else>
          <div
            class="message"
            v-if="!loading && !isLetters && perfumeSearch.length === 0 && !isMobile"
          >
            {{ $t("no_perfumes_found") }}
          </div>

          <perfume-list
            class="list perfume-list"
            ref="perfumesRef"
            :data="perfumes"
            @scroll-end="handleScrollPerfumes"
            :showBestSeller="!isLetters"
          >
            <template #footer>
              <div
                class="message"
                v-if="!loading && !isLetters && perfumeSearch.length === 0 && isMobile"
                >
                {{ $t("no_perfumes_found") }}
              </div>
            </template>
            <!-- Brands: Mobile -->
            <template #header>
              <div v-if="isMobile" class="brand-list-wrapper">
                <div class="title">{{ $t("filter_by_brand") }}</div>
                <div
                  class="message"
                  v-if="!isLetters && searchBrands && searchBrands.length === 0"
                >
                  {{ $t("no_brands_found") }}
                </div>
                <brand-list
                  class="list brand-list"
                  :selected="selectedBrand"
                  @change="handleChangeSelectedBrand"
                  :brands="groupedBrands"
                  ref="brandList"
                >
                </brand-list>
              </div>
              <div class="title">
                {{ brand ? brand.name : $t("perfume_list") }}
              </div>
            </template>
          </perfume-list>
        </template>
      </div>
      <!-- <div class="perfume-list-wrapper" ref="perfumesRef">
        <div class="wrapper" >
          <template>
            <div class="title">
              
            </div>
            <perfume-list
              v-for="entry in Object.entries(perfumes)"
              :key="entry[0]"
              class="list perfume-list"
              ref="perfumesRef"
              :perfumes="entry[1]"
            >
              <template #header>
                <div v-if="entry[0]" class="sub-group-title">
                  {{ entry[0] }}
                </div>
              </template>
            </perfume-list>
          </template>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import DebouncedInput from "../components/DebouncedInput.vue";
import Loading from "../components/Loading.vue";
import BrandList from "../components/Search/BrandList.vue";
import LetterInput from "../components/Search/LetterInput.vue";
import PerfumeList from "../components/Search/PerfumeList.vue";

import {
  getBrands,
  searchByName,
  searchBrandByName,
  getPerfumes,
  ReservedStartWith,
} from "../services/api";
import { brTracker } from "../services/tracker";
import letters, {
  getRegEx,
  normalizeString,
  NUMBERS,
} from "../settings/letters";
import { getBrandList } from "../utils/brandListUtils";

const PAGE_SIZE = 50;

export default {
  components: {
    Loading,
    DebouncedInput,
    PerfumeList,
    LetterInput,
    BrandList,
  },
  data() {
    return {
      searchString: "",
      selectedBrand: null,
      selectedLetter: null,
      scrollLetter: 0,
      letters: letters,
      perfumesLetters: {},
      perfumeSearch: null,
      lettersFetched: [],
      fetching: false,
      page: 0,
      internalError: null,
    };
  },
  mounted() {
    this.fetchPagination();
    this.eventTracker.emitEvent("VIEW_OPENED", {
      type: "EXPLORE_BY_PERFUMES",
    });
    brTracker.pageVisit(
      brTracker.TypePageVisitedEvent.VIEW_EXPLORE_BY_PERFUMES
    );
  },
  asyncComputed: {
    brands() {
      return getBrands();
    },
    async groupedBrands() {
      if (this.searchBrands) return { "": this.searchBrands };
      else return this.allGroupedBrands;
    },
    async allGroupedBrands() {
      var brands = await getBrands();
      const grouped = {};
      brands.forEach((brand) => {
        const normalizeName = normalizeString(brand.name);
        var letter = letters.find((letter) =>
          getRegEx(letter).test(normalizeName)
        );
        if (letter === undefined) letter = this.$t("others");
        grouped[letter] = grouped[letter] || [];
        grouped[letter].push(brand);
      });
      return grouped;
    },
    searchBrands() {
      if(this.selectedBrand && this.isMobile){
        return [this.brand].filter(b => b)
      }
      if (this.selectedLetter && !this.selectedBrand)
        return this.allGroupedBrands[this.selectedLetter] || [];
      return this.searchString ? searchBrandByName(this.searchString) : null;
    },
    async perfumeSearch() {
      const brands = this.selectedBrand ? [this.selectedBrand] : [];
      var result = null;
      if (this.selectedBrand) {
        result = await getPerfumes({
          brand: brands,
          gender: this.gender,
        });
      } else if (this.searchString) {
        result = await searchByName(this.searchString, this.gender, brands);
      } else if (this.selectedLetter)
        // if number, change to
        result = await getPerfumes({
          starts_with:
            this.selectedLetter === NUMBERS
              ? ReservedStartWith.NO_LETTERS
              : this.selectedLetter,
          gender: this.gender,
        });
      return result;
    },
  },
  computed: {
    loading() {
      return (
        this.fetching ||
        this.$asyncComputed.perfumeSearch.updating ||
        this.$asyncComputed.searchBrands.updating ||
        this.$asyncComputed.groupedBrands.updating
      );
    },
    error() {
      return (
        this.internalError ||
        this.$asyncComputed.perfumeSearch.error ||
        this.$asyncComputed.searchBrands.error ||
        this.$asyncComputed.groupedBrands.error
      );
    },
    brand() {
      return this.brands?.find((brand) => brand.id === this.selectedBrand);
    },
    currentLetter() {
      return this.letters[this.scrollLetter];
    },
    gender() {
      return this.$store.state.user.gender;
    },
    isLetters() {
      return !(this.searchString || this.selectedLetter || this.selectedBrand);
    },
    perfumes() {
      if (!this.isLetters) return { "": this.perfumeSearch };
      else return this.perfumesLetters;
    },
  },
  methods: {
    handleClickLetter(value) {
      this.selectedLetter = value;
      const virtualScroll = this.$refs.brandList.$refs.scroll;
      // to keep scroll near to selected brand
      if(value)
        virtualScroll.scrollToItem(0);
    },
    handleScrollPerfumes() {
      // this.scrollLetter++;
      if (this.isLetters) this.fetchPagination();
    },
    async fetchPagination() {
      if (this.fetching) return;
      this.fetching = true;
      this.internalError = null;
      try {
        this.lettersFetched.push(this.currentLetter);
        var perfumes = await getPerfumes({
          starts_with:
            this.currentLetter === NUMBERS
              ? ReservedStartWith.NO_LETTERS
              : this.currentLetter,
          gender: this.gender,
          page_num: this.page,
          page_len: PAGE_SIZE,
        });
        if (perfumes.length > 0) {
          this.page++;
          this.perfumesLetters = {
            ...this.perfumesLetters,
            [this.currentLetter]: (
              this.perfumesLetters[this.currentLetter] || []
            ).concat(perfumes),
          };
        } else {
          this.scrollLetter++;
          this.page = 0;
          this.fetching = false;
          this.fetchPagination();
        }
      } catch (err) {
        this.internalError = err;
      } finally {
        this.perfumeSearch = null;
        this.fetching = false;
      }
    },
    clearFetchedLetters() {
      this.lettersFetched = [];
    },
    handleChangeSelectedBrand(value) {
      this.selectedBrand = value;
      if (value) {
        this.clearFetchedLetters();
        this.searchString = "";
        this.selectedLetter = null
        setTimeout(() => {
          const list = getBrandList(this.groupedBrands, this.isMobile);
          const index = list.findIndex((brand) => brand.id === value);
          if (index > -1) {
            const virtualScroll = this.$refs.brandList.$refs.scroll;
            virtualScroll.scrollToItem(index);
          }
          // const brand = document.getElementById("brand-" + value);
          // brand.scrollIntoView({ behavior: "smooth", block: "center" });
        }, 200);
      }
    },
  },
  watch: {
    searchString(value) {
      if (value) {
        this.selectedLetter = null;
        this.selectedBrand = null;
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
$height-letter-input = vw(30px)
$height-letter-input-mobile = vw(50px)
$breakpoint-letters = desktop $height-letter-input, tablet $height-letter-input, mobile $height-letter-input-mobile
$border-color = #ddd

.view
  display: flex
  flex-direction: column

  .top
    background-color: #fff
    border-bottom: 1px solid $border-color
    // height: vw(240px)
    padding: vw(20px) vw(50px)
    transition: all 0.25s ease-in-out

    .mobile &
      padding: vw(20px)
      padding-bottom: vw(10px)

    // Fix height to animate
    &.hidden
      height: 0
      overflow: hidden
      padding: 0

    .title
      m-font-size(30, 40)
      font-weight: 600
      margin-bottom: vw(30px)
      text-align: center

      .mobile &
        m-font-size(18, 18)
        margin-bottom: vw(20px)

    .input-wrapper
      margin-bottom: vw(30px)
      margin-left: auto
      margin-right: auto
      max-width: vw(1024px)

      &.hide
        margin-bottom: 0

      .mobile &
        margin-bottom: vw(15px)

    .letter-input
      transition: height 0.25s ease-in-out

      .mobile &
        overflow-x: auto

      &.hide
        height: 0
        opacity: 0
        pointer-events: none
        visibility: hidden

      for $b in $breakpoint-letters
        height: $b[1]

        >>> button
          .{$b[1]} &
            height: $b[1]
            width: $b[1]

  .container
    display: flex
    flex: 1 1 100%
    height: 50%
    position: relative

    .loading-overlay
      top: 0

    .mobile &
      flex-direction: column

    .message
      padding: vw(30px)

    >>>.title
      m-font-size(20, 26)
      font-weight: 700
      padding: vw(30px)

      .mobile &
        m-font-size(16, 20)
        font-weight: 500
        padding: vw(20px) 0
        // padding-bottom: vw(10px)

    .sub-group-title
      m-font-size(22, 28)
      font-weight: 700
      margin-bottom: vw(10px)

      .mobile &
        m-font-size(16, 20)
        font-weight: 500

    .brand-list-wrapper
      border-right: 1px solid $border-color
      display: flex
      flex: 0 0 vw(300px)
      flex-direction: column

      .mobile &
        border-right: none
        display: block
        flex: none

      .brand-list
        flex: 1 1 0%

    .brand-list
      padding: 0 vw(30px)

      .mobile &
        height: vw(45px)
        padding: 0 vw(5px)

    .perfume-list-wrapper
      display: flex
      flex: 1 1 0%
      flex-direction: column
      position: relative

      .mobile &
        overflow: hidden

      .error
        m-font-size(16, 20)
        margin: auto
        padding: vw(50px)

      .perfume-list
        flex: 1 1 0%
        padding-bottom: vw(50px)
        padding-left: vw(50px)
        padding-right: vw(50px)

        .mobile &
          padding-bottom: vw(20px)
          padding-left: vw(20px)
          padding-right: vw(20px)

        >>> .sub-group-title
          m-font-size(22, 28)
          font-weight: 700
          margin-bottom: vw(20px)
          margin-top: vw(20px)
          padding-left: vw(30px)
          padding-right: vw(30px)

          .mobile &
            font-weight: 500
            margin-bottom: 0
            margin-top: 0
            padding-left: 0
            padding-right: 0

        >>> .brand,
        >>> .name
          m-ellipsis(100%)

      .loading
        height: 100%
        position: absolute
        top: 0
        width: 100%

      .wrapper
        height: 100%
        overflow-y: auto
        padding: vw(30px) vw(100px)
</style>