<template>
  <div class="concept-toggle" @click="handleClick">
    <div class="background"></div>
    <div
      class="ball"
      :class="value === 1 ? 'left' : value === -1 ? 'right' : 'center'"
    ></div>
  </div>
</template>

<script>
export default {
  props: ["value"],
  methods: {
    handleClick(e) {
      console.log("e", e.offsetX);
      if (!this.value)
        this.$emit(
          "click",
          e.offsetX < this.$el.getBoundingClientRect().width / 2 ? -1 : 1
        );
      else this.$emit("click");
    },
  },
};
</script>

<style lang="stylus" scoped>
$toggle-height = vw(30px)
$ball-size = vw(24px)
$gap = vw(6px)
$border-width = 1px

.concept-toggle
  height: $toggle-height
  position: relative
  width: "calc((%s - %s) * 3)" % ($ball-size $gap)

  .background
    background-color: #fff
    border: $border-width solid #000
    border-radius: $toggle-height
    height: 100%
    width: 100%

  .ball
    background-color: #000
    border-radius: 50%
    height: $ball-size
    left: 50%
    position: absolute
    top: 50%
    transform: translate(-50%, -50%)
    transition: transform 0.25s ease-in-out
    width: $ball-size

    &.left
      $position = "calc(-100% + %s)" % $border-width
      transform: translate($position, -50%)

    &.center
      opacity: 0.25
      transform: translate(-50%, -50%)

    &.right
      $position = "calc(0% - %s)" % $border-width
      transform: translate($position, -50%)
</style>