import letters from "../settings/letters";

export function getBrandList(brands, isMobile) {
  return Object.entries(brands || {})
    .sort(([a], [b]) => {
      let indexA = letters.indexOf(a);
      let indexB = letters.indexOf(b);
      if (indexA === -1) indexA = Infinity;
      if (indexB === -1) indexB = Infinity;
      return indexA - indexB;
    })
    .reduce((array, [name, list]) => {
      return array.concat(
        (isMobile ? [] : [{ id: name, name, type: "subtitle" }]).concat(
          list.map((brand) => ({ ...brand, type: "list" }))
        )
      );
    }, []);
}
