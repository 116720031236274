<template>
  <div class="ingredient-item" :class="{selected:selectable && value}" @click="onClick">
    <div class="image" :style="{backgroundImage: `url(${data.imageUrl || data.lightImageUrl})`}"></div>
    <div class="label">{{data.name.toLowerCase()}}</div>
    <div class="fam-label" v-if="showFamily">{{data.family && data.family.name}}</div>
  </div>
</template>

<script>
export default {
  name: 'IngredientItem',
  components:{
  },
  props: ['data','showFamily','selectable','value'],
  data(){
    return {
      // value: false,
      active: false
    }
  },
  async mounted(){
    // let ingredients = await this.$store.getters.ingredients()
    // this.value = (ingredients.find((p)=>p.id===this.data.id)!==undefined)
    // this.$nextTick(()=>this.active = true)
  },
  methods:{
    onClick(){
      // if(this.selectable) this.value=!this.value
      this.$emit('select',this.data)
    }
  },
  watch:{
    // value(v){
    //   if(!this.active) return
    //   if(v) this.$store.dispatch('addIngredient', this.data)
    //   else this.$store.dispatch('removeIngredient', this.data)
    // }
  }
}
</script>

<style scoped lang="stylus">
.ingredient-item
  align-items: center
  // cursor: pointer
  display: flex
  flex-direction: column
  overflow: hidden
  padding: 1px // sino se corta el "border" del selected

  // &.selected
  // opacity: 0.25

  // &.unselected
  // opacity: 0.2
  .image
    background-position: center center
    background-repeat: no-repeat
    background-size: cover
    border-radius: 50%
    // height: vw(80px)
    // flex: 1 1 0 // Que no lo ocupe todo y se mantenga cuadrado
    margin: 1px // Para asegurar que no se corta "ni un pixel" de la redonda
    margin-bottom: vh(15px)
    max-width: vw(80px)
    position: relative
    width: 100%

    .selected&
      &:before
        border: solid 2px
        border-radius: 100%
        content: ""
        height: calc(100% + 4px)
        margin-left: -2px
        margin-top: -2px
        position: absolute
        width: calc(100% + 4px)

    &:after
      content: ""
      display: block
      padding-bottom: 100%

  .label
    m-ellipsis(100%) // para eliminar el white-space: nowrap
    m-font("Lelo", "light")
    m-font-size(14, 16)
    // m-letter-spacing(25)
    text-align: center
    m-first-letter-case()

  .fam-label
    color: #777
    m-ellipsis(100%)
    // m-font("Lelo", "light")
    m-font-size(12, 14)
    margin-top: vh(5px)
    text-align: center
</style>