export default {
welcome_to_profiler: "<b>Moteur de recherche de parfums Notino</b>",
what_are_you_looking_for: "Laissez-vous conseiller un nouveau parfum qui conviendra parfaitement pour vous ou pour votre proche.<br>Pour qui êtes-vous à la recherche du parfum idéal ?",
build_your_profile_and: "Laissez-vous conseiller un nouveau parfum qui conviendra parfaitement pour vous ou pour votre proche.<br>Pour qui êtes-vous à la recherche du parfum idéal ?",
search_by: "Nous conseillons un parfum selon vos",
gender_m: "Pour hommes",
gender_f: "Pour femmes",
mode_perfumes: "Parfums préférés",
mode_ingredients: "Ingrédients",
back: "Retour",
novelty: "Nouveauté",
go_back_to_finder: "Retour au moteur de recherche de parfums",
done: "Terminé",
best_sellers: "Best-seller",
from: "de",
search_your_perfume: "<b>Choisissez<b> un maximum de 5 parfums appréciés par vous-même ou la personne pour laquelle vous cherchez un parfum.",
search_your_perfume_flat: "Commencez à saisir le nom de votre parfum ou marque de prédilection",
select_your_perfume: "<b>Choisissez</b> votre parfum préféré.",
no_perfumes_found: "Aucun parfum n’a été trouvé :(",
no_ingredients_found: "Aucun ingrédient n’a été trouvé :(",
something_went_wrong: "Malheureusement, nous n’avons trouvé aucun résultat. Essayez de saisir un autre nom",
select_fav_ings: "<b>Choisissez</b> vos ingrédients préférés et nous vous aiderons à trouver le parfum idéal.",
search_fav_ings: "Saisissez ici le nom de vos ingrédients préférés.",
building_your_profile: "<b>Nous allons créer</b> votre<br>profil de parfum",
edit_your_profile: "Modifier mon profil",
fragrances: "Parfums",
ingredients: "Ingrédients",
concepts: "Brouillons",
next_step: "Terminé",
what_gender: "À qui ce profil est-il destiné ?",
selected_ingredients: "Vos ingrédients préférés",
selected_perfumes: "Vos parfums préférés",
no_perfumes_yet: "Votre profil ne contient aucun parfum",
no_ingredients_yet: "Votre profil ne contient aucun ingrédient",
no_conceptss_yet: "Votre profil ne contient aucun brouillon",
get_recomendations: "Recevoir des recommandations",
this_are_your_ings: "Voici vos ingrédients préférés",
your_profile_to_edit: "Votre profil",
add_more_fragrances: "Ajouter d’autres parfums",
add_more_ingredients: "Ajouter d’autres ingrédients",
add_more_concepts: "Ajouter d’autres brouillons",
top_6_recos: "PARFUMS SUR MESURE",
recos_based_on: "Ces recommandations se basent sur votre profil de parfum.",
rest_of_recos: "Recommandations supplémentaires",
view_details: "Afficher les détails",
your_profile_is_empty: "Votre profil est vide !",
add_to_profile_to_get_recos: "Ajoutez des parfums ou des ingrédients à votre profil pour obtenir des recommandations",
price: "prix",
buy_it_now: "En savoir plus",
prominent_ings: "Ingrédients les plus prononcés",
all_ings: "Liste d’ingrédients complète",
why_do_we_recommend: "Pourquoi nous recommandons <b>ce parfum</b> ?",
other_ings_present: "Semblable aux autres, mais moins prononcé",
description: "Description",
evaluate_this_reco: "Évaluez cette recommandation",
how_relevant_do_you: "<b>Cette recommandation </b> vous semble-t-elle adaptée ?",
submit: "Envoyer",
check_your_new_recommendations_1: "<b>Profil actualisé.</b> Merci !",
check_your_new_recommendations_2: "Vous avez de nouvelles recommandations.",
view_new_recommendations: "Afficher les nouvelles recommandations",
close: "fermer",
loading: "Chargement",

//Profile_desc,
you_love_perfumes_of_the: "<b>adorez les parfums</b><br>",
you_love_perfumes_of_the_plural: "<b>adorez les parfums</b><br>",
and: "et",
desc_family: "famille",
desc_families: "familles",
you_also_love_plural: "adorez également les sous-groupes",
you_also_love: "adorez également les sous-groupes",
desc_sub_family: "sous-groupe",
desc_sub_families: "sous-groupes",

//RECOS:,
it: "ce",
also: "aussi",
$pronoun_self: "vous",
$possessive_singular_self: "votre/vos",
$possessive_refl_singular_self: "mon/ma/mes",
$possessive_plural_self: "votre/vos",
$possessive_refl_plural_self: "mon/ma/mes",
$possessive_singular_prep_self: "votre/vos",
$possessive_singular_liste_self: "votre/vos",
$you_self: "vous",
$you_prep_self: "vous",
$do_you_have_self: "avez",
$that_you_love_self: "que vous aimez",
$you_love_self: "aimez",
$you_adore_self: "aimez",
$apreciate_self: "vous plaît/plaisent",
$you_have_self: "avez",
reco_desc_title: "Nous recommandons ce parfum parce que",
possible_reco_title: "Peut-être $you_love ce parfum parce que",
recommended_by_hero: "$you_adore son ingrédient principal : <b>%HERO%</b>",
recommended_by_fam: "fait partie de l’une de$possessive_plural familles de parfum les plus appréciées, <b>%FAMILY%</b>",
not_recommended_by_fam: "Même si son profil est différent de $possessive_singular_prep, $you_prep devriez l’essayer",
recommended_by_ings: "a quelques-uns de z $possessive_plural ingrédients préférés : ",
furthermore: "Suivant",
home: "Nouvelles recommandations",
my_profile: "Mon profil",
no_concepts_yet: "Votre profil ne contient aucun brouillon",
view_more: "Voir plus",
select_fav_ingredients_to_find_perfumes: "Choisissez vos <b>ingrédients</b><br> préférés et trouvez vos <br> parfums<b>préférés</b>",
your_prefered_concepts: "Vos caractéristiques préférées",
concept_fresh: "Frais (hespéridé, par exemple)",
concept_intense: "Intense (boisé, par exemple)",
concept_casual: "Pour une utilisation au quotidien (au travail, par exemple)",
concept_special_occasion: "Pour les occasions spéciales (pour un rendez-vous amoureux, par exemple)",
concept_sure_bet: "Un gage de sécurité",
concept_novelty: "À la mode (une nouveauté, par exemple)",
change_concepts: "Modifiez les caractéristiques sélectionnées",
select_concepts: "C’est presque prêt",
add_concepts: "Ajouter des caractéristiques préférées supplémentaires",
or: "ou bien",
explore_by: "Découvrir d’après",
brands: "Marques",
your_selection: "Votre sélection",
list_view: "Liste des parfums préférés",
graphic_view: "Votre profil de parfum",
recos_based_on_selection: "Recommandations basées sur votre sélection",
add_to_profile: "Ajouter à mon profil",
do_you_want_to_reset: "Souhaitez-vous à nouveau définir votre profil ?",
start_new: "Recommencer",
keep_perfumes: "Conserver les parfums",
click_to_view: "Afficher par un clic",
already_in_profile: "Déjà ajouté",
only_showing_that_combine_with: "S’affichent seulement les ingrédients associés à",
see_all_ings_in_this_fam: "Afficher tous les ingrédients de cette famille",
select_concepts_subtitle: "Pour obtenir des recommandations, vous devez choisir au moins 2 parfums (cliquez pour revenir) ou ajouter au moins l’une des caractéristiques de parfum suivantes.",
action: "promo",
free_del: "livraison gratuite",
new_in: "nouveaux",
cancel: "effacer",
see_more: "Afficher plus",
see_less: "Afficher moins",
recommendation_by: "Recommandations concernant cet ingrédient",
apply: "Appliquer",
modal_limit_ingredients_title: "Maximum d'ingrédients",
modal_limit_ingredients_text: "Vous avez atteint la limite maximale de sélection d'ingrédients (4). Veuillez retirer un ingrédient avant d'en ajouter un autre.",
empty_selection: "Aucun produit sélectionné",
empty_selection_text: "Pour obtenir des recommandations, sélectionnez un ou plusieurs parfum(s)",
search_perfume_subtitle: "Parfait ! Vous avez désormais accès à vos recommandations personnalisées.",
perfume_search_subtitle: "Parfait ! Vous avez désormais accès à vos recommandations personnalisées.",
filter_by_brand: "Filtrer par marque",
perfume_list: "Sélectionnez un parfum",
others: "",
some_error: "Oups ! Il semblerait qu’une erreur soit survenue sur le site Internet. Nous essayons de la réparer au plus vite. Merci pour votre patience !",
no_brands_found: "Aucune marque trouvée :("
}