import axios from "axios";
import store from "@/store";
import combinations from "../../utils/combinations";
// import Router from '../router';

let instance = axios.create({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
instance.interceptors.request.use((config) => {
  // console.log("config",config)
  config.params = {
    ...config.params,
    ...{
      lang: store.state.i18n.apiLang,
      hostId: config.noHost ? null : store.getters.hostId,
    },
  };
  return config;
});
let instanceWithoutHostId = axios.create({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
instanceWithoutHostId.interceptors.request.use((config) => {
  config.params = {
    ...config.params,
    lang: store.state.i18n.apiLang,
  };
  return config;
});

let cache = {};

function addToCache(type, key, value) {
  if (!cache[type]) cache[type] = {};
  cache[type][key] = value;
  return value;
}
function getCached(type, key) {
  return cache[type] && cache[type][key];
}
/**
 *
 * @param {string} type
 * @param {string} args
 * @param {()=>T} callback
 * @template T
 * @returns {T}
 */
function cacheCallback(type, args, callback) {
  var promise = getCached(type, JSON.stringify(args));
  if (promise) return promise;
  promise = callback();
  addToCache(type, JSON.stringify(args), promise);
  return promise;
}

async function useCachePromise(key, callback) {
  if (getCached(key, "")) return getCached(key, "");
  const res = callback();
  addToCache(key, "", res);
  return res;
}

/**
 * return perfumes by text. <br/>
 * Without HostID. <br/>
 *
 * @param {string} query
 * @param {'M' | 'F' | 'U'} gender
 * @returns {Promise<Perfume[]>}
 */
export function searchByName(query, gender, brands = []) {
  // Params query string
  const querystring = new URLSearchParams();
  querystring.set("q", query);
  querystring.set("limit", 50);
  if (brands.length) querystring.set("included.brands", brands.join(","));
  querystring.set("strategy", store.getters.strategy);
  // if (gender) querystring.set("included.gender", gender.split("").join(","));

  // path
  let path = process.env.VUE_APP_API_URL + `/v7/search?${querystring}`;

  // fetch
  return cacheCallback("searchByName", path, async () =>
    instanceWithoutHostId
      .get(path)
      .then((response) => {
        let res = response.data;
        return res;
      })
      .then((datas) => {
        if (datas.data) datas = datas.data;
        datas.forEach((perf) => {
          addToCache("perfume_puig", perf.id, perf);
        });
        return datas;
      })
  );
}
export async function searchBrandByName(query) {
  const querystring = new URLSearchParams();
  querystring.set("q", query);
  querystring.set("strategy", store.getters.strategy);
  querystring.set("entity", "brands");
  querystring.set("light", true);

  let path = process.env.VUE_APP_API_URL + `/v7/search?${querystring}`;

  return cacheCallback("searchBrandByName", path, () =>
    instanceWithoutHostId
      .get(path)
      .then((response) => response.data)
      .then(async (datas) => {
        const ids = datas.data.map((d) => d.id);
        const map = await getMapBrands();
        return ids.map((id) => map.get(id)).filter((obj) => obj);
      })
  );
}
export function searchByIngredients(ingIds) {
  // let lang = store.getters.lang
  // console.log("should search by ings",ings)
  //MENTIRA:
  // return searchByName('212')
  let path = `/perfume_search/by_ingredients/`;
  // let cached = store.getters['perfumes/get'](lang,path)
  // if(cached)
  //   return Promise.resolve(cached)
  return (
    instance
      .post(
        process.env.VUE_APP_API_URL + path,
        ingIds.map((id) => ({ ingredientId: id, proportion: 10 }))
      )
      // .post('https://www.wikiparfum.fr/api/perfume_search/by_ingredients/?&lang=en',ings.map((i)=>({ingredientId:i.id,proportion:10})))
      .then((response) => {
        // store.dispatch('perfumes/save',{lang, path, objects : response.data.data})
        let res = response.data.map((d) => d.perfume);
        // res.forEach((perf)=>{
        //   addToCache('perfume',perf.id,perf)
        // })
        return res;
      })
  );
}

export function getAllIngredients() {
  // let lang = store.getters.lang
  // let cached = store.getters.getCachedIngredients()
  // if (cached)
  //   return Promise.resolve(cached)
  return instance
    .get(process.env.VUE_APP_API_URL + `/ingredients/`)
    .then((response) => {
      let res = response.data;
      res.sort((a, b) => {
        return b.popularity - a.popularity;
      });
      res.forEach((ing) => {
        addToCache("ingredient", ing.id, ing);
      });
      return res;
    });
}
export function getRestrictedIngredients(restrictions) {
  // let lang = store.getters.lang
  // let cached = store.getters.getCachedIngredients()
  // if (cached)
  //   return Promise.resolve(cached)
  return instance
    .get(
      process.env.VUE_APP_API_URL +
        `/ingredients/getPossibleCombinations?ingredients=${restrictions.map(
          (i) => i.id
        )}`
    )
    .then((response) => {
      let res = response.data.map((d) => d.ingredient);
      res.forEach((ing) => {
        addToCache("ingredient", ing.id, ing);
      });
      return res;
    });
}
export async function getCombinationsIngredients(ingredients) {
  return useCachePromise(
    "getCombinationsIngredients/" + ingredients.map((i) => i.id).join("/"),
    async () => {
      const combinationIngredients = combinations(ingredients).filter(
        (array) => array.length > 0
      );
      return Promise.all(
        combinationIngredients.map(async (ingredients) => {
          const perfumes = await getRecommendationsByIngredients(
            ingredients.map((i) => i.id)
          );
          return {
            ingredients,
            perfumes,
          };
        })
      )
        .then((data) => {
          let allPerfumes = [];
          data.forEach((combination) => {
            combination.perfumes = combination.perfumes
              .filter((perfume) => {
                if (allPerfumes.includes(perfume.id)) return false;
                else {
                  allPerfumes.push(perfume.id);
                  return true;
                }
              })
              .slice(0, 12);
          });
          return data;
        })
        .then((data) => data.filter(({ perfumes }) => perfumes.length > 0));
    }
  );
}
/**
 * Fetch by id WITH host ID <br/>
 * @param {number} id
 * @returns {Promise<Perfume>}
 */
export function getPerfume(id) {
  let cached = getCached("perfume", id);
  if (cached) return cached;

  return addToCache(
    "perfume",
    id,
    instance
      .get(process.env.VUE_APP_API_URL + `/perfumes/${id}`)
      .then((response) => {
        return response.data;
      })
  );
}
/**
 * Fetch by id without Host ID
 * @param {number} id
 * @returns {Promise<Perfume>}
 */
export function getPerfumePuig(id) {
  let cached = getCached("perfume_puig", id);
  if (cached) return cached;

  return addToCache(
    "perfume_puig",
    id,
    instance
      .get(process.env.VUE_APP_API_URL + `/perfumes/${id}`, {
        noHost: true,
      })
      .then((response) => {
        return response.data;
      })
  );
}
export function getIngredient(id) {
  let cached = getCached("ingredient", id);
  if (cached) return cached;
  return addToCache(
    "ingredient",
    id,
    instance
      .get(process.env.VUE_APP_API_URL + `/ingredients/${id}`)
      .then((response) => {
        return response.data;
      })
  );
}

const concepts = [
  {
    id: 15,
    name: "concept_fresh",
  },
  {
    id: 2,
    name: "concept_intense",
  },
  {
    id: 31,
    name: "concept_casual",
  },
  {
    id: 30,
    name: "concept_special_occasion",
  },
  {
    id: "sure_bet",
    name: "concept_sure_bet",
  },
  {
    id: "novelty",
    name: "concept_novelty",
  },
];

export function getConcept(id) {
  return concepts.find((c) => c.id === id);
}

export function getRecommendations(mode, restrict) {
  let prom = getRecommendationsShallow(mode, restrict).then((datas) => {
    datas = datas.filter(({ eanData }) =>
      Object.values(eanData).some(
        ({ availability }) => availability && availability > 0
      )
    );
    datas.forEach((perf) => {
      addToCache("perfume", perf.id, perf);
    });
    return datas;
  });
  store.dispatch("setLatestRecos", prom);
  return prom;
}
export function getRecommendationsShallow(mode, restrict) {
  let perfumeIds = store.state.profile.perfumes;
  let ingredientIds = store.state.profile.ingredients;
  if (mode === "ingredients")
    return getRecommendationsByIngredients(ingredientIds);
  else return getRecommendationsByPerfumes(restrict || perfumeIds);
}

function getRecommendationsByIngredients(ingredientIds) {
  return useCachePromise(
    "getRecommendationsByIngredients/" + ingredientIds,
    async () => searchByIngredients(ingredientIds)
  );
}
function getRecommendationsByPerfumes(perfumeIds) {
  let conceptIds = store.state.profile.concepts;
  let gender = store.state.user.gender;

  if (perfumeIds.length === 0)
    return new Promise((resolve) => {
      resolve([]);
    });
  const realConcepts = [];
  // let novelty = false;
  // let sure_bet = false;
  conceptIds.forEach((concept) => {
    // if (concept === "novelty") novelty = true;
    // if (concept === "sure_bet") sure_bet = true;
    if (!["novelty", "sure_bet"].includes(concept)) realConcepts.push(concept);
  });

  let url = //`http://localhost:3001/api/reco?strategy=notino_profiler&perfumes=${perfumeIds}&conceptsAgg=${realConcepts}&limit=12&included.gender=${gender.split('').join(",")}`
    process.env.VUE_APP_API_URL +
    `/v7/reco?perfumes=${perfumeIds}&conceptsAgg=${realConcepts}&limit=100&included.gender=${gender
      .split("")
      .join(",")}&strategy=${store.getters.strategy}`;
  // if(gender && gender !== 'U') url = url + `&gender=` + gender +',U'
  let prom = instance.get(url).then((response) => {
    // Bestiario tracking and then return
    // instance.trackUsage(response.data.data,perfumeIds,exludedIds,conceptIds)
    /////////////////////
    let res = response.data.data;
    // res.forEach((ing)=>{
    //   addToCache('perfume',ing.id,ing)
    // })
    // store.dispatch('setLatestRecos',res)
    return res;
  });

  return prom;
}

export async function getInstalations() {
  var apikey = process.env.VUE_APP_HIERARCHY_APIKEY;
  return axios
    .get(process.env.VUE_APP_API_URL + "/retailer/secure/hierarchy", {
      headers: {
        apikey,
      },
    })
    .then((res) => {
      const results = res.data;
      const retailerId = Number(process.env.VUE_APP_RETAILER_ID);
      /** @type {Host[]} */
      const hosts = results
        .filter((retailer) => retailer.id === retailerId)
        .reduce((array, retailer) => array.concat(retailer.hosts), []);
      const sensors = hosts
        .reduce(
          (array, host) =>
            array.concat(
              host.sensors?.map((s) => ({ ...s, hostId: host.id, host })) ?? []
            ),
          []
        )
        .filter((sensor) => sensor.appName === process.env.VUE_APP_ID);
      console.log("get sensors", sensors);
      return sensors ?? [];
    });
  //return HARDCODE_INSTALATIONS
}
/**
 *
 * @returns {Promise<{description:string,id:string,imageId:number,imageUrl:string}[]>}
 */
export async function getLangs() {
  return instance
    .get(process.env.VUE_APP_API_URL + "/languages")
    .then((data) => data.data);
}

export async function getBrands() {
  return cacheCallback("getBrands", "", () =>
    instanceWithoutHostId
      .get(process.env.VUE_APP_API_URL + "/brands/")
      .then((data) => data.data)
  );
}
export async function getMapBrands() {
  return cacheCallback("getMapBrands", "", async () => {
    const brands = await getBrands();
    const map = new Map();
    brands.forEach((b) => map.set(b.id, b));
    return map;
  });
}

export async function getPerfumeByBrand(brand) {
  return instance
    .get(process.env.VUE_APP_API_URL + "/perfumes/", {
      params: {
        brand,
      },
    })
    .then((res) => res.data);
}
/**
 * @enum
 * sort type of getPerfumeByLetter
 */
const SortBy = Object.freeze({
  PERFUME_NAME: "PERFUME_NAME",
  BRAND_NAME_AND_PERFUME_NAME: "BRAND_NAME_AND_PERFUME_NAME",
});
/**
 * @enum Reserved names for start with
 *
 *
 */
export const ReservedStartWith = Object.freeze({
  NUMBER: "number",
  NO_LETTERS: "no-letters",
});

export async function getPerfumes({
  starts_with,
  order_by,
  page_num,
  page_len,
  brand,
}) {
  return cacheCallback("getPerfumeByLetter", arguments, () =>
    instanceWithoutHostId
      .get(process.env.VUE_APP_API_URL + "/perfumes/", {
        params: {
          starts_with,
          order_by: order_by || SortBy.PERFUME_NAME,
          page_num,
          page_len,
          brand_ids: brand ? [brand].flat().join(",") : undefined,
        },
      })
      .then((res) => res.data)
  );
}
