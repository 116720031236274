<template>
  <transition name="fade">
    <div class="loading-overlay" v-if="active">
      <div class="content">
        <!-- <div class="icon"></div> -->
        <div class="wheel">
          <div class="back"></div>
          <div class="wedge"></div>
          <div class="front"></div>
        </div>
        <div v-if="text" class="text">{{ $t(text) }}</div>
      </div>
    </div>
  </transition>
</template>


<script>
export default {
  name: "LoadingOverlay",
  props: {
    text: {
      type: String,
      default: "loading",
    },
  },
  data() {
    return {
      active: false,
    };
  },
  mounted() {
    this.$nextTick(() => (this.active = true));
  },
};
</script>

<style scoped lang="stylus">
$radius = vw(60px)
$radius1 = "calc( %s - 1px)" % vw(60px)
$outer-diameter = vw(120px)
$inner-diameter = vw(100px)

.loading-overlay
  background-color: rgba(#fff, 0.9)
  display: flex
  height: 100%
  justify-content: center
  left: 0
  max-height: 100vh
  position: absolute
  top: $header-height
  width: 100%
  z-index: 10

  .mobile &
    top: $header-height-mobile

  &.fade-enter-active
    transition: opacity 0.25s ease-in-out

  &.fade-leave-active
    transition: opacity 0.25s ease-in-out
    transition-delay: 0.25s

  &.fade-enter,
  &.fade-leave-to
    opacity: 0

  .content
    align-items: center
    display: flex
    flex-direction: column
    justify-content: center

    .icon
      background-image: url("~@/assets/icons/loader.gif")
      background-position: center center
      background-repeat: no-repeat
      background-size: contain
      height: 40px
      width: 40px

    .text
      m-first-letter-case()
      m-font-size(16, 20)
      font-weight: bold
      margin: vw(5px)
      text-align: center
      // max-width 60%

    .wheel
      height: $radius
      margin: vw(5px)
      position: relative

      .wedge
        animation: spin 0.9s infinite linear
        border-bottom: $radius1 solid transparent
        border-left: $radius1 solid transparent
        border-right: $radius1 solid transparent
        border-top: $radius1 solid $notino-pink
        border-top-left-radius: $radius1
        border-top-right-radius: $radius1
        height: 0
        position: absolute
        // position: relative;
        top: 0
        transform: translate(-50%, -50%) rotate(320deg)
        width: 0

        &:after
          border-bottom: $radius1 solid transparent
          border-left: $radius1 solid transparent
          border-right: $radius1 solid transparent
          border-top: $radius1 solid #fff
          border-top-left-radius: $radius1
          border-top-right-radius: $radius1
          border-width: 0
          content: ""
          height: 0
          // animation: spin 0.9s infinite linear;
          position: absolute
          top: 0
          transform: translate(-50%, -50%) rotate(60deg)
          width: 0

      .back
        border: solid 1px
        border-radius: 100%
        height: $outer-diameter
        position: absolute
        transform: translate(-50%, -50%)
        width: $outer-diameter

      .front
        background: #fff
        border: solid 1px
        border-radius: 100%
        height: $inner-diameter
        position: absolute
        transform: translate(-50%, -50%)
        width: $inner-diameter

@keyframes spin
  0%
    transform: translate(-50%, -50%) rotate(320deg)

  100%
    transform: translate(-50%, -50%) rotate(680deg)
</style>