<template>
  <div id="app" :class="deviceClass">
    <header-vue></header-vue>
    <transition-group name="fade" tag="div" class="transition-wrapper">
      <div
        class="keep-alive"
        :key="$router.currentRoute.name + '_' + $router.currentRoute.params.id"
      >
        <!-- <keep-alive> -->
        <router-view
          :key="
            $router.currentRoute.name + '_' + $router.currentRoute.params.id
          "
        />
        <!-- </keep-alive> -->
      </div>
    </transition-group>
    <!-- <template v-if="showResetWarning">
      <div class="reset-confirmation">
        <div class="content">
          <div class="text">{{$t('reset_disclaimer')}}</div>
          <div class="actions">
            <router-link class="button" :to="'/welcome'" @click.native="resetProfile">{{$t('start_new')}}</router-link>
            <router-link class="button" :to="{query:null}">{{$t('cancel')}}</router-link>
          </div>
        </div>
      </div>
    </template> -->
  </div>
</template>


<script>
// import device from "@/utils/device";
import HeaderVue from "./components/Header.vue";
import ResponsiveCss from "./mixins/ResponsiveCss";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { HeaderVue },
  mixins: [ResponsiveCss],
  // mounted() {
  //   this.handleLogin();
  // },
  data() {
    return {
      loggedUser: null,
    };
  },
  computed: {
    ...mapGetters(["installation"]),
    deviceClass() {
      return this.$mq;
    },
    // showResetWarning(){
    //   return this.$route.query['confirm-reset']!==undefined
    // }
  },
  watch: {
    $route() {
      // this.handleLogin();
      if (this.$route.query.referer) this.setReferer(this.$route.query.referer);
    },
    installation: {
      handler() {
        if (this.installation) this.handleLogin();
      },
      immediate: false,
    },
  },
  methods: {
    ...mapActions(["setReferer", "setUser"]),
    handleLogin() {
      const regex = /_ga=[0123456789.-]*/g;
      const match = window.location.href.match(regex);
      if (match) {
        try {
          const userId = match[0].split("=")[1].split("-")[1];
          if (this.loggedUser !== userId) {
            this.eventTracker.emitEvent("LOGIN", { user: userId });
            this.setUser(userId);
            this.loggedUser = userId;
          }
        } catch (error) {
          console.error("Error trying to parse userId (_ga) from url");
        }
      }
    },
  },
};
</script>
<style lang="stylus">
@import "./styles/main"

#app
  height: 100%
  overflow: hidden
  width: 100%

  .header
    position: fixed
    top: 0
    width: 100%
    z-index: 1

    .mobile&
      &.open-sub-header
        + .transition-wrapper
          .view
            height: "calc(100% - %s - %s)" % ($header-height-mobile $sub-header-height-mobile)
            top: "calc(%s + %s)" % ($header-height-mobile $sub-header-height-mobile)

    &.open-sub-header
      + .transition-wrapper
        .view
          height: "calc(100% - %s - %s)" % ($header-height $sub-header-height)
          top: "calc(%s + %s)" % ($header-height $sub-header-height)

  .transition-wrapper
    height: 100%
    overflow: hidden
    position: relative
    z-index: 0

    .view
      background-color: #fff
      height: 100%
      height: "calc(100% - %s)" % $header-height
      left: 0
      // no se perque el vas tornar a posar, pero no va be amb els vh y la nav bar de mobile
      // overflow: hidden
      // margin-top: $header-height
      position: fixed
      top: $header-height
      width: 100%

      .mobile&
        // margin-top: $header-height-mobile
        height: "calc(100% - %s)" % $header-height-mobile
        top: $header-height-mobile
        // height "calc(100% - %s)" % $header-height-mobile

      &.scrollable
        overflow-y: auto
        padding-bottom: vh(220px)

  .keep-alive
    height: 100%
    overflow: hidden
    width: 100%

    &.fade-enter-active
      position: absolute
      top: 0
      transition: opacity 0.5s ease-in-out
      z-index: 1

    &.fade-leave-active
      opacity: 0
      position: absolute
      top: 0
      transition: opacity 0.5s ease-in-out
      transition-delay: 0.5s
      z-index: 0

    &.fade-enter
      opacity: 0

    &.fade-leave-to
      opacity: 0
</style>
