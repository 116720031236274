<template>
  <div class="welcome view">
    <div class="background"></div>
    <div class="step">
      <div class="title" v-html="$t('welcome_to_profiler')"></div>
      <div class="description" v-html="$t('what_are_you_looking_for')"></div>
      <div class="buttons">
        <div class="button" @click="() => setGender('MU')">
          {{ $t("gender_m") }}
        </div>
        <div class="button" @click="() => setGender('FU')">
          {{ $t("gender_f") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { brTracker } from "../services/tracker";

export default {
  mounted() {
    this.eventTracker.emitEvent("VIEW_OPENED", {
      type: "LANDING_PAGE",
    });
    brTracker.pageVisit(brTracker.TypePageVisitedEvent.VIEW_LANDING_PAGE);
    brTracker.identify({ userId: this.loggedUser });
    // this.$store.dispatch("reset");
  },
  computed: {
    ...mapGetters(["loggedUser"]),
  },
  methods: {
    setGender(g) {
      this.$store.dispatch("setGender", g);
      this.$router.push({ name: "ModeSelect" });
      // this.$router.push({name:"PerfumeSearch"});
    },
  },
};
</script>

<style lang="stylus">
.welcome,
.mode-select
  height: 100%
  width: 100%

  .background
    background-color: #444
    background-image: url("../assets/images/bg-compo2.png")
    background-position: center
    background-repeat: no-repeat
    background-size: cover
    height: 100%
    width: 100%

    .mobile &
      background-image: url("../assets/images/background-mobile2.jpg")

    &:after
      background: rgba(22, 12, 0, 0.4)
      content: ""
      height: 100%
      position: absolute
      width: 100%

  .step
    align-items: center
    display: flex
    flex-direction: column
    left: 50%
    position: absolute
    text-align: center
    top: 50%
    transform: translate(-50%, -50%)
    width: 60%

    .mobile &
      width: 90%

  .title
    color: #fff
    m-font-size(32, 40)
    margin-bottom: vh(25px)

    .mobile &
      margin-bottom: vh(30px)

  .description
    color: #fff
    m-font-size(24, 30)
    margin-bottom: vh(50px)
    max-width: 60%

    .mobile &
      margin-bottom: vh(100px)

  .buttons
    display: flex

    .button
      border: 2px solid #fff

      .mobile &
        padding: 1em

      + .button
        margin-left: vw(50px)

        .mobile &
          margin-left: vw(30px)

.mode-select
  .title
    .mobile &
      m-font-size(24, 30)

      > br
        display: none
</style>