<template>
  <div class="view profile">
    <template v-if="this.$store.state.profile.perfumes.length === 0">
      <div class="no-data-disclaimer">
        <div class="no-data-content">
          <div class="main">{{ $t("your_profile_is_empty") }}</div>
          <div class="sub">{{ $t("add_to_profile_to_get_recos") }}</div>
          <div class="actions">
            <router-link class="button" :to="{ name: 'PerfumeSearch' }">{{
              $t("mode_perfumes")
            }}</router-link>
            <router-link class="button" :to="{ name: 'IngredientSearch' }">{{
              $t("mode_ingredients")
            }}</router-link>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="top" ref="scroller">
        <div class="left">
          <profile-desc v-if="!isMobile"></profile-desc>
          <div class="concepts" v-if="!isMobile">
            <template v-if="profileConcepts && profileConcepts.length > 0">
              <div class="title">{{ $t("your_prefered_concepts") }}:</div>
              <div class="concepts-row">
                <div
                  class="concept"
                  v-for="concept in profileConcepts"
                  :key="concept.id"
                  @click="() => removeConcept(concept)"
                >
                  <div class="concept-text">{{ $t(concept.name) }}</div>
                  <div class="close" />
                </div>
              </div>
              <router-link
                class="edit-concepts"
                :to="{ name: 'ConceptsSelect' }"
                >{{ $t("add_concepts") }}</router-link
              >
            </template>
            <template v-else>
              <router-link
                class="add-concepts"
                :to="{ name: 'ConceptsSelect' }"
                >{{ $t("add_concepts") }}</router-link
              >
            </template>
          </div>
          <!-- <router-link class="recos-btn button" :to="`/recommendations/perfumes`+(filterString?`?restricted=${filterString}`:'')">{{$t('get_recomendations')}}</router-link> -->
          <div class="recos-btn button" @click="goToRecos">
            {{ $t("get_recomendations") }}
          </div>
        </div>
        <div class="center">
          <profile-desc v-if="isMobile"></profile-desc>
          <div class="concepts" v-if="isMobile">
            <template v-if="profileConcepts && profileConcepts.length > 0">
              <div class="title">{{ $t("your_prefered_concepts") }}</div>
              <div class="concepts-row">
                <div
                  class="concept"
                  v-for="concept in profileConcepts"
                  :key="concept.id"
                  @click="() => removeConcept(concept)"
                >
                  <div class="concept-text">{{ $t(concept.name) }}</div>
                  <div class="close" />
                </div>
              </div>
              <router-link
                class="edit-concepts"
                :to="{ name: 'ConceptsSelect' }"
                >{{ $t("add_concepts") }}</router-link
              >
            </template>
            <template v-else>
              <router-link
                class="add-concepts"
                :to="{ name: 'ConceptsSelect' }"
                >{{ $t("add_concepts") }}</router-link
              >
            </template>
          </div>
          <div class="donut-wrapper" v-if="donutData">
            <Donut
              :data="donutData"
              v-model="selectedFamily"
              :show-labels="true"
              :snap-angle="-90"
              ref="donut"
            ></Donut>
            <div :class="['family-pointer']">
              <div class="family-label">
                <transition name="shift">
                  <div class="label" :key="selectedFamily && selectedFamily.id">
                    {{ selectedFamily && selectedFamily.name }}
                  </div>
                </transition>
              </div>
              <div class="arrow"></div>
              <div :class="['donut-explanation', { hidden: selectedFamily }]">
                {{ $t("click_to_view") }}
              </div>
              <div v-if="isMobile" class="mobile-currents-wrapper">
                <transition name="swap" mode="out-in">
                  <div
                    class="currents-list"
                    v-if="selectedFamily"
                    :key="selectedFamily.id"
                  >
                    <PerfumeCard
                      v-for="perfume in perfumesInSelection[0]"
                      :key="perfume.id"
                      :data="perfume"
                      :showRemove="true"
                      @remove="removing = perfume"
                      @click="selectedFav = perfume"
                    />
                  </div>
                </transition>
              </div>
            </div>
          </div>
          <!-- HA DE SER PRIMARY! no ghost com al diseño "antic"-->
          <!-- <router-link class="goto-edit-btn" to="/profile-edit" @click.native="trackEditProfile">{{$t('edit_your_profile')}}</router-link> -->
        </div>
        <div class="right">
          <div class="title">{{ $t("this_are_your_ings") }}</div>
          <div class="ingredients-container">
            <ingredient-item
              v-for="ing in profileIngredients"
              :key="ing.id"
              :data="ing"
              :selectable="false"
            ></ingredient-item>
          </div>
        </div>
      </div>
      <div v-if="!isMobile" :class="['bottom', { open: selectedFamily }]">
        <div class="close-button" @click="selectedFamily = null"></div>
        <transition name="swap" mode="out-in">
          <div
            class="currents-list"
            v-if="selectedFamily"
            :key="selectedFamily.id"
          >
            <!-- slider if many chunks -->
            <slider
              v-if="perfumesInSelection.length > 1"
              @tap="onListTap"
              @slide="onListSlide"
              :options="{
                pagination: perfumesInSelection.length > 1,
              }"
            >
              <slideritem v-for="(chunk, i) in perfumesInSelection" :key="i">
                <PerfumeCard
                  v-for="perfume in chunk"
                  :key="perfume.id"
                  :data="perfume"
                  :showRemove="true"
                  @remove="removing = perfume"
                  @click="onItemTap"
                />
              </slideritem>
            </slider>
            <!-- simple list otherwise -->
            <PerfumeCard
              v-else
              v-for="perfume in perfumesInSelection[0]"
              :key="perfume.id"
              :data="perfume"
              :showRemove="true"
              @remove="removing = perfume"
              @click="selectedFav = perfume.id"
            />
          </div>
        </transition>
      </div>
      <div class="back-button" @click="$router.go(-1)">
        <div class="back-icon"></div>
        <div class="back-text">{{ $t("back") }}</div>
      </div>
      <loading v-if="!donutData"></loading>
    </template>
  </div>
</template>

<script>
import Donut from "@bestiario/puig-donut";
import IngredientItem from "@/components/Legacy/IngredientItem";
import ProfileDesc from "../components/ProfileDesc.vue";
import Loading from "../components/Loading.vue";
import PerfumeCard from "@/components/PerfumeCard";
import { Tracker } from "@/services";
import { slider, slideritem } from "vue-concise-slider";
import { brTracker } from "../services/tracker";
import { mapActions } from "vuex";
var VueScrollTo = require("vue-scrollto");

export default {
  components: {
    Donut,
    IngredientItem,
    ProfileDesc,
    Loading,
    PerfumeCard,
    slider,
    slideritem,
  },
  data() {
    return {
      selectedFamily: null,
    };
  },
  mounted() {
    this.eventTracker.emitEvent("VIEW_OPENED", { type: "OWN_PROFILE" });
    brTracker.pageVisit(brTracker.TypePageVisitedEvent.VIEW_PROFILE);
  },
  asyncComputed: {
    donutData() {
      return this.$store.getters.familiesWithSubsByPerfumes();
    },
    async profileIngredients() {
      let all = await this.$store.getters.profileIngredients();
      return all.slice(0, 12);
    },
    async profilePerfumes() {
      return await this.$store.getters.perfumes();
    },
    async profileConcepts() {
      return await this.$store.getters.concepts();
    },
  },
  computed: {
    perfumesInSelection() {
      if (!this.selectedFamily) return null;
      let perfumes = this.profilePerfumes.filter(
        (p) => p.family.id === this.selectedFamily.id
      );
      let chunks = perfumes.reduce((chunks, p, i) => {
        if (i % 6 === 0) chunks.push([]);
        chunks[chunks.length - 1].push(p);
        return chunks;
      }, []);
      return chunks;
    },
    filterString() {
      return this.perfumesInSelection
        ? this.perfumesInSelection.flat().map((p) => p.id)
        : null;
    },
  },
  methods: {
    ...mapActions(["removeConcept"]),
    trackEditProfile() {
      Tracker.trackEditProfile();
    },
    goToRecos() {
      this.$store.dispatch("setNeedsNewRecos");
      if (this.filterString) {
        this.$router.push({
          name: "Recommendations",
          params: { mode: "perfumes" },
          query: { restricted: this.filterString },
        });
      } else {
        this.$router.push({
          name: "Recommendations",
          params: { mode: "perfumes" },
        });
      }
    },
    onListTap() {
      // this.didSlide = false
    },
    onItemTap() {
      // if(!this.didSlide) this.selectedFav = data
    },
    onListSlide() {
      // if(e.direction!=='rebound') this.didSlide = true
    },
  },
  watch: {
    selectedFamily() {
      //HABRIA QUE NAIMARLO con algo como vue-scrollto
      // console.log("WTF",this.selectedFamily)
      if (this.isMobile) {
        // let donutOffset = this.$refs.donut.$el.offsetTop + 80
        // this.$refs.scroller.scrollTop = donutOffset
        setTimeout(() => {
          VueScrollTo.scrollTo(this.$refs.donut.$el, 600, {
            container: this.$refs.scroller,
            offset: 40,
          });
        }, 300);
      }
    },
    // profileFamiliesWithSubs() {
    //   if ( this.selectedFamily && this.profileFamiliesWithSubs.findIndex(el => { return el.family.id === this.selectedFamily.id}) < 0) {
    //     this.selectedFamily = null
    //   }
    // }
  },
};
</script>

<style lang="stylus" scoped>
.profile
  align-items: center
  display: flex
  flex-direction: column
  padding: vh(30px)

  .mobile &
    padding: 0

  .loading-overlay
    top: 0

  .no-data-disclaimer
    left: 50%
    position: absolute
    top: 50%
    transform: translate(-50%, -50%)

    .mobile &
      width: 90%

    .no-data-content
      align-items: center
      display: flex
      flex-direction: column
      text-align: center

      .main
        m-font-size(24)
        margin: vw(20px)

      .sub
        m-font-size(20)
        color: $manatee

      .actions
        align-items: center
        display: flex
        flex-wrap: wrap
        justify-content: center
        margin: vw(15px)

        .button
          margin: vw(15px)
          width: vw(180px)

  .back-button
    align-items: center
    background-color: rgba(#fff, 0.9)
    box-shadow: 0 0 0 10px rgba(#fff, 0.9)
    cursor: pointer
    display: flex
    left: vw(30px)
    position: absolute
    text-transform: uppercase
    top: vh(30px)

    .mobile &
      left: vw(20px)
      top: vw(20px)

    .back-icon
      m-icon("arrow-left", 14)
      margin-right: vw(5px)

    .back-text
      font-weight: $fw-medium
      m-font-size(14, 20)
      margin-top: 0.1em
      text-transform: uppercase

  .top
    align-items: center
    display: flex
    flex: 1 1 0%
    overflow: hidden
    width: 100%

    .mobile &
      flex-direction: column
      overflow: auto

  .left,
  .right
    flex: 0 0 vw(300px)

  .left
    text-align: center

    .mobile &
      align-items: center
      background-color: #fff
      bottom: 0
      box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.05)
      display: none !important
      display: flex
      height: vw(80px)
      justify-content: center
      left: 0
      position: fixed
      width: 100%
      z-index: 1

  .center
    align-items: center
    display: flex
    flex: 1 1 0%
    flex-direction: column
    height: 100%
    justify-content: center

    .mobile &
      display: block
      height: auto // no seporque tiene 100% en desktop...
      margin-top: vw(50px)
      padding: vw(20px)
      width: 100%

    .profile-desc
      text-align: center

    .donut-wrapper
      flex: 0 0 32vw
      margin-top: vh(-100px)
      width: 100%

      .mobile &
        margin-bottom: vw(20px)
        margin-top: 0

      >>> .donut
        height: 100%
        width: 100%

        .mobile &
          height: 90vw

      .family-pointer
        align-items: center
        display: flex
        flex-direction: column
        opacity: 1
        position: relative
        transition: opacity 0.4s ease-in-out

        .mobile &
          margin-bottom: vh(15px)

        &.hidden
          opacity: 0

        .donut-explanation
          m-first-letter-case()
          m-font("Lelo", "medium")
          // m-font-size(12, 14)
          m-font-size(16, 20)
          margin-top: vh(10px)
          position: absolute
          text-align: center
          top: vh(40px)
          transition: opacity 0.4s ease-in-out

          .mobile &
            m-font-size(16, 20)
            margin-top: vh(-40px)

          &.hidden
            opacity: 0

        .arrow
          height: vw(22px)
          margin-top: vh(5px)
          position: relative
          width: @height

          .mobile &
            margin-top: 0

          &:after
            m-triangle("up", #000, 10)
            left: 50%
            top: 50%
            transform: translate(-50%, -50%)

            .mobile &
              m-triangle("up", #000, 20)

        .family-label
          height: 2vh
          position: relative

          .mobile &
            height: 4vh

          .label
            $shift-x = vw(200px)
            text-transform: uppercase
            m-font("Lelo", "bold")
            position: absolute
            transform: translate(-50%, 0)
            white-space: nowrap

            &.shift-enter-active,
            &.shift-leave-active
              transition: transform 0.6s ease-in-out, opacity 0.4s ease-in-out

            &.shift-enter
              opacity: 0
              transform: translate($shift-x, 0)
              transition-delay: 0.6s

            &.shift-leave-to
              opacity: 0
              transform: translate(-$shift-x, 0)

    .goto-edit-btn
      display: block
      m-font-size(16, 20)
      margin: 0 auto
      margin-bottom: vh(30px)
      text-align: center
      text-decoration: underline

      .mobile &
        margin-bottom: vh(60px)
        m-font-size(18, 22)

  .right
    display: flex
    flex-direction: column

    .mobile &
      display: block

    .title
      m-font-size(18, 22)
      margin-bottom: vh(30px)

      .mobile &
        margin-bottom: vw(15px)
        // margin-top: vw(30px)
        text-align: center

    .ingredients-container
      align-items: center
      display: flex
      flex-wrap: wrap
      max-height: 50vh
      overflow-y: auto

      .mobile &
        border-top: 1px solid #ccc
        max-height: 100%
        overflow-y: visible
        padding-top: vw(25px)
        width: 100%

      .ingredient-item
        $gap = 10px
        $width = percentage((1 / 3))
        flex: 0 0 "calc(%s - (%s * 2))" % ($width vw($gap))
        margin-bottom: vw($gap * 2)
        margin-left: vw($gap)
        margin-right: vw($gap)

  .concepts
    margin-bottom: vh(60px)
    text-align: left

    .mobile &
      margin-bottom: vh(60px)
      text-align: center

    .add-concepts,
    .edit-concepts
      m-font-size(18, 22)
      text-align: left
      text-decoration: underline

      .mobile &
        m-font-size(18, 22)

    .title
      m-font-size(14, 20)
      font-weight: $fw-bold
      margin-top: vw(20px)

      .mobile &
        m-font-size(16, 22)

    .concepts-row
      display: flex
      flex-wrap: wrap
      margin-bottom: vw(20px)
      margin-top: vw(20px)

      .concept
        align-items: center
        border: 1px solid #000
        border-radius: vw(30px)
        display: flex
        display: flex
        height: vw(30px)
        margin-bottom: vw(10px)
        margin-right: vw(10px)
        padding-bottom: 0.1em
        padding-left: vw(15px)
        padding-right: vw(15px)

        .concept-text
          m-font-size(12, 14)
          m-ellipsis(100%)
          align-items: center
          font-weight: $fw-light
          margin-top: 0.35em

        .close
          m-icon("close", 12)
          cursor: pointer
          margin-left: vw(10px)

          .mobile &
            m-icon("close", 10)
            margin-top: 0.15em

  .bottom
    box-shadow: 0 0 10px 0 rgba(#000, 0.15)
    flex: 0 0 0%
    margin-bottom: vh(-30px)
    overflow: hidden
    position: relative
    transition: flex 0.4s ease-in-out
    width: 100vw
    z-index: 1

    &.open
      flex: 0 0 25vh

      @media (orientation: portrait)
        .tablet &
          flex: 0 0 20vh

    .close-button
      cursor: pointer
      m-icon("close", 15)
      position: absolute
      right: 0
      right: vw(10px)
      top: vw(10px)
      z-index: 1

    .currents-list
      display: flex
      height: 100%
      justify-content: safe center
      margin-left: vw(30px)
      overflow-x: auto
      width: "calc(100% - %s)" % vw(60px)

      // .mobile &,
      &.swap-enter-active,
      &.swap-leave-active
        transition: transform 0.4s ease-in-out

      &.swap-enter,
      &.swap-leave-to
        transform: translate(0, 150%)

      >>> .slider-pagination-bullets
        bottom: 0

        .slider-pagination-bullet
          border: solid 1px #ddd

      .slider-item
        align-items: initial
        color: #000

      .perfume-card
        $percentage = percentage((1 / 8))
        $margin = vw(10px)
        $width = "calc(%s - (%s * 2) - 2px)" % ($percentage $margin)
        cursor: default
        flex: 0 0 $width
        margin: $margin
        max-width: $width
        width: $width

        &:hover
          >>> .name,
          >>> .brand
            text-decoration: none

        >>> .family-and-icon
          align-self: flex-start
          m-ellipsis(100%)
          margin-bottom: vh(5px)

          .label
            m-font-size(10, 12)

          .trash-button
            right: 0
            top: 0

        >>> .image
          flex: 1 1 0%
          margin: $margin 0
          width: 100%

          @media (orientation: portrait)
            .tablet &
              margin: vw(10px * 2) 0

        >>> .name
          m-font-size(12, 14)
          font-weight: $fw-medium
          height: vw(30px)
          margin-bottom: "calc(%s / 4)" % $margin

          @media (orientation: portrait)
            .tablet &
              height: vw(40px)

        >>> .brand
          m-font("Lelo", "light")
          m-font-size(10, 12)
          margin-bottom: vh(5px)

        >>> .info
          width: 100%

        >>> .tags,
        >>> .price
          display: none

  .mobile-currents-wrapper
    .currents-list
      margin-top: vh(80px)

      >>> .perfume-card
        margin-bottom: vh(80px)

        .family-and-icon
          margin-bottom: 0
</style>