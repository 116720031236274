export default function routeParams(array, type) {
  array = Array.isArray(array) ? array : [array];
  return {
    computed: {
      ...array.reduce(
        (obj, str) => ({
          ...obj,
          [str]: {
            get() {
              switch (type) {
                case "number":
                  return this.$route.query[str]
                    ? Number(this.$route.query[str])
                    : null;
                case "string":
                default:
                  return this.$route.query[str];
              }
            },
            set(value) {
              this.$router
                .replace({
                  query: { ...this.$route.query, [str]: value },
                })
                .catch(() => {});
            },
          },
        }),
        {}
      ),
    },
  };
}
