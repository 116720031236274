<template>
  <div
    class="icon-wrapper"
    v-if="profilePerfumes"
    :class="{ empty: profilePerfumes && !profilePerfumes.length }"
    v-click-outside="handleClickOutside"
  >
    <div class="icon" @click="open = !open">
      <div class="dot">
        <span v-if="profilePerfumes.length">{{ profilePerfumes.length }}</span>
      </div>
    </div>
    <transition name="fade">
      <div class="background" v-if="isMobile && open" @click="open = false" />
    </transition>

    <transition name="fade">
      <div class="tooltip" v-if="open" >
        <div class="tooltip-header">
          <div class="title" v-if="!profilePerfumes.length">
            {{ $t("empty_selection") }}
          </div>
          <div class="title" v-else>{{ $t("your_selection") }}</div>
          <button class="btn btn--ghost close" @click="open = false" />
        </div>
        <div class="perfume-list" v-if="profilePerfumes.length" @scroll="clearTimeout">
          <transition-group name="list">
            <perfume-item
              class="small"
              v-for="perfume in profilePerfumes"
              :key="perfume.id"
              :data="perfume"
            />
        </transition-group>
          
        </div>
        <div class="text" v-else>{{ $t("empty_selection_text") }}</div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import vClickOutside from "v-click-outside";
import PerfumeItem from "./PerfumeItem.vue";

export default {
  data() {
    return {
      open: false,
    };
  },
  components: { PerfumeItem },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  computed: {
    ...mapGetters(["perfumes","perfumesIds"]),
  },
  asyncComputed: {
    async profilePerfumes() {
      const perfumes = await this.perfumes().then((perfumes) =>
        perfumes.filter((p) => p)
      );
      return perfumes;
    },
  },
  methods:{
    handleClickOutside(){
      // this.open = false
    },
    clearTimeout(){
      clearTimeout(this.timer)
    },
    setTimeout(time = 100){
      this.clearTimeout()
        this.timer = setTimeout(()=>{
          this.open = true
          
          this.timer = setTimeout(()=>{
            this.open = false
          },1500)
        },time)
    }
  },
  watch:{
    profilePerfumes(value, lastValue){
      if(this.isMobile){
        if( lastValue?.length === 0 && value.length > 0)
         this.setTimeout()
      }
      else if(value && lastValue && lastValue.length<value.length)
        this.setTimeout()
    }
  }
};
</script>
<style lang="stylus">
$tooltip-width = vw(400px)
$tooltip-width-mobile = 90vw
$tooltip-padding-width = vw(10px)
$tooltip-padding-wrapper-width = vw(20px)
.icon-wrapper
  cursor: pointer
  position: relative

  .background
    background-color: #000
    height: 100vh
    left: 0
    opacity: 0.5
    position: fixed
    top: $header-height-mobile
    width: 100vw
    add-transition()

  &.empty
    .icon
      m-icon("like-outline", 35)

  .icon
    m-icon("like", 35)
    position: relative

    .mobile &
      m-icon("like", 25)

    .dot
      m-font-size(10, 10)
      align-items: center
      background-color: #CA2B69
      border-radius: 50%
      bottom: 0
      display: flex
      height: vw(18px)
      justify-content: center
      position: absolute
      right: 0
      width: vw(18px)

      .mobile &
        bottom: vw(-5px)

      > span
        margin-top: 0.1em

  .tooltip
    add-transition()
    background-color: #000
    border-radius: vw(10px)
    display: flex !important
    flex-direction: column
    max-height: 50vh
    padding: vw(20px) $tooltip-padding-width
    position: absolute
    right: vw(-50px)
    top: $header-height
    width: $tooltip-width
    z-index: 10
    &.fade-enter-active,
    &.fade-leave-active
      transition: all 0.5s ease-in-out
      
    &.fade-enter
    &.fade-leave-to
      opacity: 0
      transform translateY(-30px)

    .mobile &
      top: $header-height-mobile
      width: $tooltip-width-mobile

    &:before
      m-triangle("up", #000, 20)
      right: vw(50px)
      top: vw(-15px)

      .mobile &
        m-triangle("up", #000, 30)
        top: vw(-10px)

    .open-sub-header &
      top: "calc(%s + %s)" % ($header-height $sub-header-height)

      .mobile &
        top: "calc(%s + %s)" % ($header-height-mobile $sub-header-height-mobile)

    .tooltip-header
      align-items: center
      display: flex
      gap: vw(20px)
      margin-bottom: vw(15px)
      padding: 0 vw(20px)

      .title
        m-font-size(22, 28)
        flex: 1 1 0%
        font-weight: 700
        text-align: left

      .close
        m-icon("close-white", 25)
        padding: 0


    .perfume-list
      flex: 1 1 0%
      overflow-y: auto
      padding: 0 $tooltip-padding-wrapper-width

      .perfume-item
        background-color: #fff
        border-radius: vw(10px)
        color: #000
        padding: vw(12px) vw(8px)
          
        &:hover,
        &:active
          .fake-checkbox
            background-image: url("~@/assets/icons/icon-fake-checkbox-unchecked.svg") !important

        &:not(:last-child)
          margin-bottom: vw(10px)

        &.small
          .family-and-icon
            display: none

        .image
          height: vw(60px)
          width: vw(60px)

        .info
          text-align: left
        &.list-move, /* apply transition to moving elements */
          transition: all .5s ease-in-out
        &.list-enter-active,
        &.list-leave-active 
          transition: all 5s cubic-bezier(1, 0, 1, 0), opacity 0.3s ease
          pointer-events none
          
        &.list-enter-from,
        &.list-leave-to
          opacity: 0;
          transform: translateX(30px);
          width: "calc(%s - (%s + %s) * 2 - 10px)" % ($tooltip-width $tooltip-padding-wrapper-width $tooltip-padding-width)
          .mobile &
            width: "calc(%s - (%s + %s) * 2 - 10px)" % ($tooltip-width-mobile $tooltip-padding-wrapper-width $tooltip-padding-width)
        
        /* ensure leaving items are taken out of layout flow so that moving
          animations can be calculated correctly. */
        &.list-leave-active 
          position: absolute;
        

    .text
      m-font-size(16, 22)
      font-weight: 300
      padding: 0 vw(20px)
      text-align: left
</style>